/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { roundingNumber } from 'services/utils/roundingNumber';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	EApiCryptoTransactionDestination,
	EApiCryptoTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiTransactionType,
	IApiCryptoTransaction,
} from 'services/api/transactions/types';
import {
	putCryptoNoteRequest,
	putCryptoWithdrawalApproveRequest,
	putCryptoWithdrawalRejectRequest,
} from 'redux/reducers/transactions/reducer';
import { shortNotation } from 'services/utils/strings';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import IconSvg from 'ui/Svg/IconSvg';
import moment from 'moment';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';
import { getCurrencyData } from '../../../../redux/reducers/currency/selectors';
import { ICurrencyDataItem } from '../../../../redux/reducers/currency/types';

const INIT_VALUE_TO_EDIT = {
	id: 0,
	value: EApiCryptoTransactionStatus.ADMIN_PENDING,
	name: 'Pending',
};
const STATUS_TO_EDIT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiCryptoTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiCryptoTransactionStatus.FAILED,
	},
];

export interface ICryptoTransactionsRow extends IApiCryptoTransaction {
	isAllTypes?: boolean;
	permission: boolean;
	column?: string;
	transactionType?: string;
}

const CryptoTransactionsRow: FC<ICryptoTransactionsRow> = ({
	id,
	type,
	status,
	created_at,
	chain,
	fee,
	tx_hash,
	destination,
	isAllTypes,
	permission,
	note,
	gross_fee,
	net_fee,
	user,
	column,
	transactionType,
	asset_id,
	user_id,
	ext_tx_hash,
	address,
}) => {
	const dispatch = useDispatch();
	const [date, time] = useFormatDateTime(created_at);
	const currencies = useSelector(getCurrencyData);
	const [asset, setAsset] = useState<ICurrencyDataItem | null>(null);

	useEffect(() => {
		setAsset(currencies.filter((item) => item.id === asset_id)[0]);
	}, [asset_id, currencies]);

	const filterStatusArrayToEdit = (currentStatus: EApiCryptoTransactionStatus | string) => {
		return STATUS_TO_EDIT.filter((el) => el.value !== currentStatus);
	};
	const findEditedStatus = (currentStatus: string) => {
		return STATUS_TO_EDIT.find((el) => el.value === currentStatus) || INIT_VALUE_TO_EDIT;
	};
	const [editedStatus, setEditedStatus] =
		useState<IHistoryFiltersOptionStringValue>(INIT_VALUE_TO_EDIT);
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editedNote, setEditedNote] = useState(note);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value);
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => {
		setEditMode(true);
		setEditedStatus(findEditedStatus(status));
		setEditedNote(note);
	};
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		if (editedStatus.value === EApiCryptoTransactionStatus.FAILED) {
			dispatch(putCryptoWithdrawalRejectRequest(id));
		}
		if (editedStatus.value === EApiCryptoTransactionStatus.COMPLETED) {
			dispatch(putCryptoWithdrawalApproveRequest(id));
		}
		if (editedNote !== note) {
			dispatch(putCryptoNoteRequest({ type, data: { transaction_id: id, note: editedNote } }));
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedStatus(findEditedStatus(status));
		disableEditMode();
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				{type === 'deposit' ? (
					<>
						<p className="td-name--flex">{moment.utc(created_at).format('YYYY-MM-DD')}</p>
						<span className="td-more-info">{moment.utc(created_at).format('HH:mm:ss')}</span>
					</>
				) : (
					<>
						<p className="td-name--flex">{date}</p>
						<span className="td-more-info">{time}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{user_id}</p>
			</div>
			{isAllTypes && (
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p className="td-status">{type}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				{!!asset && (
					<>
						<p className="td-name--flex">{asset.code.toUpperCase()}</p>
						<span className="td-more-info">{asset?.name}</span>
					</>
				)}
			</div>
			{/* <div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{chain?.code}</p>
				<span className="td-more-info">{chain?.name}</span>
			</div> */}
			<div className="td">
				<p className="td-hidden-name">Gross</p>
				{!!asset && (
					<>
						<p>{roundingNumber(gross_fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				{!!asset && (
					<>
						<p>{roundingNumber(fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				{!!asset && (
					<>
						<p>{toFixedNumber(net_fee, asset.code, true)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				{column === EApiCryptoTransactionDestination.DESTINATION && (
					<>
						<p className="td-hidden-name">Destination</p>
						<p className="copy-button">
							{destination && (
								<>
									<span className="copy-button__text copy-button__text--max-width">
										{shortNotation(destination, 4, 4)}
									</span>
									<CopyToClipboard text={destination.toString()} onCopy={handleCopy}>
										<button type="button">
											<svg
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
													fill="#5974FF"
												/>
											</svg>
											Copy
										</button>
									</CopyToClipboard>
								</>
							)}
							{!destination && address.address && (
								<>
									<span className="copy-button__text copy-button__text--max-width">
										{shortNotation(address.address, 4, 4)}
									</span>
									<CopyToClipboard text={address.address.toString()} onCopy={handleCopy}>
										<button type="button">
											<svg
												width="14"
												height="14"
												viewBox="0 0 14 14"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
													fill="#5974FF"
												/>
											</svg>
											Copy
										</button>
									</CopyToClipboard>
								</>
							)}
							{!destination && !address.address && '-'}
						</p>
					</>
				)}
				{column === EApiCryptoTransactionDestination.TX_HASH && (
					<>
						<p className="td-hidden-name">Tx Hash</p>
						<p className="copy-button">
							<span className="copy-button__text copy-button__text--max-width">
								{(ext_tx_hash && shortNotation(ext_tx_hash, 4, 4)) || '-'}
							</span>
							{!!ext_tx_hash && (
								<CopyToClipboard text={ext_tx_hash.toString()} onCopy={handleCopy}>
									<button type="button">
										<svg
											width="14"
											height="14"
											viewBox="0 0 14 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
												fill="#5974FF"
											/>
										</svg>
										Copy
									</button>
								</CopyToClipboard>
							)}
						</p>
					</>
				)}
			</div>
			{type !== EApiTransactionType.DEPOSIT && (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					<p>{id}</p>
				</div>
			)}
			{type === EApiTransactionType.DEPOSIT && (
				<div className="td">
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(tx_hash, 4, 4) || '-'}
						</span>
						{!!tx_hash && (
							<CopyToClipboard text={tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.33125 0.533203C5.33714 0.533203 4.53125 1.33909 4.53125 2.3332V3.19987H2.33125C1.33714 3.19987 0.53125 4.00576 0.53125 4.99987V11.6665C0.53125 12.6606 1.33714 13.4665 2.33125 13.4665H7.66458C8.6587 13.4665 9.46458 12.6606 9.46458 11.6665V10.7999H11.6646C12.6587 10.7999 13.4646 9.99398 13.4646 8.99987V2.3332C13.4646 1.33909 12.6587 0.533203 11.6646 0.533203H6.33125ZM9.46458 9.86654H11.6646C12.1432 9.86654 12.5312 9.47852 12.5312 8.99987V2.3332C12.5312 1.85456 12.1432 1.46654 11.6646 1.46654H6.33125C5.8526 1.46654 5.46458 1.85456 5.46458 2.3332V3.19987H7.66458C8.6587 3.19987 9.46458 4.00576 9.46458 4.99987V9.86654ZM1.46458 4.99987C1.46458 4.52122 1.8526 4.1332 2.33125 4.1332H7.66458C8.14323 4.1332 8.53125 4.52122 8.53125 4.99987V11.6665C8.53125 12.1452 8.14323 12.5332 7.66458 12.5332H2.33125C1.8526 12.5332 1.46458 12.1452 1.46458 11.6665V4.99987Z"
											fill="#5974FF"
										/>
									</svg>
									Copy
								</button>
							</CopyToClipboard>
						)}
					</p>
				</div>
			)}
			{type === EApiTransactionType.DEPOSIT && (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					<p>{id}</p>
				</div>
			)}
			{transactionType === EApiTransactionType.WITHDRAWAL && !isAllTypes && (
				<div className="td">
					<p className="td-hidden-name">Admin Notes</p>
					{editMode ? (
						<div className="input">
							<div className="input-wrapper">
								<input
									type="text"
									value={editedNote}
									placeholder="Notes"
									onChange={handleChangeEditedNote}
									className="input-item input-item--type-td"
								/>
							</div>
						</div>
					) : (
						<div className="tooltip-item-note">
							<p className="tooltip-item-text">{note ? `${note?.slice(0, 10)}...` : '-'}</p>
							{note && (
								<div className="tooltip-note">
									<p>{note}</p>
									<i />
								</div>
							)}
						</div>
					)}
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{editMode && status === EApiCryptoTransactionStatus.ADMIN_PENDING ? (
					<TableItemSelect
						activeElement={editedStatus}
						selectArray={statusArrayToEdit}
						onChange={handleEditStatus}
					/>
				) : (
					<span
						className={
							status === EApiCryptoTransactionStatus.ADMIN_PENDING
								? 'pending-status'
								: `${status}-status`
						}
					>
						{status === EApiCryptoTransactionStatus.ADMIN_PENDING
							? `Pending`
							: `${status.charAt(0).toUpperCase()}${status.slice(1)}`}
					</span>
				)}
			</div>
			{/* {(type === EApiTransactionType.WITHDRAWAL || type === EApiTransactionType.TRANSFER) &&
				!isAllTypes && ( */}
			<div className={`td  td--right ${editMode ? 'td--editable' : ''}`}>
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button type="button" className="btn btn--icon btn--check" onClick={handleEdit} />
						<button type="button" className="btn btn--icon btn--cancel" onClick={disableEditMode} />
					</div>
				)}
				{permission && !editMode && (
					// eslint-disable-next-line react/self-closing-comp, jsx-a11y/control-has-associated-label
					<button
						type="button"
						className="btn btn--icon btn--edit"
						disabled={status !== EApiCryptoTransactionStatus.ADMIN_PENDING}
						onClick={enableEditMode}
					></button>
				)}
			</div>
			{/* )} */}
		</div>
	);
};

export default CryptoTransactionsRow;
