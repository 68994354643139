import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAuthStore, IPermissions } from 'redux/reducers/auth/types';

// ==========================================:
const getAuthState = (state: IStoreState): IAuthStore => state.auth;
export const getAuth = createSelector([getAuthState], (auth: IAuthStore) => auth);

// ====================================================:
export const getAuthIsAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !!(auth.isAuthenticated && auth.accessToken),
);

// ====================================================:
export const getAuthIsNotAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !auth.isAuthenticated && !auth.accessToken,
);
export const getUserData = createSelector([getAuth], (auth: IAuthStore) => auth.userData);

// ====================================================:
export const getForgotPassSuccessPopupOpen = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => auth.forgotPasswordPopup,
);
// ====================================================:
export const getSecretTokenForFileDownload = createSelector(
	[getAuth],
	(auth: IAuthStore): string | null => auth.secretTokenForFileDownload,
);

export const getPermissions = createSelector([getAuth], (auth: IAuthStore) =>
	auth.permissions?.reduce((acc: { [key: string]: IPermissions['pivot'] }, item) => {
		acc[item.name.replaceAll(' ', '_')] = item.pivot;
		return acc;
	}, {}),
);

export const getAdminId = createSelector([getAuth], (auth: IAuthStore) => auth.userData?.id);

export const getLoginLoader = createSelector([getAuth], (auth: IAuthStore) => auth.loginLoader);
