export const notificationsMessagesPopUp = {
	bankSuccessfullyDeleted: (bank: string) =>
		`Your bank account "${bank}" was deleted successfully.`,
	bankSuccessfullyCreated: (bank: string) => `Your bank account "${bank}" was added successfully.`,
	bankSuccessfullyUpdated: (bank: string) =>
		`Your bank account "${bank}" has been updated successfully.`,
	bankSuccessfully: (bank: string) => `Your bank account “${bank}” has been updated successfully.`,
	bankNotCreated: 'Bank account has not been added. Please try again or contact support.',
	bankNotUpdate: 'Bank account has not been updated. Please try again or contact support.',
	bankNotDeleted: 'Bank account has not been updated. Please try again or contact support.',
	bonusCompleted: 'Bonus Transfer was completed.',
	bankDelete: 'Bank account has not been deleted.',

	walletSuccessfully: (name: string) =>
		`Your wallet address “${name}” has been updated successfully.`,

	walletUpdated: 'Wallet address has not been updated. Please try again or contact support.',
	walletDeleted: 'Wallet address has not been deleted.',
	walletSuccessfullyCreated: 'Wallet address has been created successfully.',
	walletSuccessfullyNotCreated: 'Wallet address has not been created.',

	transactionCreated: 'Transaction has been created successfully',
	transactionNotCreated: 'Transaction has not been added. Please try again or contact support.',
};
