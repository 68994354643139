import React from 'react';

const TableBodyNoData = () => {
	return (
		<div className="table-no-data-body">
			<div className="table-no-data-body-plug">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="80"
					height="80"
					viewBox="0 0 80 80"
					fill="none"
				>
					<rect x="28" y="13" width="32" height="40" rx="4" fill="#2B2E31" />
					<rect x="8" y="8" width="64" height="64" rx="4" stroke="#5C6277" strokeDasharray="2 2" />
					<rect x="17" y="26" width="32" height="40" rx="4" fill="#626A85" />
					<rect x="23" y="41" width="20" height="1" rx="0.5" fill="#2B2E31" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M32.9498 33.8285L30.1213 31L28 33.1213L30.8285 35.9498L28.0001 38.7782L30.1215 40.8995L32.9498 38.0711L35.7782 40.8995L37.8995 38.7782L35.0711 35.9498L37.8996 33.1213L35.7783 31L32.9498 33.8285Z"
						fill="#8D99C5"
					/>
					<rect x="23" y="45" width="20" height="1" rx="0.5" fill="#2B2E31" />
					<rect x="23" y="49" width="20" height="1" rx="0.5" fill="#2B2E31" />
					<rect x="23" y="53" width="20" height="1" rx="0.5" fill="#2B2E31" />
					<rect x="23" y="57" width="20" height="1" rx="0.5" fill="#2B2E31" />
					<circle
						cx="48.3137"
						cy="50.3137"
						r="8"
						transform="rotate(-45 48.3137 50.3137)"
						fill="#8D99C5"
					/>
					<circle cx="48.314" cy="50.3137" r="6" fill="#939393" />
					<circle cx="48.314" cy="50.3137" r="6" fill="#626A85" />
					<rect
						x="52.5566"
						y="55.9707"
						width="2"
						height="2"
						transform="rotate(-45 52.5566 55.9707)"
						fill="#8D99C5"
					/>
					<rect
						x="53.2637"
						y="58.0918"
						width="4"
						height="15"
						rx="1"
						transform="rotate(-45 53.2637 58.0918)"
						fill="#8D99C5"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M49 56.275V44.3525C51.9906 44.693 54.314 47.232 54.314 50.3137C54.314 53.3955 51.9906 55.9345 49 56.275Z"
						fill="#2B2E31"
					/>
				</svg>
				<p>No data to show</p>
			</div>
		</div>
	);
};

export default TableBodyNoData;
