import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import {
	getWalletBalancePlatformBalancesUserCrypto,
	getWalletBalancePlatformBalancesUserCryptoLoading,
	getWalletBalancePlatformBalancesUserFiat,
} from '../../../../redux/reducers/walletBalance/selectors';
import { popUpOpen, setPopUpData } from '../../../../redux/reducers/popUp/reducer';
import Loading from '../../../../layouts-elements/Loading/Loading';

const Total = ({ permission, activeTab }: { permission: boolean; activeTab: string }) => {
	const dispatch = useDispatch();
	const itemCrypto = useSelector(getWalletBalancePlatformBalancesUserCrypto);
	const loading = useSelector(getWalletBalancePlatformBalancesUserCryptoLoading);
	const itemFiat = useSelector(getWalletBalancePlatformBalancesUserFiat);

	return (
		<div className="wallet-content-balance flex">
			<div className="wallet-content-balance-item">
				<div className="wallet-content-balance-item__title">
					<p>Estimates Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{roundingNumber(
									Number(itemCrypto?.totalBalances.eur) + Number(itemFiat?.totalBalances.eur),
									'EUR',
								)}{' '}
								<span> EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈{' '}
								{roundingNumber(
									Number(itemCrypto?.totalBalances.btc) + Number(itemFiat?.totalBalances.btc),
									'BTC',
								)}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>

			<div className="wallet-content-balance-item">
				<div className="wallet-content-balance-item__title">
					<p>Fiat Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{roundingNumber(itemFiat?.totalBalancesFiat.eur, 'EUR')}
								<span>EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {roundingNumber(itemFiat?.totalBalancesFiat.btc, 'BTC')}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>

			<div className="wallet-content-balance-item">
				<div className="wallet-content-balance-item__title">
					<p>Crypto Balance</p>
				</div>
				<div className="wallet-content-balance-item__value">
					<p>
						{loading ? (
							<Loading height={29} />
						) : (
							<>
								{roundingNumber(itemCrypto?.totalBalancesCrypto.eur, 'EUR')}
								<span>EUR</span>
							</>
						)}
					</p>
				</div>
				<div className="wallet-content-balance-item__user-currensy">
					<p>
						{loading ? (
							<Loading height={29} fontSize={16} />
						) : (
							<>
								≈ {roundingNumber(itemCrypto?.totalBalancesCrypto.btc, 'BTC')}
								<span>BTC</span>
							</>
						)}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Total;
