import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { popUpOpen, setPopUpData } from '../../../../../redux/reducers/popUp/reducer';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
	permission: boolean;
}

const Item: FC<Props> = ({ item, permission }) => {
	const { code } = item;
	const dispatch = useDispatch();
	return (
		<>
			<div className="tr">
				<div style={{ height: '100%', display: 'flex', alignItems: 'center' }} className="td">
					<p className="td-hidden-name">Currency</p>
					<div className="td__wrap">
						<div className="coin coin--type11">
							<div className="coin__icon">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
							</div>
							<div className="coin__text-wrap">
								<p className="coin__text">{code.toUpperCase()}</p>
								<span className="coin__text-more">{item.name}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="td td--right td--m-100">
					<p className="td-hidden-name">Platform Balance</p>
					<div className="td__wrap">
						<p>{roundingNumber(item?.total_balance.valueCrypto, code)}</p>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Transfer</p>
					{permission && (
						<button
							onClick={() => {
								const data = {
									title: 'Transfer to Bank Account',
									currency: code.toUpperCase(),
									totalBalance: `${roundingNumber(
										item?.total_balance.valueCrypto,
										code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${code.toUpperCase()}`,
									walletName: 'Bank Account',
									payload: {
										type: 'Pending fees fiat',
										asset_id: item.chains.asset_id,
									},
									total_balance: item?.total_balance.valueCrypto,
								};
								dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
								dispatch(setPopUpData(data));
							}}
							className="btn btn-outline-secondary btn-outline-secondary--sm btn-outline-secondary--w-180"
							type="button"
						>
							Transfer to Bank Account
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default Item;
