import React, { FC } from 'react';
import { useParams } from 'react-router';
import { IAccountDetailsParams } from '../../../types';
import {
	IGetCurrencyUserPayload,
	IPutCurrencyUserParams,
	IPutCurrencyUserPayload,
} from '../../../../../redux/reducers/currency/types';
/* eslint-disable @typescript-eslint/no-explicit-any */

const Item: FC<any> = ({ data, updateHandler, permission }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const id = data?.asset.id;
	const { depositable, withdrawable, exchangeable } = data;
	const code = data?.asset.code;
	const checkValue = (text: string, value: number) => {
		const params: IPutCurrencyUserParams = {
			asset_id: Number(id),
			user_id: Number(userId),
			[text]: value === 0 ? 1 : 0,

			//
			// onFinally: () => setFirstLoading(false),
		};
		updateHandler(params, data?.asset.name);
	};

	return (
		<>
			<form className="tr">
				<div className="td">
					<p className="td-hidden-name">Cryptocurrency</p>
					<div className="td__wrap">
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p>
							{code.toUpperCase()} <span className="td-more-info d-inline">{data.asset.name}</span>
						</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Deposit</p>
					<div className="td__wrap">
						<div className="switch">
							<label className="switch__label">
								<input
									onChange={() => {
										checkValue('depositable', depositable);
									}}
									disabled={!permission}
									name="depositable"
									checked={depositable}
									type="checkbox"
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Withdrawal</p>
					<div className="td__wrap">
						<div className="switch">
							<label className="switch__label">
								<input
									onChange={() => {
										checkValue('withdrawable', withdrawable);
									}}
									name="withdrawable"
									disabled={!permission}
									checked={withdrawable}
									type="checkbox"
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Trade</p>
					<div className="td__wrap">
						<div className="switch">
							<label className="switch__label">
								<input
									onChange={() => {
										checkValue('exchangeable', exchangeable);
									}}
									name="exchangeable"
									disabled={!permission}
									checked={exchangeable}
									type="checkbox"
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default Item;
