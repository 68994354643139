/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { responseErrors } from 'services/http/responseErrors';
import { notificationsMessagesPopUp } from 'services/utils/notificationsMessages/notificationsMessagesPopUp';
import { IUpdateDepositAccountData } from 'components/WalletBalance/DepositAccounts/DepositAccountsCrypto/DepositAccountCryptoItem/types';
import { IPagination } from '../users/types';
import {
	getDepositsWalletsCryptoSuccess,
	getDepositsWalletsRequest,
	getDepositsWalletsSuccess,
	getDepositAccountRequest,
	getLiquidityCryptoSuccess,
	getLiquidityFiatSuccess,
	getLiquidityReportRequest,
	getLiquidityReportSuccess,
	getLiquidityRequest,
	getPendingFeesCryptoSuccess,
	getPendingFeesFiatSuccess,
	getPendingFeesRequest,
	getPlatformBalancesCryptoSuccess,
	getPlatformBalancesFiatSuccess,
	getPlatformBalancesRequest,
	getDepositAccountSuccess,
	updateDepositAccountRequest,
	createDepositAccountRequest,
	deleteDepositAccountRequest,
	getPlatformBalancesUserCryptoSuccess,
	getPlatformBalancesUserFiatSuccess,
	getDepositWalletsUserSuccess,
	getPlatformBalancesUserRequest,
	getDepositWalletsUserRequest,
	getPendingFeesTotalSuccess,
	getAssetsSuccess,
	getAssetsRequest,
	getPendingFeesReportRequest,
	getPendingFeesReportSuccess,
	postButtonsRequest,
	postButtonsCryptoSuccess,
	getUserDepositAccountRequest,
	getUserDepositAccountSuccess,
} from './reducer';
import {
	IDepositAccount,
	ILiquidityCryptoResponse,
	ILiquidityPayload,
	ILiquidityReportPayload,
	IPlatformBalancePayload,
} from './types';
import { setToInitActiveBankAccounts } from '../bankAccounts/reducer';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { IBankAccountRequestPayload } from '../bankAccounts/types';
import { setErrorMsg } from '../errors/reducer';

// =============================================================:

function* getAssetsWorker() {
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: any = yield call(api.walletBalances.getAssets);
		yield put(getAssetsSuccess(response));
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getLiquidityWorker({ payload }: PayloadAction<ILiquidityPayload>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		const response: ILiquidityCryptoResponse = yield call(
			api.walletBalances.getWalletBalancesLiquidity,
			apiParams,
		);
		if (apiParams.currency === 'crypto') {
			yield put(getLiquidityCryptoSuccess(response));
		}
		if (apiParams.currency === 'fiat') {
			yield put(getLiquidityFiatSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getLiquidityReportWorker({ payload }: PayloadAction<ILiquidityReportPayload>) {
	const { apiParams } = payload;

	try {
		yield put(showLoading());
		const response: string = yield call(
			api.walletBalances.getWalletBalancesLiquidityReport,
			apiParams,
		);
		const { origin } = window.location;
		// window.location.replace(
		// 	`${origin}/api/admin/dashboard/report/liquidity/${apiParams.fileName}/${String(
		// 		apiParams.key,
		// 	)}/${apiParams.currency}`,
		// );
		window.location.replace(
			`${origin}/api/admin/dashboard/report/liquidity/${apiParams?.fileName}/${String(
				apiParams.key,
			)}/${apiParams.currency}`,
		);

		// TEST
		// window.location.replace(
		// 	`https://oneify-dev.corp.merehead.xyz/api/admin/dashboard/report/liquidity/${
		// 		apiParams.fileName
		// 	}/${String(apiParams.key)}/${apiParams.currency}`,
		// );

		// yield call(() => {
		// 	if (apiParams?.fileName) {
		// 		downloadFile(response, `Liquidity ${apiParams?.currency} File.${apiParams?.fileName}`);
		// 	}
		// });
		yield put(getLiquidityReportSuccess());
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getPendingFeesReportWorker({ payload }: PayloadAction<ILiquidityReportPayload>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		const response: string = yield call(
			api.walletBalances.getWalletBalancesPendingFeesReport,
			apiParams,
		);
		const { origin } = window.location;
		window.location.replace(
			`${origin}/api/admin/dashboard/report/pending_fee/${apiParams.fileName}/${String(
				apiParams.key,
			)}/${apiParams.currency}`,
		);
		// yield call(() => {
		// 	if (apiParams?.fileName) {
		// 		downloadFile(response, `Pending fees ${apiParams?.currency} File.${apiParams?.fileName}`);
		// 	}
		// });
		yield put(getPendingFeesReportSuccess());
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* getPlatformBalancesWorker({ payload }: PayloadAction<any>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: never = yield call(
			api.walletBalances.getWalletBalancesPlatformBalances,
			apiParams,
		);
		if (apiParams.currency === 'crypto') {
			yield put(getPlatformBalancesCryptoSuccess(response));
		}
		if (apiParams.currency === 'fiat') {
			yield put(getPlatformBalancesFiatSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getDepositWalletsWorker({ payload }: PayloadAction<any>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: never = yield call(
			api.walletBalances.getWalletBalancesDepositWallets,
			apiParams,
		);
		if (apiParams.currency === 'crypto') {
			yield put(getDepositsWalletsCryptoSuccess(response));
		}
		if (apiParams.currency === 'fiat') {
			yield put(getDepositsWalletsSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getPendingFeesWorker({ payload }: PayloadAction<any>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: never = yield call(api.walletBalances.getWalletBalancesPendingFees, apiParams);
		if (apiParams.currency === 'crypto') {
			yield put(getPendingFeesCryptoSuccess(response));
		}
		if (apiParams.currency === 'fiat') {
			yield put(getPendingFeesFiatSuccess(response));
		}

		if (apiParams.currency === 'total') {
			yield put(getPendingFeesTotalSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// User

function* getPlatformBalancesUserWorker({ payload }: PayloadAction<IPlatformBalancePayload>) {
	// console.log('getPlatformBalancesUserWorker');
	const { apiParams } = payload;
	try {
		yield put(showLoading());
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: never = yield call(
			api.walletBalances.getWalletBalancesPlatformBalancesUser,
			apiParams,
		);
		if (apiParams.currency === 'crypto') {
			yield put(getPlatformBalancesUserCryptoSuccess(response));
		}
		if (apiParams.currency === 'fiat') {
			yield put(getPlatformBalancesUserFiatSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getDepositWalletsUserWorker({ payload }: PayloadAction<any>) {
	const { apiParams } = payload;
	try {
		yield put(showLoading());

		if (apiParams.type === 'crypto') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const response: never = yield call(
				api.walletBalances.getWalletBalancesDepositWalletsUser,
				apiParams,
			);
			yield put(getDepositWalletsUserSuccess(response));
		}
		if (apiParams.type === 'fiat') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const response: never = yield call(
				api.walletBalances.getWalletBalancesDepositWalletsUserFiat,
				apiParams,
			);
			yield put(getDepositWalletsUserSuccess(response));
		}
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

//
function* getUsersDepositWalletsWorker(action: PayloadAction) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IPagination<IDepositAccount> = yield call(
			api.walletBalances.getDepositAccounts,
			payload,
		);
		yield put(getDepositAccountSuccess(response));
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
function* getUserDepositWalletsWorker({ payload }: PayloadAction) {
	try {
		yield put(showLoading());
		const response: IPagination<IDepositAccount> = yield call(
			api.walletBalances.getUserDepositAccounts,
			payload,
		);
		yield put(setToInitActiveBankAccounts(response.data));
		yield put(getUserDepositAccountSuccess(response));
	} catch (error) {
		notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}

function* updateDepositAccountsWorker(action: PayloadAction<IUpdateDepositAccountData>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IDepositAccount[] = yield call(
			api.walletBalances.updateDepositAccount,
			payload,
		);
		yield put(getDepositAccountRequest({ current_page: 1, per_page: 10 }));
		yield put(
			setPopUpData({
				message: notificationsMessagesPopUp.bankSuccessfullyUpdated(payload.bank_name),
				title: 'Bank Account Updated',
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotUpdate }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}

function* createDepositAccountsWorker(action: PayloadAction<IBankAccountRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IDepositAccount[] = yield call(
			api.walletBalances.createDepositAccount,
			payload.data,
		);
		if (payload.popup) {
			yield put(getDepositAccountRequest({ per_page: 100, asset_id: payload.asset_id || 1 }));
		} else {
			yield put(getDepositAccountRequest({ current_page: 1, per_page: 10 }));
		}

		yield put(
			setPopUpData({
				message: notificationsMessagesPopUp.bankSuccessfullyCreated(payload.data.bank_name),
				title: 'Bank Account Added',
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotCreated }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}

function* deleteDepositAccountsWorker(
	action: PayloadAction<{ admin_requisite_id: number; nickname: string }>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IDepositAccount[] = yield call(api.walletBalances.deleteDepositAccount, {
			admin_requisite_id: payload.admin_requisite_id,
		});
		yield put(getDepositAccountRequest({ current_page: 1, per_page: 10 }));
		yield put(
			setPopUpData({
				message: notificationsMessagesPopUp.bankSuccessfullyDeleted(payload.nickname),
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotDeleted }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
/* eslint-disable */
function* postButtonsWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onFinally, currencyInfo } = payload;
	try {
		yield put(showLoading());
		// Deposit wallets PAGE
		if (apiParams.type === 'Deposit wallets crypto') {
			yield call(api.walletBalances.postButtonsDepositsWalletsCrypto, apiParams);
		}
		// pending-fees PAGE
		if (apiParams.type === 'Pending fees crypto') {
			yield call(api.walletBalances.postButtonsPendingFeesCrypto, apiParams);
		}
		if (apiParams.type === 'Pending fees fiat') {
			yield call(api.walletBalances.postButtonsPendingFeesFiat, apiParams);
		}
		// user-balances PAGE
		if (apiParams.type === 'User balances all') {
			yield call(api.walletBalances.postButtonsUserBalancesAll, apiParams);
		}
		if (apiParams.type === 'User balances deposit wallet totals') {
			yield call(api.walletBalances.postButtonsUserBalancesTotals, apiParams);
		}
		if (apiParams.type === 'User balances deposit wallet totals user-management') {
			yield call(api.walletBalances.postButtonsUserBalancesWallets, apiParams);
		}
		if (apiParams.type === 'User balances deposit wallet') {
			yield call(api.walletBalances.postButtonsUserBalancesWallets, apiParams);
		}

		if (apiParams.type === 'User balances transfer wallet') {
			yield call(api.walletBalances.postButtonsUserBalances, apiParams);
		}
		if (apiParams.type === 'All user balances transfer wallet') {
			yield call(api.users.postAllTransferToPool, apiParams);
		}

		if (apiParams.type === 'Deposit wallets crypto') {
			notificationContainer(
				`${currencyInfo.coin} was transferred to the ${currencyInfo.walletName} successfully`,
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'Pending fees crypto') {
			notificationContainer(
				`${currencyInfo.coin} was transferred to the ${currencyInfo.walletName} successfully`,
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'Pending fees fiat') {
			notificationContainer(
				`${currencyInfo.coin} was transferred to the ${currencyInfo.walletName} successfully`,
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'User balances all') {
			notificationContainer(
				'All was transferred to the Pool Wallet successfully',
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'User balances deposit wallet totals') {
			notificationContainer(
				`${currencyInfo.coin} was transferred to the ${currencyInfo.walletName} successfully`,
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'User balances deposit wallet totals user-management') {
			notificationContainer(
				`${currencyInfo.coin} was transferred to the ${currencyInfo.walletName} successfully`,
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'User balances deposit wallet') {
			notificationContainer(
				`${currencyInfo.coin} was transferred to the Pool Wallet successfully`,
				'success',
				'Transfer completed',
			);
		} else if (apiParams.type === 'All user balances transfer wallet') {
			notificationContainer(
				`All assets were transferred to the Pool wallet successfully`,
				'success',
				'Transfer completed',
			);
		} else {
			notificationContainer('Success buttons', 'success');
		}

		onFinally();
		yield put(postButtonsCryptoSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// console.log(error?.response?.data?.errors);
			if (error?.response?.data?.errors[0] === 'zero_asset_balance') {
				notificationContainer(
					`${currencyInfo.coin} balance is zero`,
					'user_blocked',
					'Insufficient balance',
				);
			}
			if (error?.response?.data?.errors[0] === 'the_totp_field_is_required') {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			} else if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg(error.response?.data.errors[0]));
			} else {
				responseErrors(error);
			}
		}
		//notificationContainer('Error', 'error');
	} finally {
		yield put(hideLoading());
	}
}
export function* walletBalanceSaga() {
	yield takeEvery(getAssetsRequest.type, getAssetsWorker);
	yield takeEvery(getLiquidityRequest.type, getLiquidityWorker);
	yield takeEvery(getLiquidityReportRequest.type, getLiquidityReportWorker);
	yield takeEvery(getPendingFeesReportRequest.type, getPendingFeesReportWorker);
	yield takeEvery(getPlatformBalancesRequest.type, getPlatformBalancesWorker);
	yield takeEvery(getDepositsWalletsRequest.type, getDepositWalletsWorker);
	yield takeEvery(getPendingFeesRequest.type, getPendingFeesWorker);
	yield takeEvery(getPlatformBalancesUserRequest.type, getPlatformBalancesUserWorker);
	yield takeEvery(getDepositWalletsUserRequest.type, getDepositWalletsUserWorker);
	yield takeEvery(getDepositAccountRequest.type, getUsersDepositWalletsWorker);
	yield takeEvery(getUserDepositAccountRequest.type, getUserDepositWalletsWorker);
	yield takeEvery(updateDepositAccountRequest.type, updateDepositAccountsWorker);
	yield takeEvery(createDepositAccountRequest.type, createDepositAccountsWorker);
	yield takeEvery(deleteDepositAccountRequest.type, deleteDepositAccountsWorker);

	yield takeEvery(postButtonsRequest.type, postButtonsWorker);
}
