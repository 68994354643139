import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import {
	getUserSpreadFeeRequest,
	putUserSpreadFeesRequest,
	updateUserSpreadFees,
} from 'redux/reducers/users/reducer';
import {
	getUsersFeesLoader,
	getUsersSpreadFees,
	getUsersSpreadFeesLoading,
} from 'redux/reducers/users/selectors';
import { IUserFeesSpreadUpdteBody, IUserFeesData } from 'redux/reducers/users/types';
import FeeManagementSpreadItem from './FeeManagementSpreadItem/FeeManagementSpreadItem';

import { IAccountDetailsParams } from '../../types';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const FeeManagementSpread = ({ permission }: { permission: boolean }) => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const usersFees = useSelector(getUsersSpreadFees);
	const loading = useSelector(getUsersSpreadFeesLoading);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};
	const pageCount = usersFees?.last_page || 1;

	useLayoutEffect(() => {
		if (userId) {
			const params: IUserFeesData = {
				id: userId,
				current_page: currentPage,
				per_page: perPage,
			};
			dispatch(getUserSpreadFeeRequest(params));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, userId, perPage, currentPage]);

	const updateHandler = (obj: IUserFeesSpreadUpdteBody) => {
		dispatch(
			putUserSpreadFeesRequest({
				id: userId,
				body: obj,
				current_page: currentPage,
				per_page: perPage,
				feeType: 'Spread',
			}),
		);
		dispatch(
			updateUserSpreadFees({
				spread_id: obj.spread_id,
				data: {
					spread: obj.spread,
				},
			}),
		);
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management-fee-management-spread">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Pair</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Spread %</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">{/* <p>Action</p> */}</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{usersFees?.data?.length ? (
										usersFees?.data?.map((el: any) => (
											<FeeManagementSpreadItem
												key={el.id}
												updateHandler={updateHandler}
												el={el}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{!!usersFees?.data.length && (
							<div className="table-footer table-footer--type2">
								<div className="pagination-block pagination-block--full">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCount > 1 && (
										<Pagination
											pageCount={pageCount}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!usersFees?.data.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					)}
				</div>
			)} */}
		</>
	);
};

export default FeeManagementSpread;
