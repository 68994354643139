import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import TransferLimitsTable from './TransferLimitsTable';

const TransferLimitsTab = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState('crypto');

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	const handleChangeTab = (e: any) => {
		const { name } = e.target;
		if (name === 'crypto' || name === 'fiat') {
			setActiveTab(name);
		}
	};

	return (
		<div className="user-management">
			<div className="tabs-buttons tabs-buttons--transfer-history" onClick={handleChangeTab}>
				<button type="button" name="crypto" className={activeTab === 'crypto' ? 'active' : ''}>
					Crypto
				</button>
				<button type="button" name="fiat" className={activeTab === 'fiat' ? 'active' : ''}>
					Fiat
				</button>
			</div>

			<TransferLimitsTable type={activeTab} />
		</div>
	);
};

export default TransferLimitsTab;
