import Accordion from '../Accordion/Accordion';
import PrivacyData, { TPrivacyData } from './PrivacyData';

const PrivacyPolicy = () => {
	return (
		<section className="info-section">
			<div className="container ">
				<div className="info-section__box">
					<h1>Privacy Policy</h1>
					<p>
						In this Privacy Policy, ‘us’ ‘we’ or ‘our’ means ONEIFY TRADING. We are committed to
						respecting your privacy. Our Privacy Policy sets out how we collect, use, store and
						disclose your personal information.
					</p>
					<p>
						By providing personal information to us, you consent to our collection, use and
						disclosure of your personal information in accordance with this Privacy Policy and any
						other arrangements that apply between us. We may change our Privacy Policy from time to
						time by publishing changes to it on our website. We encourage you to check our website
						periodically to ensure that you are aware of our current Privacy Policy.
					</p>
					<p className="mb-0">
						Personal information includes information or an opinion about an individual that is
						reasonably identifiable. For example, this may include your name, age, gender, postcode
						and contact details. It may also include financial information, including your bank
						account information.
					</p>
					<div className="accordion">
						{!!PrivacyData && (
							<>
								{PrivacyData.map((el: TPrivacyData) => (
									<Accordion
										title={el.title}
										content={el.content}
										key={el.id}
										isActive={el.isActive}
									/>
								))}
							</>
						)}
					</div>
					<p className="mt-50 mb-0">
						<span className="font-weight-semibold">Effective:</span> 07/08/2018
					</p>
				</div>
			</div>
		</section>
	);
};

export default PrivacyPolicy;
