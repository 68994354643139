/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useLayoutEffect, useState } from 'react';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWithdrawalLimits,
	getWithdrawalLimitsLoading,
} from 'redux/reducers/transactions/selectors';
import { IGetTradeLimitPayload } from 'redux/reducers/transactions/types';
import {
	getCryptoWithdrawalLimitsRequest,
	getFiatWithdrawalLimitsRequest,
} from 'redux/reducers/transactions/reducer';
import { setUserData } from 'redux/reducers/users/reducer';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';

import Loading from '../../../../../layouts-elements/Loading/Loading';
import TransferLimitsTableRow from './TransferLimitsTableRow';

export interface ITransfersLimitsTable {
	type: string;
}

const TrasferLimitsTable: FC<ITransfersLimitsTable> = ({ type }) => {
	const dispatch = useDispatch();
	const withdrawalLimits = useSelector(getWithdrawalLimits);
	const withdrawalLimitsLoading = useSelector(getWithdrawalLimitsLoading);
	const userData = useSelector(getUserCreateData);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const pageCount = withdrawalLimits?.last_page || 1;

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};

	useLayoutEffect(() => {
		const params: IGetTradeLimitPayload = {
			current_page: currentPage,
			per_page: perPage,
		};
		type === 'crypto' && dispatch(getCryptoWithdrawalLimitsRequest(params));
		type === 'fiat' && dispatch(getFiatWithdrawalLimitsRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const assetFilterData = withdrawalLimits?.data?.map((el: any) => {
		const currentAsset = userData?.transaction_limits?.find(
			(item: any) => item.asset_id === el.asset_id,
		);
		if (currentAsset) {
			return {
				...el,
				minimum: currentAsset?.minimum,
				maximum: currentAsset.maximum,
				hourly: currentAsset.hourly,
				daily: currentAsset.daily,
			};
		}
		return el;
	});

	/* eslint-disable */
	const updateHandler = ({ asset_id, minimum, maximum, hourly, daily }: any) => {
		let newData: any = userData?.transaction_limits ? [...userData.transaction_limits] : [];
		// debugger;
		const asset = userData?.transaction_limits?.find((item) => item.asset_id === asset_id);
		if (asset && userData?.transaction_limits) {
			newData = userData?.transaction_limits?.map((item) => {
				return item.asset_id === asset_id
					? { ...item, minimum: minimum, maximum: maximum, hourly: hourly, daily: daily }
					: item;
			});
		} else {
			withdrawalLimits?.data?.forEach((item: any) => {
				if (item.asset_id === asset_id) {
					newData.push({
						asset_id: item?.asset_id,
						minimum: minimum,
						maximum: maximum,
						hourly: hourly,
						daily: daily,
					});
				}
			});
		}

		dispatch(setUserData({ ...userData, transaction_limits: newData }));
	};

	return (
		<>
			<div className="table-block table-block--transactions-transfer-limits">
				<div className="table-wrapper">
					<div className="table table--type2 table--transactions-transfer-limits">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Minimum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Maximum</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Hourly Limit</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Daily Limit</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name" />
								</div>
							</div>
						</div>
						<div className="table-body">
							{!withdrawalLimitsLoading && (
								<>
									{assetFilterData?.length && !withdrawalLimitsLoading ? (
										assetFilterData.map((el: any) => (
											<TransferLimitsTableRow key={el.id} el={el} updateHandler={updateHandler} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{withdrawalLimitsLoading ? <Loading /> : null}
						</div>
						{!!withdrawalLimits?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									{pageCount > 1 && (
										<Pagination
											pageCount={pageCount}
											currentPage={currentPage}
											onPageChange={setCurrentPage}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* {!!withdrawalLimits?.data.length && (
					<div className="table-pagination">
						<PerPageSelect onChange={handleSetPerPage} />
						{pageCount > 1 && (
							<div className="pagination-block">
								<Pagination
									pageCount={pageCount}
									currentPage={currentPage}
									onPageChange={setCurrentPage}
								/>
							</div>
						)}
					</div>
				)} */}
		</>
	);
};

export default TrasferLimitsTable;

