import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

import Header from 'layouts-elements/Header/Header/Header';
import Footer from 'layouts-elements/Footer';
import { IDashboard } from './types';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>

			{/* <Header /> */}
			<main className="main">{children}</main>
			<Footer />
		</>
	);
};

export default Dashboard;
