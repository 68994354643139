// Capitalize first letter
export function capitalize(string: string) {
	if (string !== undefined) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return '';
}

// Capitalize first letter in all words
export function capitalizeWords(str: string) {
	return str
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

export function shortNotation(string: string, fromStart: number, fromEnd = 0): string {
	if (string) {
		if (string.length <= fromStart + fromEnd) {
			return string;
		}
		const leftPart = string.slice(0, fromStart);
		const rightPart = fromEnd ? string.slice(-fromEnd) : '';

		return `${leftPart}...${rightPart}`;
	}
	return '-';
}

export function splitString(string: string, separator: string) {
	return string.split(separator);
}
export function trimBottomLine(string: string) {
	const newStr = string.replace(/_/g, ' ');
	return newStr;
}
