import React from 'react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EPendingWithdrawalType } from 'services/api/transactions/types';
import DetailedView from 'components/DetailedView/DetailedView';
import PendingWithdrawalTable from './PendingWithdrawalTable';

const PendingWithdrawalTab = () => {
	const { path: matchPath } = useRouteMatch();
	const { state } = useLocation();
	return (
		<div className="user-management">
			{!state && (
				<div className="tabs-buttons-wrapper">
					<div className="tabs-buttons tabs-buttons--pending">
						<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto}>Crypto</NavLink>
						<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.fiat}>Fiat</NavLink>
					</div>
				</div>
			)}
			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto}>
					<PendingWithdrawalTable type={EPendingWithdrawalType.CRYPTO} />
				</Route>
				<Route exact path={ROUTES.transfersAndTradeReports.pendingWithdrawal.fiat}>
					<PendingWithdrawalTable type={EPendingWithdrawalType.FIAT} />
				</Route>
				<Route path={`${matchPath}/:id`} component={DetailedView} />
				<Redirect to={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto} />
			</Switch>
		</div>
	);
};
export default PendingWithdrawalTab;
