import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { FC } from 'react';
import {
	INIT_FILTER_OPTIONS,
	INIT_FILTER_OPTIONS_BALANCES_CURRENCY,
	INIT_FILTER_OPTIONS_STATUS,
} from 'redux/reducers/transactionsFilters/constants';
import {
	ETransactionsFilters,
	ITransactionsFilter,
} from 'redux/reducers/transactionsFilters/types';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';

export interface ITransactionsFilters {
	classNames?: string;
	activeFilters?: ITransactionsFilter[];
	handleResetFilters: () => void;
	handleDeleteFilter?: (type: ETransactionsFilters) => void;
	handleDateChange: (startDate: Date, endDate: Date) => void;
	handleChangeAsset?: ({ name, value }: THistoryFiltersOption) => void;
	currencyOptions?: {
		id: number;
		name: string;
		value: number;
	}[];
	statusOptions?: {
		id: number;
		name: string;
		value: any;
	}[];
	handleStatusChange?: ({ name, value }: THistoryFiltersOption) => void;
	searchArray?: {
		name: string;
		value: ETransactionsFilters;
	}[];
	handleSearch?: (searchField: ISearchSelectArr, currentSearchValue: string) => void;
	resetValue?: boolean;
	type?: string;
	reset?: boolean;
}

const ActiveReportsFilters: FC<ITransactionsFilters> = ({
	handleResetFilters,
	handleDateChange,
	handleChangeAsset,
	currencyOptions,
	statusOptions,
	handleStatusChange,
	searchArray,
	handleSearch,
	resetValue,
	reset,
	type,
}) => {
	return (
		<>
			<div className="active-trade_filters">
				<div className="active-trade_filters--date">
					<FilterDateSelect title="Date" onChange={handleDateChange} reset={reset} />
				</div>
				{currencyOptions && handleChangeAsset && (
					<div className="active-trade_filters--currency">
						{/* <p>Currency</p> */}
						<FilterSelect
							options={
								[
									...INIT_FILTER_OPTIONS_BALANCES_CURRENCY,
									...currencyOptions,
								] as THistoryFiltersTypeOption[]
							}
							selected={INIT_FILTER_OPTIONS_BALANCES_CURRENCY[0]}
							onChange={handleChangeAsset}
							icons
							reset={reset}
						/>
					</div>
				)}
				{statusOptions && handleStatusChange && (
					<div className="active-trade_filters--status">
						{/* <p>Status</p> */}
						<FilterSelect
							options={
								[...INIT_FILTER_OPTIONS_STATUS, ...statusOptions] as THistoryFiltersTypeOption[]
							}
							selected={INIT_FILTER_OPTIONS_STATUS[0]}
							onChange={handleStatusChange}
							reset={reset}
						/>
					</div>
				)}
				{searchArray && handleSearch && (
					<div className="active-trade_filters--search">
						<SearchWithSelect
							handleSearch={handleSearch}
							selectArr={searchArray}
							resetValue={resetValue}
							trade={type === 'user-history'}
						/>
					</div>
				)}
				<button type="button" className="reset_trade-filters" onClick={handleResetFilters}>
					Reset
				</button>
			</div>
		</>
	);
};
export default ActiveReportsFilters;
