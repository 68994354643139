/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	EFiltersType,
	ITransactionsFilter,
	ITransactionsFiltersStore,
	ITransactionsDeleteFilterPayload,
} from './types';

export const initialState: ITransactionsFiltersStore = {
	cryptoFilters: [],
	fiatFilters: [],
	pooledDepositsFilters: [],
	tradesFilters: [],
	profitWalletFilters: [],
	pendingFiatWithdrawalFilters: [],
	pendingCryptoWithdrawalFilters: [],
	referralFilters: [],
	bankAccountsWithSelectFilters: [],
	ledgerFilters: [],
};

const transactionsFilters = createSlice({
	name: '@@transactions',
	initialState,
	reducers: {
		addFilter: (state, { payload }: PayloadAction<ITransactionsFilter>) => {
			const filtersWithoutCurrent = state[payload.filterType]?.filter(
				(filter) => filter.type !== payload.type,
			);

			if (payload.value === undefined) {
				state[payload.filterType] = filtersWithoutCurrent;
				return;
			}
			if (state[payload.filterType].length === filtersWithoutCurrent.length) {
				state[payload.filterType] = [...state[payload.filterType], payload];
			} else {
				state[payload.filterType] = [...filtersWithoutCurrent, payload];
			}
		},
		deleteFilter: (state, { payload }: PayloadAction<ITransactionsDeleteFilterPayload>) => {
			state[payload.filterType] = state[payload.filterType].filter(
				(filter) => filter.type !== payload.type,
			);
		},
		resetFilters: (state, { payload }: PayloadAction<EFiltersType>) => {
			state[payload] = [];
		},
	},
});

export default transactionsFilters.reducer;
export const { addFilter, deleteFilter, resetFilters } = transactionsFilters.actions;
