import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getMinimizedSidebarState } from 'redux/reducers/sidebarStates/selectors';
import SideBar from 'layouts-elements/SideBar';
import { useLocation } from 'react-router';
import { IContent } from './types';

// ==========================================:
const Content: FC<IContent> = ({ children }) => {
	const minimizedSidebar = useSelector(getMinimizedSidebarState);
	const location = useLocation();
	return (
		<main className="main">
			{location.pathname === '/terms' || location.pathname === '/privacy' ? (
				<>{children}</>
			) : (
				<section className="main-section">
					<SideBar />
					<div style={{ maxWidth: '100%' }} className="main-content">
						{children}
					</div>
				</section>
			)}
		</main>
	);
};

export default Content;

// import { FC, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { getMinimizedSidebarState } from 'redux/reducers/sidebarStates/selectors';
// import SideBar from 'layouts-elements/SideBar';
// import { useLocation } from 'react-router';
// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// import { motion } from 'framer-motion/dist/framer-motion';
// import { IContent } from './types';
// // ==========================================:
// const Content: FC<IContent> = ({ children }) => {
// 	const minimizedSidebar = useSelector(getMinimizedSidebarState);
// 	const location = useLocation();
// 	const [key, setKey] = useState(1);
// 	const [showChildren, setShowChildren] = useState(false);
//
// 	useEffect(() => {
// 		setKey((prevKey) => prevKey + 1);
// 		setShowChildren(true);
// 	}, [children]);
// 	useEffect(() => {
// 		const timer = setTimeout(() => {
// 			setShowChildren(true);
// 		}, 2000);
// 		return () => clearTimeout(timer);
// 	}, []);
//
// 	return (
// 		<main className="main">
// 			{location.pathname === '/terms' || location.pathname === '/privacy' ? (
// 				<>{children}</>
// 			) : (
// 				<section className={`main-section ${minimizedSidebar ? 'minimized' : ''}`}>
// 					<SideBar />
// 					<motion.div
// 						key={key}
// 						style={{ maxWidth: '100%' }}
// 						className="main-content"
// 						initial={{ opacity: 0 }}
// 						animate={{ opacity: 1, transition: { duration: 1 } }}
// 						exit={{ opacity: 0, transition: { duration: 1 } }}
// 					>
// 						{showChildren && <div>{children}</div>}
// 					</motion.div>
// 				</section>
// 			)}
// 		</main>
// 	);
// };
//
// export default Content;
