import Accordion from '../Accordion/Accordion';
import TermsData, { TTermsData } from './TermsData';

const TermsOfUse = () => {
	return (
		<section className="info-section">
			<div className="container">
				<div className="info-section__box">
					<h1>Terms and Conditions</h1>
					<p>
						Thank you for visiting our website. This website is owned and operated by ONEIFY
						TRADING. By accessing and/or using this website and related services, you agree to these
						Terms and Conditions, which include our Privacy Policy (available at
						https://www.oneifytrading.com/privacy) (Terms). You should review our Privacy Policy and
						these Terms carefully and immediately cease using our website if you do not agree to
						these Terms.
					</p>
					<p className="mb-0">In these Terms, ‘us’, ‘we’ and ‘our’ means ONEIFY TRADING.</p>
					<div className="accordion">
						{!!TermsData && (
							<>
								{TermsData.map((el: TTermsData) => (
									<Accordion
										title={el.title}
										content={el.content}
										key={el.id}
										isActive={el.isActive}
									/>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default TermsOfUse;
