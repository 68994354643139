import { useLayoutEffect } from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { useDispatch } from 'react-redux';
import CryptoHistory from '../CryptoHistory';
import FiatHistory from '../FiatHistory';

const TransferHistoryTabView = () => {
	const { url, path } = useRouteMatch();
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div className="tabs-buttons tabs-buttons--transfer-history">
					<NavLink to={`${url}/crypto`} className="button button--size4 button--type3">
						Crypto
					</NavLink>
					<NavLink to={`${url}/fiat`} className="button button--size4 button--type3">
						Fiat
					</NavLink>
				</div>
				<Switch>
					<Route exact path={`${path}/crypto`} component={CryptoHistory} />
					<Route exact path={`${path}/fiat`} component={FiatHistory} />
					<Redirect to={`${path}/crypto`} />
				</Switch>
			</div>
		</div>
	);
};

export default TransferHistoryTabView;
