import React, { FC } from 'react';
import { IPlatformDataItem } from 'redux/reducers/walletBalance/types';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: IPlatformDataItem;
}

const Item: FC<Props> = ({ item }) => {
	const { code, balances, name } = item;
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Cryptocurrency</p>
					<div className="td__wrap">
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p className="td-name">{code.toUpperCase()}</p>
						<span className="td-more-info">{name}</span>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Platform Balance</p>
					<div className="td__wrap">
						<p>{roundingNumber(balances.valueCrypto, code)}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
