import { FormikProps, FormikValues, useField } from 'formik';
import { ChangeEvent, FC } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from './types';

// ==========================================:
const InputPattern: FC<IAdminInput & FormikProps<FormikValues>> = (props) => {
	const { ariaLabel, type, field, placeholder, inputMode, onKeyUp, disabled, className } = props;

	const [, , { setValue }] = useField(field.name);
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const result = e.target.value.replace(/[^\da-zA-Z]/g, '');
		setValue(result.toUpperCase());
	};

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			<input
				className={`input-item ${String(className)}`}
				{...field}
				type={type}
				placeholder={placeholder}
				onKeyUp={onKeyUp}
				disabled={disabled}
				onChange={onChange}
				inputMode={inputMode || null}
			/>
		</FormControl>
	);
};

export default InputPattern;
