import React, { FC } from 'react';
import classNames from 'classnames';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IBalanceListProps } from './types';

const BalanceList: FC<IBalanceListProps> = ({ list, activeIndex, onChangeActive }) => {
	return (
		<div className="balance-info-list">
			{list?.map((item, index) => (
				<div
					key={item.name}
					className={classNames('balance-info-list__items', { active: index === activeIndex })}
					onClick={() => onChangeActive(index)}
				>
					<div className="box-info">
						<div className="box-info_img">
							{/* <img
								src={item?.img?.replace('oneify-stage.corp.merehead.xyz', 'dev1.oneify.cybro.cz')}
								alt={item?.code}
							/> */}
							{!!item?.code && (
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								<img src={`/img/currencies/${item?.code.toLowerCase()}.svg`} alt={item?.name} />
							)}
						</div>
						<div className="box-info_name">
							{item?.code && item?.name && (
								<>
									<span className="box-info_name__code">{item.code}</span>
									<span className="box-info_name__name">{item.name}</span>
								</>
							)}
						</div>
					</div>
					<div className="box-info-coin">
						<p className="text-sm-center">
							{item?.code && (
								<>
									{roundingNumber(item.valueCrypto, item.code)} {String(item.code)}
								</>
							)}
						</p>
					</div>
					<div className="box-info-coin">
						<p className="text-sm-right">{roundingNumber(item.valueEuro, 'EUR')} EUR</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default BalanceList;
