import { notificationContainer } from 'services/utils/notificationContainer';
import { Errors } from 'services/utils/notificationsMessages/notificationsMessagesError';
import { ErrorsInfo } from 'services/utils/notificationsMessages/notificationsMessagesInfo';

// ==========================================:
const customResponseErrors = (errorMessage: string, type: string, response: any) => {
	const urlPath = response.config.url;
	const params: string = urlPath?.split('/').pop()?.replace('_', '/').toUpperCase() || ' ';
	if (errorMessage === 'Pair_not_available') {
		notificationContainer(`Pair ${params}  not available. Choose another pair.`, type);
	} else if (Errors[String(errorMessage.replace('Errors.', ''))]) {
		notificationContainer(String(Errors[String(errorMessage.replace('Errors.', ''))]), type);
	} else if (ErrorsInfo[String(errorMessage.replace('Errors.', ''))]) {
		notificationContainer(String(ErrorsInfo[String(errorMessage.replace('Errors.', ''))]), type);
	} else notificationContainer(errorMessage, type);
};
export { customResponseErrors };
