import React, { FC, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import {
	getBankAccounts,
	getBankAccountsLoading,
	getBankAccountsPageInfo,
	getBankAccountsPagesCount,
} from 'redux/reducers/bankAccounts/selectors';
import {
	changeBankAccountsPage,
	changeBankAccountsUserId,
	deleteBankAccountRequest,
	getBankAccountsRequest,
	updateBankAccountRequest,
} from 'redux/reducers/bankAccounts/reducer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import { IBankAccountRequestPayload } from 'redux/reducers/bankAccounts/types';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import PaymentMethodFiatItem from './PaymentMethodFiatItem/PaymentMethodFiatItem';

import { IAccountDetailsParams } from '../../types';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import { IPaymentMethodTab } from '../PaymentMethodTab';
import Loading from '../../../../layouts-elements/Loading/Loading';
import { currencyDataRequest } from '../../../../redux/reducers/currency/reducer';
import { getCurrencyData } from '../../../../redux/reducers/currency/selectors';

const PaymentMethodFiat: FC<IPaymentMethodTab> = ({ setUpdatingBankHandler }) => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const bankAccountsData = useSelector(getBankAccounts);
	const bankAccountsPageInfo = useSelector(getBankAccountsPageInfo);
	const bankAccountsPagesCount = useSelector(getBankAccountsPagesCount);
	const permissions = useSelector(getPermissions);
	const loading = useSelector(getBankAccountsLoading);
	const currencies = useSelector(getCurrencyData);

	useLayoutEffect(() => {
		dispatch(changeBankAccountsUserId(Number(userId)));
		dispatch(getBankAccountsRequest());
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const newPageInfo = { ...bankAccountsPageInfo, current_page: selected + 1 };
		dispatch(changeBankAccountsPage(newPageInfo));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your bank account "${nickname}" was deleted successfully.`,
			}),
		);
		dispatch(deleteBankAccountRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Bank Account',
				message: 'Are you sure you want to delete the account?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
		dispatch(popUpOpen('warningPopUp'));
	};

	const updateHandler = (obj: IBankAccountRequestPayload) => {
		dispatch(updateBankAccountRequest(obj));
	};

	// useLayoutEffect(() => {
	// 	console.log(bankAccountsData);
	// }, [bankAccountsData]);
	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--payment-method-fiat_5 table---custom-payment-method-fiat_5">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Beneficiary Name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Bank Details</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Account Number</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">{/* <p>Action</p> */}</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{bankAccountsData?.length ? (
										bankAccountsData?.map((el: any) => (
											<PaymentMethodFiatItem
												permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
												key={el.id}
												deleteHandler={deleteHandler}
												updateHandler={updateHandler}
												setUpdatingBankHandler={setUpdatingBankHandler}
												currencies={currencies}
												{...el}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}

							{loading ? <Loading /> : null}
						</div>
						<div className="table-footer table-footer--type2">
							{bankAccountsPagesCount > bankAccountsPageInfo.per_page && (
								<div className="pagination-block justify-content-center">
									<ReactPaginate
										breakLabel="..."
										pageCount={Math.ceil(bankAccountsPagesCount / bankAccountsPageInfo.per_page)}
										onPageChange={handlePageClick}
										className="pagination"
										activeClassName="active"
										previousClassName="pagination__arrow pagination__arrow--prev"
										nextClassName="pagination__arrow pagination__arrow--next"
										previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
										nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PaymentMethodFiat;
