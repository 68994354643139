export interface ITransactionsFiltersStore {
	cryptoFilters: ITransactionsFilter[];
	fiatFilters: ITransactionsFilter[];
	pooledDepositsFilters: ITransactionsFilter[];
	tradesFilters: ITransactionsFilter[];
	profitWalletFilters: ITransactionsFilter[];
	pendingFiatWithdrawalFilters: ITransactionsFilter[];
	pendingCryptoWithdrawalFilters: ITransactionsFilter[];
	referralFilters: ITransactionsFilter[];
	bankAccountsWithSelectFilters: ITransactionsFilter[];
	ledgerFilters: ITransactionsFilter[];
}

export enum EFiltersType {
	TRANSACTIONS_CRYPTO = 'cryptoFilters',
	TRANSACTIONS_FIAT = 'fiatFilters',
	TRANSACTIONS_POOLED_DEPOSITS = 'pooledDepositsFilters',
	TRANSACTIONS_TRADES = 'tradesFilters',
	TRANSACTIONS_PROFIT_WALLET = 'profitWalletFilters',
	TRANSACTIONS_FIAT_PENDING_WITHDRAWALS = 'pendingFiatWithdrawalFilters',
	TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS = 'pendingCryptoWithdrawalFilters',
	REFERRAL_FILTERS = 'referralFilters',
	BANK_ACCOUNTS_WITH_SELECT = 'bankAccountsWithSelectFilters',
	TRANSACTIONS_LEDGER = 'ledgerFilters',
}

export enum ETransactionsFilters {
	DATE = 'date',
	ASSET_ID = 'asset_id',
	STATUS = 'status',
	USER_ID = 'user_id',
	ADMIN_ID = 'admin_id',
	EMAIL = 'email',
	COMPANY_NAME = 'company_name',
	BENEFICIARY_NAME = 'beneficiary_name',
	BENEFICIARY_ADDRESS = 'beneficiary_address',
	BANK_ADDRESS = 'bank_address',
	SWIFT_BIC = 'swift_bic',
	ACCOUNT_NUMBER = 'account_number',
	REQUISITE_NAME = 'requisite_name',
	BANK_NAME = 'bank_name',
	BANK = 'bank',
	FULL_NAME = 'full_name',
	TYPE = 'type',
	GROSS = 'gross_fee',
	BANK_NICKNAME = 'bank_nickname',
	TX_ID = 'id',
	SELL = 'sell',
	BUY = 'buy',
	CURRENCY = 'currency',
	ONEIFY_ID = 'oneify_id',
}

export interface ITransactionsDateFilterValue {
	date_to: Date;
	date_from: Date;
}
export interface ITransactionsFromAndToFilterValue {
	to: string;
	from: string;
}

export interface ITransactionsDeleteFilterPayload {
	type: ETransactionsFilters;
	filterType: EFiltersType;
}

export interface ITransactionsFilter {
	type: ETransactionsFilters;
	name?: string;
	value: string | ITransactionsDateFilterValue | ITransactionsFromAndToFilterValue;
	filterType: EFiltersType;
}
