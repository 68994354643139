import { FC } from 'react';
import { roundingNumber } from 'services/utils/roundingNumber';
import { ICustomTooltipProps } from './types';

const CustomTooltip: FC<ICustomTooltipProps> = (props) => {
	const { active, payload } = props;
	const itemPayload = payload?.[0]?.payload;

	if (!active || !itemPayload) {
		return null;
	}
	const { valueCrypto, valueEuro, name, code } = itemPayload;

	return (
		<>
			<div className="custom-tooltip_block">
				<p className="custom-tooltip_block__text crypto">
					{roundingNumber(valueCrypto, code)} {name}
				</p>
				<p className="custom-tooltip_block__text eur">{roundingNumber(valueEuro, 'eur')} EUR</p>
			</div>
		</>
	);
};

export default CustomTooltip;
