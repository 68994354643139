import React from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CryptoHistory from '../CryptoHistory';
import FiatHistory from '../FiatHistory';
import TradeHistory from '../TradeHistory';

const TransactionHistoryTabView = () => {
	const { url, path } = useRouteMatch();

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<Switch>
					<Route exact path={`${path}/trade`} component={TradeHistory} />
					<Redirect to={`${path}/trade`} />
				</Switch>
			</div>
		</div>
	);
};

export default TransactionHistoryTabView;
