/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { ChangeEvent } from 'react';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { useSelector, useDispatch } from 'react-redux';
import { setUserAdmin } from 'redux/reducers/users/reducer';
import { Link, useLocation } from 'react-router-dom';
import { getOneUser, getOneUserLoading, getUserAdmin } from 'redux/reducers/users/selectors';
import { navListAfterLogin } from 'routes/routesList';
import Loading from '../../../layouts-elements/Loading/Loading';

const AccountHeader = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(getOneUser);
	const loading = useSelector(getOneUserLoading);
	const userAdmin = useSelector(getUserAdmin);

	const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;
		dispatch(setUserAdmin(checked));
	};
	const path = location?.pathname?.split('/')[3];

	return (
		<div className="account-header">
			<div className="title-block-wrap">
				{/* <div className="title-block">
					<p className="title title--type2 typeTitle">User Management</p>
				</div> */}
				<div className="title-block">
					<Link to={navListAfterLogin.userManagement.path} className="back-btn">
						<button type="button" className="wallet-operations-header__back" />
					</Link>
					{!loading ? (
						<p className="title title--type2">
							{user?.data.first_name} {user?.data.last_name}
							<span className="title__user-id">User ID: {user?.id}</span>
							{user?.data.company_name && (
								<span className="title__info">{user?.data.company_name}</span>
							)}
						</p>
					) : (
						<p className="title title--type2">
							<Loading height={25} />
						</p>
					)}
				</div>

				{/* {path === 'required-emails' && (
					<div className="switch">
						<label className="switch__label">
							<span className="switch__text">
								<p>User/ Admin</p>
							</span>
							<input
								type="checkbox"
								onChange={handleSwitch}
								defaultChecked={Boolean(userAdmin)}
								className="hidden"
							/>
							<span className="switch__toggler" />
						</label>
					</div>
				)} */}
			</div>
		</div>
	);
};
export default AccountHeader;
