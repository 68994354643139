/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { roundingNumber } from 'services/utils/roundingNumber';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	EApiPooledDepositTransactionStatus,
	IApiPooledDeposit,
} from 'services/api/transactions/types';
import { getCryptoCurrencyData, getCurrencyData } from 'redux/reducers/currency/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import { shortNotation } from 'services/utils/strings';

const PooledDepositRow: FC<IApiPooledDeposit> = ({
	user,
	amount,
	status,
	created_at,
	tx_hash,
	tx_id,
	asset_id,
	chain_id,
	destination,
	id,
}) => {
	const [date, time] = useFormatDateTime(created_at);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const currency = useSelector(getCurrencyData);

	const filterCurrency = useMemo(() => {
		return cryptoCurrencyData?.find((el) => el.id === asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cryptoCurrencyData]);
	const filterNetwork = useMemo(() => {
		return filterCurrency?.chains.find((el) => el.id === chain_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterCurrency]);

	const handleSearchAssetName = (assetId: number) => {
		const asset = currency.filter((item) => item.id === assetId)[0];
		return asset.name;
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p className="td-name--flex">{user.id}</p>

				{/* <Link to={`/user-management/${String(user.id)}`} className="link">
					{user.email}
				</Link> */}
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{filterCurrency?.code.toUpperCase()}</p>
				<span className="td-more-info"> {handleSearchAssetName(asset_id)}</span>
			</div>
			{/* <div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{filterNetwork?.code || '-'}</p>
				<span className="td-more-info">{filterNetwork?.name}</span>
			</div> */}
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{roundingNumber(amount, 'btc')}</p>
				<span className="td-more-info">{filterCurrency?.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Destination</p>
				<p className="copy-button">
					<span className="copy-button__text copy-button__text--max-width">
						{destination ? shortNotation(destination, 4, 4) : '-'}
					</span>
					{!!destination && (
						<CopyToClipboard text={destination.toString()} onCopy={handleCopy}>
							<button type="button">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="15"
									height="14"
									viewBox="0 0 15 14"
									fill="none"
								>
									<path
										d="M2.875 12.8151C2.64167 12.8151 2.4375 12.7276 2.2625 12.5526C2.0875 12.3776 2 12.1734 2 11.9401V3.14635H2.875V11.9401H9.7875V12.8151H2.875ZM4.625 11.0651C4.39167 11.0651 4.1875 10.9776 4.0125 10.8026C3.8375 10.6276 3.75 10.4234 3.75 10.1901V2.02344C3.75 1.7901 3.8375 1.58594 4.0125 1.41094C4.1875 1.23594 4.39167 1.14844 4.625 1.14844H11.0417C11.275 1.14844 11.4792 1.23594 11.6542 1.41094C11.8292 1.58594 11.9167 1.7901 11.9167 2.02344V10.1901C11.9167 10.4234 11.8292 10.6276 11.6542 10.8026C11.4792 10.9776 11.275 11.0651 11.0417 11.0651H4.625ZM4.625 10.1901H11.0417V2.02344H4.625V10.1901Z"
										fill="#5974FF"
									/>
								</svg>
								Copy
							</button>
						</CopyToClipboard>
					)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx Hash</p>
				<p className="copy-button">
					<span className="copy-button__text copy-button__text--max-width">
						{shortNotation(tx_hash, 4, 4) || '-'}
					</span>
					{!!tx_hash && (
						<CopyToClipboard text={tx_hash.toString()} onCopy={handleCopy}>
							<button type="button">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="15"
									height="14"
									viewBox="0 0 15 14"
									fill="none"
								>
									<path
										d="M2.875 12.8151C2.64167 12.8151 2.4375 12.7276 2.2625 12.5526C2.0875 12.3776 2 12.1734 2 11.9401V3.14635H2.875V11.9401H9.7875V12.8151H2.875ZM4.625 11.0651C4.39167 11.0651 4.1875 10.9776 4.0125 10.8026C3.8375 10.6276 3.75 10.4234 3.75 10.1901V2.02344C3.75 1.7901 3.8375 1.58594 4.0125 1.41094C4.1875 1.23594 4.39167 1.14844 4.625 1.14844H11.0417C11.275 1.14844 11.4792 1.23594 11.6542 1.41094C11.8292 1.58594 11.9167 1.7901 11.9167 2.02344V10.1901C11.9167 10.4234 11.8292 10.6276 11.6542 10.8026C11.4792 10.9776 11.275 11.0651 11.0417 11.0651H4.625ZM4.625 10.1901H11.0417V2.02344H4.625V10.1901Z"
										fill="#5974FF"
									/>
								</svg>
								Copy
							</button>
						</CopyToClipboard>
					)}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">TxID</p>
				<p className="link">{id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span
					className={
						status === EApiPooledDepositTransactionStatus.ADMIN_PENDING
							? 'pending-status'
							: `${status}-status`
					}
				>
					{status === EApiPooledDepositTransactionStatus.ADMIN_PENDING
						? `Pending`
						: `${status.charAt(0).toUpperCase()}${status.slice(1)}`}
				</span>
			</div>
		</div>
	);
};

export default PooledDepositRow;
