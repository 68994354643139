import { FC, useLayoutEffect, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import IconSvg from 'ui/Svg/IconSvg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { IFilterSelectProps } from './types';

const FilterSelect: FC<IFilterSelectProps> = ({
	options,
	selected,
	onChange,
	title,
	outputValue = 'name',
	icons,
	reset,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [currentSelected, setCurrentSelected] = useState<any>(selected);
	const selectBlockRef = useRef<HTMLDivElement | null>(null);
	useClickOutside(selectBlockRef, () => setIsOpen(false));
	const handleClick = (option: any) => {
		if (option !== currentSelected) {
			onChange(option);
		}
		setCurrentSelected(option);
		setIsOpen(false);
	};

	useLayoutEffect(() => {
		if (reset) {
			setCurrentSelected(selected);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reset, selected]);

	return (
		<div ref={selectBlockRef} className={`select select--table ${isOpen ? 'active' : ''}`}>
			<button type="button" className="select__current" onClick={() => setIsOpen(!isOpen)}>
				{icons && currentSelected.code ? (
					<div className="selected__current_text">
						<img
							src={
								// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require, @typescript-eslint/restrict-template-expressions
								require(`assets/img/currencies/${currentSelected.code.toLowerCase()}.svg`).default
							}
							alt={currentSelected.name}
						/>

						<p>{currentSelected.name}</p>
					</div>
				) : (
					<>{title || currentSelected.name}</>
				)}

				<span className="select__current-arrow">
					<IconSvg name="keyboard-arrow-down" w="9" h="6" />
				</span>
			</button>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						style={{ display: 'block' }}
						className="select__drop select__drop--status"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.3 } }}
						exit={{ opacity: 0, transition: { duration: 0.3 } }}
					>
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								<ul>
									{options.map((option) => (
										<li key={option.id}>
											<button type="button" onClick={() => handleClick(option)}>
												{icons ? (
													<div className="selected__current_text">
														{option.path && option.code && (
															<img
																src={
																	// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require, @typescript-eslint/restrict-template-expressions
																	require(`assets/img/currencies/${option?.code.toLowerCase()}.svg`)
																		.default
																}
																alt={option[outputValue]}
															/>
														)}
														{option[outputValue]}
													</div>
												) : (
													<>{option[outputValue]}</>
												)}
											</button>
										</li>
									))}
								</ul>
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default FilterSelect;
