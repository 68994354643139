import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';

const CurrenciesTab = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);
	const permissions = useSelector(getPermissions);

	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable} />;
			case EPaymentMethodTab.FIAT:
				return <Fiat permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable} />;
			default:
				return <Crypto permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable} />;
		}
	};

	const setToWithdrawTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};

	const setToDepositTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};

	return (
		<div>
			<div className="tabs-buttons tabs-buttons--transfer-history">
				<button
					type="button"
					className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
					onClick={setToWithdrawTab}
				>
					Crypto
				</button>

				<button
					type="button"
					className={activeTab === EPaymentMethodTab.FIAT ? 'active' : ''}
					onClick={setToDepositTab}
				>
					Fiat
				</button>
			</div>

			{chooseTab()}
		</div>
	);
};

export default CurrenciesTab;
