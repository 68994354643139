import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { clearError } from 'redux/reducers/errors/reducer';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { postButtonsRequest } from 'redux/reducers/walletBalance/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IPopUp } from '../types';

const WalletBalancesTwoFaCodePopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	title,
	message,
	currency,
	depositAmount,
	userID,
	companyName,
	balance,
	totalBalance,
	payload,
	walletName,
	total_balance,
}) => {
	const [code, setCode] = useState('');
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState('');

	const try2Fa = (text: string | number) => {
		if (!text) {
			// notificationContainer('Enter 2fa field.', 'info');
			setErrorMessage('Please enter 2FA code.');

			return false;
		}
		if (String(text).length > 6 || String(text).length < 6) {
			setErrorMessage('Please enter 6 character 2FA code.');

			// notificationContainer('Enter 6 2fa code.', 'info');
			return false;
		}
		return true;
	};

	const submitButtons = () => {
		if (!try2Fa(code)) return;
		if (total_balance === 0) {
			notificationContainer('Amount is too low.', 'amount_error', 'Transfer error');
			closeModal();
			return;
		}
		const params: any = {
			apiParams: {
				...payload,
				totp: code,
			},
			currencyInfo: {
				coin: currency,
				walletName,
			},
			//
			onFinally: () => closeModal(),
		};

		dispatch(postButtonsRequest(params));
	};
	const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setErrorMessage('');
		dispatch(clearError());
		const { value } = event.target;
		if (!value) {
			setErrorMessage('Please enter 2FA code.');
		}
		if (value.length > 6) {
			setErrorMessage('Please enter 6 character 2FA code.');
		}
		setCode(value);
	};
	const errorMsg = useSelector((state: any) => state.error.errorMsg);

	useLayoutEffect(() => {
		if (errorMsg === 'invalid_totp_code') {
			setErrorMessage('Your 2FA code is incorrect.');
		}
	}, [errorMsg]);

	return (
		<Popup
			open={open}
			closeOnDocumentClick
			onClose={() => {
				closeModal();
				dispatch(clearError());
			}}
		>
			<div className="popup popup--width-660">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button
					type="button"
					className="popup__close-btn"
					onClick={() => {
						closeModal();
						dispatch(clearError());
					}}
				/>
				<div className="popup-header">
					<p className="popup-header__title">{title}</p>
				</div>
				<div className="popup-body">
					{depositAmount || currency || userID || companyName || balance ? (
						<div className="popup-table mb-16">
							{currency && (
								<div className="popup-table__tr">
									<div className="popup-table__td">Currency:</div>
									<div className="popup-table__td">
										<span>{currency}</span>
									</div>
								</div>
							)}
							{userID && (
								<div className="popup-table__tr">
									<div className="popup-table__td">User ID:</div>
									<div className="popup-table__td">
										<span>{userID}</span>
									</div>
								</div>
							)}
							{companyName && (
								<div className="popup-table__tr">
									<div className="popup-table__td">Company Name:</div>
									<div className="popup-table__td">
										<span>{companyName}</span>
									</div>
								</div>
							)}
							{balance && (
								<div className="popup-table__tr">
									<div className="popup-table__td">Balance:</div>
									<div className="popup-table__td">
										<span>{balance}</span>
									</div>
								</div>
							)}
							{depositAmount && (
								<div className="popup-table__tr">
									<div className="popup-table__td">Deposit Wallets:</div>
									<div className="popup-table__td">
										<span>{depositAmount}</span>
									</div>
								</div>
							)}
							{totalBalance && (
								<div className="popup-table__tr">
									<div className="popup-table__td">Total Balance:</div>
									<div className="popup-table__td">
										<span>{totalBalance}</span>
									</div>
								</div>
							)}
						</div>
					) : null}
					<div className="input mb-0">
						<p className="input__name_wallets_popup">2FA Code</p>
						<div className={errorMessage ? 'input-wrapper input-wrapper--error' : 'input-wrapper'}>
							<input
								value={code}
								onChange={handleCodeChange}
								className="input-item"
								type="text"
								placeholder="Enter 2FA Code"
								onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
										event.preventDefault();
									}
								}}
							/>
						</div>
						{errorMessage && <div className="input__notification">{errorMessage}</div>}
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column mt-16">
						<button
							onClick={() => {
								submitButtons();
							}}
							type="button"
							className="btn btn-primary btn--full"
						>
							Confirm
						</button>
						<button
							onClick={() => {
								closeModal();
								dispatch(clearError());
							}}
							type="button"
							className="btn btn btn-outline btn-secondary"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default WalletBalancesTwoFaCodePopUp;
