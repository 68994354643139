import React, { FC, ReactElement, useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { IReferralUser } from 'services/api/referrals/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import { ITradeContext } from 'redux/reducers/referrals/types';
import { delReferralByUserRequest } from 'redux/reducers/referrals/reducer';
import WarningPopUp from 'layouts-elements/PopUp/WarningPopUp/WarningPopUp';
import { IAccountDetailsParams } from 'components/AccountDetails/types';
import IconSvg from 'ui/Svg/IconSvg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { getReferralSystem } from '../../../../redux/reducers/users/selectors';
import { referralSystemRequest } from '../../../../redux/reducers/users/reducer';

const ReferralsByUserTableRow: FC<IReferralUser & { permission: boolean }> = ({
	id,
	email,
	tradeVolume,
	data,
	permission,
}) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();

	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [showTradeSize, setShowTradeSize] = useState(false);
	const closeConfirmModal = () => {
		setOpenConfirmModal(false);
	};
	const handleOpenConfirmModal = () => {
		setOpenConfirmModal(true);
	};

	const isTradeVolumesBlank = !!Object.keys(tradeVolume.all_in_eur).length;

	const tradeVolumeMap = (obj: ITradeContext, fn: (value: string, key: string) => ReactElement) =>
		isTradeVolumesBlank && Object.entries(obj).map(([key, value]) => fn(key, value));

	const createTradeVolumeElement = (key: string, value: string) => (
		<div key={key} className="trade-size-item">
			<p>{key.toUpperCase()}</p>
			<p>{roundingNumber(value)}</p>
		</div>
	);
	const handleConfirm = useCallback(() => {
		dispatch(delReferralByUserRequest({ userId, referral_id: String(id) }));
	}, [dispatch, id, userId]);
	useLayoutEffect(() => {
		dispatch(referralSystemRequest(userId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const referralSystem = useSelector(getReferralSystem);
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<Link to={`${ROUTES.userManagement.index}/${id}`}>{id}</Link>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Name</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>
							{data.first_name} {data.last_name}
						</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<Link to={`${ROUTES.userManagement.index}/${id}`}>{email}</Link>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Company Name</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>{data.company_name}</p>
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referral Fee</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="td__text">
						<p>{referralSystem?.commission}</p>
					</div>
				</div>
			</div>
			<div className="td" onMouseLeave={() => setShowTradeSize(false)}>
				<p className="td-hidden-name">Month Trade Size, EUR</p>
				<div className="td__wrap td__wrap--column td__wrap--column-no-gap">
					<div className="flex flex-m">
						<p>{roundingNumber(tradeVolume?.total)}</p>
						{isTradeVolumesBlank && (
							<div className="trade-size" onMouseEnter={() => setShowTradeSize(true)}>
								<IconSvg name="layers" w="17" h="17" />
								<AnimatePresence>
									{showTradeSize && (
										<motion.div
											className="trade-size__drop"
											initial={{ opacity: 0 }}
											animate={{ opacity: 1, transition: { duration: 0.3 } }}
											exit={{ opacity: 0, transition: { duration: 0.3 } }}
										>
											<div className="trade-size-list">
												<div className="trade-size-item trade-size-item--header">
													<p>Currency </p>
													<p>Trade Volume, EUR</p>
												</div>
												{tradeVolumeMap(tradeVolume.all_in_eur, createTradeVolumeElement)}
											</div>
										</motion.div>
									)}
								</AnimatePresence>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				{permission && (
					<div className="table-buttons flex-e">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon-danger btn--delete"
							onClick={handleOpenConfirmModal}
						/>
					</div>
				)}
			</div>
			<WarningPopUp
				open={openConfirmModal}
				closeModal={closeConfirmModal}
				title="Delete Referral"
				message="Are you sure you want to delete the referral?"
				confirmHandler={handleConfirm}
			/>
		</div>
	);
};

export default ReferralsByUserTableRow;
