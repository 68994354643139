import { useFormatDateTime } from 'hooks/useFormatDateTime';
import React, { FC } from 'react';
import { toMaxDecimals } from 'services/utils/numbers';
import { capitalize } from 'services/utils/strings';
import { statusTitle } from 'components/AccountDetails/TransferHistoryTab/utils';
import moment from 'moment';
import { useHistory } from 'react-router';
import { IFiatHistoryRowProps } from '../../CryptoHistory/types';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const FiatHistoryRow: FC<IFiatHistoryRowProps> = ({ item, type }) => {
	const [date, time] = useFormatDateTime(item.created_at);
	const history = useHistory();

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div>
					{type === 'deposit' ? (
						<>
							<p className="td-name--flex">{moment.utc(item.created_at).format('YYYY-MM-DD')}</p>
							<span className="td-more-info">{moment.utc(item.created_at).format('HH:mm:ss')}</span>
						</>
					) : (
						<>
							<p className="td-name--flex">{date}</p>
							<span className="td-more-info">{time}</span>
						</>
					)}
				</div>
			</div>
			<div className="td td--width_90">
				<p className="td-hidden-name">Currency</p>
				<div>
					<p className="td-name--flex">{item.asset.code.toUpperCase()}</p>
					<span className="td-more-info">{item?.asset?.name}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">
					{history.location.pathname.includes('deposit-history') ? 'Bank Name' : 'Beneficiary'}
				</p>
				<p>{item?.bank_account?.bank_name || item?.admin_requisite?.bank_name || '-'}</p>
				<span className="td-more-info">
					{item?.bank_account?.account_number || item?.admin_requisite?.account_number || '-'}
				</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Gross</p>
				<p>{roundingNumber(item.gross_fee, item.asset.code)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<div>
					<p>{roundingNumber(item.fee, item.asset.code)}</p>
					<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Net</p>
				<p>{toFixedNumber(item.net_fee, item.asset.code, true)}</p>
				<span className="td-more-info">{item.asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx ID</p>

				<p>{item.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<span className={`${item.status}-status`}>{statusTitle[item.status]}</span>
			</div>
		</div>
	);
};

export default FiatHistoryRow;
