/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useLayoutEffect, useState } from 'react';
import { IPermissionRowProps } from './types';

const permissions = {
	permission_dashboard: 'Dashboard',
	admin_management: 'Admin management',
	deposit_account: 'Deposit Account',
	user_management: 'User Management',
	reset_request: 'Reset Request',
	default_fee_management: 'Default Fee Management',
	transfer_trade_report: 'Transfer & Trade Reports',
	wallet_management: 'Wallet Management',
	referral_management: 'Referral Management',
	required_emails: 'Required Emails',
};

const PermissionRow: FC<IPermissionRowProps> = ({
	title,
	id,
	onChange,
	editable,
	only_read,
	permission,
}) => {
	const [toggler, setToggler] = useState({ editable: false, only_read: false });

	const handleChange = (e: any) => {
		const { name, checked }: { name: 'editable' | 'only_read'; checked: boolean } = e.target;
		if (name === 'editable' && checked) {
			setToggler({ editable: checked, only_read: true });
			return;
		}
		if (name === 'only_read') {
			setToggler({ editable: false, only_read: checked });
			return;
		}
		setToggler({ ...toggler, [name]: checked });
	};

	useLayoutEffect(() => {
		if (editable || only_read) {
			setToggler({ editable: Boolean(editable), only_read: Boolean(only_read) });
		}
	}, [editable, only_read]);

	useLayoutEffect(() => {
		onChange(title, { id, ...toggler });
	}, [toggler]);

	const drawTitle = () => {
		const index = title.replaceAll(' ', '_') as keyof typeof permissions;
		return permissions[index];
	};

	return (
		<div className="create-admin__table-item" onChange={handleChange}>
			<div className="create-admin__table-title">
				<p>{drawTitle()}</p>
			</div>
			<div className="create-admin__table-toggler">
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							disabled={!permission}
							name="only_read"
							type="checkbox"
							className="hidden"
							checked={!!toggler.only_read}
						/>
						<span className="switch__toggler" />
						<p className="switch__text">Read Only</p>
					</label>
				</div>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							disabled={!permission}
							name="editable"
							type="checkbox"
							className="hidden"
							checked={!!toggler.editable}
						/>
						<span className="switch__toggler" />
						<p className="switch__text">Edit</p>
					</label>
				</div>
			</div>
		</div>
	);
};

export default PermissionRow;
