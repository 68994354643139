import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from '../types';

const SuccessPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, title, message }) => {
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header">
					<p className="popup-header__title">{title || 'Successful'}</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="81"
						height="80"
						viewBox="0 0 81 80"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="1" y="0.5" width="79" height="79" rx="39.5" stroke="#5EC45C" />
						<rect x="21" y="20" width="40" height="40" rx="20" fill="#5EC45C" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M41.0008 30.7C35.8645 30.7 31.7008 34.8638 31.7008 40C31.7008 45.1363 35.8645 49.3 41.0008 49.3C46.137 49.3 50.3008 45.1363 50.3008 40C50.3008 34.8638 46.137 30.7 41.0008 30.7ZM30.3008 40C30.3008 34.0906 35.0913 29.3 41.0008 29.3C46.9102 29.3 51.7008 34.0906 51.7008 40C51.7008 45.9095 46.9102 50.7001 41.0008 50.7001C35.0913 50.7001 30.3008 45.9095 30.3008 40Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M46.4345 35.4507C46.738 35.6902 46.7898 36.1304 46.5502 36.4338L40.2344 44.4338C40.1097 44.5918 39.9231 44.6884 39.7222 44.6991C39.5212 44.7098 39.3254 44.6335 39.1847 44.4897L35.5005 40.725C35.2301 40.4487 35.2349 40.0055 35.5112 39.7351C35.7875 39.4647 36.2307 39.4695 36.5011 39.7458L39.6287 42.9417L45.4514 35.5663C45.6909 35.2629 46.1311 35.2111 46.4345 35.4507Z"
							fill="white"
						/>
					</svg>
				</div>
				{!!message && (
					<div className="popup-text mb-0">
						<p>{message}</p>
					</div>
				)}
				{/* <div className="popup-footer">
					<div className="popup-submit">
						<button onClick={closeModal} type="button" className="btn btn-primary btn--full">
							Ok
						</button>
					</div>
				</div> */}
			</div>
		</Popup>
	);
};

export default SuccessPopUp;
