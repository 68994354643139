import { FC, useState } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { CheckIcon, CloseIcon, PencilSmallIcon } from 'assets/inline-svg';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';

export interface IFeeAssetItem {
	commission: string;
}

export interface IFeeManagementItem {
	updateHandler: (obj: IFeeAssetItem) => void;
	commision: string;
}
const ReferralItem: FC<IFeeManagementItem> = ({ commision, updateHandler }) => {
	const permissions = useSelector(getPermissions);
	const [changeableMode, setChangeableMode] = useState<boolean>(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		commission: commision || '',
	};
	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							commission: String(values?.commission),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Referral %</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											name="commission"
											onKeyDown={(event) => event}
											onChange={(event) =>
												handleFeeFixedChange(event, props.setFieldValue, 'commission', 'percent')
											}
											onBlur={props.handleBlur}
											onWheel={(event) => event.currentTarget.blur()}
											value={props.values.commission}
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Referral %</p>
						<div className="td__wrap">
							<p>{commision}</p>
						</div>
					</div>
					{!!permissions?.[EPermissionNames.DEPOSIT_ACCOUNT].editable && (
						<div className="td td--right">
							<p className="td-hidden-name">Action</p>
							<div className="table-buttons flex-e">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button
									type="button"
									onClick={switchOnChangeableMode}
									className="btn--icon btn--edit"
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default ReferralItem;
