import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LoginForm from 'components/Login/LoginForm';
import Logo from '../../assets/img/sidebar_logo_gray.svg';

const LoginPage = () => {
	// const [bannerPositionLeft, setBannerPositionLeft] = useState(1);

	/* useLayoutEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const rect = loginBannerRect.left - bodyRect.left;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setBannerPositionLeft(rect);
	}, []); */

	return (
		<>
			<Helmet>
				<body className="login-page" />
				{/* <style type="text/css">{`
						:root {
								--login-banner-left: ${bannerPositionLeft}px;
						}
				`}</style> */}
			</Helmet>
			<section className="login-section">
				<a href="/" className="login-section__logo">
					<img src={Logo} alt="" />
				</a>
				<div className="login">
					<div className="login-form">
						<LoginForm />
					</div>
				</div>
			</section>
		</>
	);
};

export default LoginPage;
