import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOneUser } from 'redux/reducers/users/selectors';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { putUserChangeReferralRequest } from 'redux/reducers/users/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import ReferralItem from './ReferralItem';

const ReferralManagement = () => {
	const dispatch = useDispatch();
	const user = useSelector(getOneUser);
	const permissions = useSelector(getPermissions);

	const [commision, setCommision] = useState(user?.data?.commission || '0');

	const updateHandler = ({ commission }: any) => {
		if (Number(commission) > 100) {
			notificationContainer('Value must be beetwen 0-100.', 'error');
			return;
		}
		setCommision(commission);
		dispatch(putUserChangeReferralRequest({ user_id: Number(user?.id), commission }));
	};
	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--trade-management">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Referral %</p>
								</div>
							</div>
							{!!permissions?.[EPermissionNames.DEPOSIT_ACCOUNT].editable && (
								<div className="td td--right">
									<div className="td-name">{/* <p>Action</p> */}</div>
								</div>
							)}
						</div>
					</div>
					<div className="table-body">
						<ReferralItem commision={commision} updateHandler={updateHandler} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReferralManagement;
