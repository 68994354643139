import { http } from 'services/http';
import { endpoint } from 'services/endpoint';

import { IMessageApi } from './types';

export const messages: IMessageApi = {
	getMessages: (payload) =>
		http.get(endpoint.messages.GET_MESSAGES, { params: payload }).then(({ data }) => data),
	getOneMessage: ({ messageId }) =>
		http.get(endpoint.messages.GET_ONE_MESSAGE(messageId)).then(({ data }) => data),
	createMessage: ({ body }) =>
		http.post(endpoint.messages.CREATE_MESSAGE, body).then(({ data }) => data),
	deleteMessage: ({ id }) =>
		http.post(endpoint.messages.DELETE_MESSAGE, { id }).then(({ data }) => data),
	updateMessage: ({ body }) =>
		http.post(endpoint.messages.UPDATE_MESSAGE, body).then(({ data }) => data),
	deleteFiles: (payload) =>
		http.post(endpoint.messages.DELETE_FILES, payload).then(({ data }) => data),
	downloadFiles: (payload) =>
		http
			.post(endpoint.messages.DOWNLOAD_FILES, payload, { responseType: 'blob' })
			.then(({ data }) => data),
	cloneMessage: ({ id }) =>
		http.post(endpoint.messages.CLONE_MESSAGE, { id }).then(({ data }) => data),
};
