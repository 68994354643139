export const toFixedNumber = (num: any, curr: any, isFormatted?: boolean) => {
	const cryptoCurrency = ['BTC', 'LTC', 'BCH', 'ETH', 'TRX', 'XRP', 'crypto'];
	let isCrypto = false;
	let finalNumber = '';
	cryptoCurrency.forEach((e) => {
		if (e.toLowerCase() === curr.toLowerCase()) {
			isCrypto = true;
		}
	});
	if (isCrypto) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,prefer-regex-literals
		const re = new RegExp(`^-?\\d+(?:.\\d{0,5})?`);
		// eslint-disable-next-line prefer-destructuring
		finalNumber = num.toString().match(re)[0];
		const afterDot = finalNumber.split('.').pop();
		if (afterDot && afterDot.length === 4 && finalNumber.includes('.')) {
			finalNumber += '0';
		}
		if (afterDot && afterDot.length === 3 && finalNumber.includes('.')) {
			finalNumber += '00';
		}
		if (afterDot && afterDot.length === 2 && finalNumber.includes('.')) {
			finalNumber += '000';
		}
		if (afterDot && afterDot.length === 1 && finalNumber.includes('.')) {
			finalNumber += '0000';
		}
		if (!finalNumber.includes('.')) {
			finalNumber += '.00000';
		}
		return finalNumber;
	}
	// eslint-disable-next-line prefer-regex-literals
	const re = new RegExp(`^-?\\d+(?:.\\d{0,2})?`);
	// eslint-disable-next-line prefer-destructuring
	finalNumber = num.toString().match(re)[0];
	const afterDot = finalNumber.split('.')[1];
	const beforeDot = finalNumber.split('.')[0];
	const beforeDotFormatted = Number(beforeDot).toLocaleString();
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions,@typescript-eslint/ban-ts-comment
	// @ts-ignore
	// console.log(`${beforeDotFormatted}.${afterDot}`);
	if (afterDot && afterDot.length === 1 && finalNumber.includes('.')) {
		if (isFormatted) {
			finalNumber = `${beforeDotFormatted}.${afterDot}`;
		}
		finalNumber += '0';
		return finalNumber;
	}
	if (!finalNumber.includes('.')) {
		if (isFormatted) {
			finalNumber = Number(finalNumber).toLocaleString();
		}
		finalNumber += '.00';
		return finalNumber;
	}
	if (isFormatted) {
		return `${beforeDotFormatted}.${afterDot}`;
	}
	return finalNumber;
};
