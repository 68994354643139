import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPropsPopUp } from './types';
import IconSvg from '../../../ui/Svg/IconSvg';

const StatusUpdatedConfirm: FC<IPropsPopUp & IPopUpData> = ({ open, closeModal, title }) => {
	return (
		<Popup open={open} onClose={closeModal}>
			<div className="popup popup--width-430">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header">
					<p className="popup-header__title">Status Updated</p>
				</div>
				<div className="popup-body">
					<div className="popup-img status-popupImg unblock">
						<IconSvg name="check" w="22" h="22" />
					</div>
				</div>
				<div className="popup-text mb-0">
					<p>User has been {title}</p>
				</div>
				{/* <div className="popup-footer"> */}
				{/*	<div className="popup-submit popup-submit--column"> */}
				{/*		<button type="button" className="btn btn-primary btn--full" onClick={closeModal}> */}
				{/*			Ok */}
				{/*		</button> */}
				{/*	</div> */}
				{/* </div> */}
			</div>
		</Popup>
	);
};

export default StatusUpdatedConfirm;
