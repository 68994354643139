import React, { useState, FC, useLayoutEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { IDatePickerInput } from './types';

import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = React.forwardRef<
	HTMLInputElement,
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>((props, ref) => {
	return (
		<div className="input-wrapper">
			<input ref={ref} type="text" className="input-item" {...props} />
			<div className="input-icon input-icon--bigger input-icon--right">
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M13.75 1.875V4.375"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6.25 1.875V4.375"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M3.125 6.875H16.875"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	);
});

const DatePickerInput: FC<IDatePickerInput> = ({
	placeholder = 'Enter Date',
	onChange,
	resetDatePicker,
	setResetDatePicker,
	inline = false,
	selectsRange = false,
	withCalendarIcon = false,
	showTimeSelect,
}) => {
	const [inputValue, setInputValue] = useState('');
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const onDateChange = (date: Date) => {
		// const formatedDate = date.toLocaleDateString('en-GB').split('/').reverse();
		// const dateMonth = formatedDate[1].length === 1 ? `0${formatedDate[1]}` : formatedDate[1];
		// const dateDay = formatedDate[2].length === 1 ? `0${formatedDate[2]}` : formatedDate[2];
		// const newDate = `${formatedDate[0]}-${dateMonth}-${dateDay}`;
		const newDate = showTimeSelect
			? moment(date).format('YYYY-MM-DD HH:mm')
			: moment(date).format('YYYY-MM-DD');
		// setInputValue(date.toISOString().split('T')[0]);
		setInputValue(newDate);
		!!onChange && onChange(date);
	};
	const onDateChangeRange = (dates: [Date | null, Date | null]) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		!!onChange && start && end && onChange(start, end);
	};

	useLayoutEffect(() => {
		if (resetDatePicker && setResetDatePicker) {
			setInputValue('');
			setResetDatePicker(false);
		}
		if (resetDatePicker) {
			setInputValue('');
			setStartDate(null);
			setEndDate(null);
		}
	}, [resetDatePicker, setResetDatePicker]);

	return (
		<div className="input-wrapper">
			<DatePicker
				startDate={selectsRange ? startDate : undefined}
				endDate={selectsRange ? endDate : undefined}
				placeholderText={placeholder}
				value={!selectsRange ? inputValue : undefined}
				onChange={selectsRange ? onDateChangeRange : onDateChange}
				inline={inline}
				selectsRange={selectsRange}
				maxDate={new Date()}
				showTimeSelect={showTimeSelect}
				customInput={withCalendarIcon ? <CustomInput /> : undefined}
				weekDayClassName={() => 'hidden'}
				renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
					<>
						<div className="react-datepicker__selected-range">
							<div className="selected-range-input">
								{selectsRange && startDate ? (
									moment(startDate?.toLocaleDateString()).format('YYYY/MM/DD')
								) : (
									<p>From</p>
								)}
							</div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
							>
								<path
									d="M10.0003 12.6693L14.667 8.0026L10.0003 3.33594L9.30033 4.03594L12.767 7.5026H1.33366V8.5026H12.767L9.30033 11.9693L10.0003 12.6693Z"
									fill="#2e2ded"
								/>
							</svg>
							<div className="selected-range-input">
								{selectsRange && endDate ? (
									moment(endDate?.toLocaleDateString()).format('YYYY/MM/DD')
								) : (
									<p>To</p>
								)}
							</div>
						</div>
						<div className="react-datepicker__header">
							<button
								type="button"
								className="react-datepicker__navigation react-datepicker__navigation--previous"
								aria-label="Previous Month"
								onClick={decreaseMonth}
							>
								<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
									<svg
										width="7"
										height="11"
										viewBox="0 0 7 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.49983 1.38887L5.38984 0.213867L0.666504 5.21387L5.38984 10.2139L6.49983 9.03887L2.89435 5.21387L6.49983 1.38887Z"
											fill="#ffffff"
										/>
									</svg>
								</span>
							</button>
							<button
								type="button"
								className="react-datepicker__navigation react-datepicker__navigation--next"
								aria-label="Next Month"
								onClick={increaseMonth}
							>
								<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
									<svg
										width="7"
										height="11"
										viewBox="0 0 7 11"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.60998 0.213867L0.5 1.38887L4.10548 5.21387L0.5 9.03887L1.60998 10.2139L6.33332 5.21387L1.60998 0.213867Z"
											fill="#ffffff"
										/>
									</svg>
								</span>
							</button>
							<div className="react-datepicker__current-month">{`${date.toLocaleString('default', {
								month: 'long',
							})} ${date.getFullYear()}`}</div>
						</div>
					</>
				)}
			/>
		</div>
	);
};

export default DatePickerInput;
