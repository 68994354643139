import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import Select from '../index';
import { ICurrencySelect, ICurrencySelectCustomList, ICurrencySelectItem } from './types';

const CurrencySelectCustomInput: FC<ICurrencySelectItem> = ({ code, name, img_path }) => (
	<>
		{code && name ? (
			<span className="coin coin--type10" style={{ display: name && code ? '' : 'none' }}>
				{img_path ? (
					<>
						<span className="coin__icon">
							{/* <img
								src={img_path.replace('oneify-stage.corp.merehead.xyz', 'dev1.oneify.cybro.cz')}
								alt={code}
							/> */}
							<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						</span>
						<span className="coin__text-wrap">
							<span className="coin__text upper__text">
								<span className="coin__text-more">{name}</span>
								{code.toUpperCase()}
							</span>
						</span>
					</>
				) : (
					<span className="coin__text upper__text">
						<span className="coin__text-more">{name}</span>
						{code.toUpperCase()}
					</span>
				)}
			</span>
		) : (
			'Select Currency'
		)}
	</>
);

const CurrencySelectCustomList: FC<ICurrencySelectCustomList> = ({ arr, onChange }) => (
	<>
		{arr &&
			arr.map((el) => (
				<li key={el.code}>
					<button type="button" onClick={() => onChange(el)}>
						<span className="coin coin--type10">
							{el.img_path && (
								<span className="coin__icon">
									{/* <img
										src={el.img_path.replace(
											'oneify-stage.corp.merehead.xyz',
											'dev1.oneify.cybro.cz',
										)}
										alt={el.code}
									/> */}
									<img src={`/img/currencies/${el.code.toLowerCase()}.svg`} alt={el.code} />
								</span>
							)}
							<span className="coin__text-wrap">
								<span className="coin__text upper__text">
									<span className="coin__text-more">{el.name}</span>
									{el.code.toUpperCase()}
								</span>
							</span>
						</span>
					</button>
				</li>
			))}
	</>
);

const CurrencySelect: FC<ICurrencySelect> = ({
	arr,
	resetCustomSelect,
	setResetCustomSelect,
	onChange,
	activeValue,
	...props
}) => {
	const initActiveCurrency = useMemo(
		() => ({
			id: 0,
			code: '',
			name: '',
			img_path: '',
		}),
		[],
	);

	const [activeCurrency, setActiveCurrency] = useState<ICurrencySelectItem>(initActiveCurrency);
	const [searchCurrency, setSearchCurrency] = useState<ICurrencySelectItem[]>([]);

	useLayoutEffect(() => {
		if (arr) {
			setSearchCurrency(arr);
		}
	}, [arr]);

	useLayoutEffect(() => {
		if (resetCustomSelect) {
			setActiveCurrency(initActiveCurrency);
			!!setResetCustomSelect && setResetCustomSelect(false);
		}
	}, [initActiveCurrency, resetCustomSelect, setResetCustomSelect]);

	useLayoutEffect(() => {
		setActiveCurrency(activeValue || initActiveCurrency);
	}, [activeValue, initActiveCurrency]);

	const onChangeHandler = (el: ICurrencySelectItem) => {
		setActiveCurrency(el);
		!!onChange && onChange(el);
	};
	const customList = {
		list: <CurrencySelectCustomList arr={searchCurrency} onChange={onChangeHandler} />,
		activeElement: activeCurrency.code,
	};

	useLayoutEffect(() => {
		if (arr?.length === 1) {
			onChangeHandler(arr[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arr, activeCurrency]);

	return (
		<Select
			{...props}
			customInput={<CurrencySelectCustomInput {...activeCurrency} />}
			customList={customList}
			searchField
			setSearchCurrency={setSearchCurrency}
			arr={arr}
		/>
	);
};

export default CurrencySelect;
