/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorStore } from './types';

// ==========================================:
export const initialState: IErrorStore = {
	error: [],
	errorMsg: '',
};

// ==========================================:
const error = createSlice({
	name: '@@errors',
	initialState,
	reducers: {
		errors: (state, action: PayloadAction<number | null>) => {
			const errorsStatus = state;
			errorsStatus.error.push(action.payload);
		},
		setErrorMsg: (state, { payload }: PayloadAction<string>) => {
			const errorState = state;
			errorState.errorMsg = payload;
		},
		clearError: () => initialState,
	},
});

export default error.reducer;
export const { errors, setErrorMsg, clearError } = error.actions;
