import React, { FC, useLayoutEffect, useRef, useState } from 'react';

import { DateIcon } from 'assets/inline-svg';
import DatePickerInput from 'ui/DatePicker';
import useClickOutside from 'hooks/useClickOutside';
import moment from 'moment';
import { IFilterDate } from './types';

const FilterDateSelect: FC<IFilterDate> = ({ onChange, reset, title = 'Date' }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleCloseDropdown = () => setIsOpen(false);
	const handleToggleDropdown = () => setIsOpen((state) => !state);
	const [startDay, setStartDay] = useState<Date | null>(null);
	const [endDay, setEndDate] = useState<Date | null>(null);

	const dropdownElement = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdownElement, handleCloseDropdown);

	const handleChooseDate = (startDate: Date, endDate?: Date) => {
		if (startDate && endDate) {
			onChange(startDate, endDate);
			setStartDay(startDate);
			setEndDate(endDate);
			setIsOpen(false);
		}
	};

	useLayoutEffect(() => {
		if (reset) {
			setStartDay(null);
			setEndDate(null);
		}
	}, [reset]);

	return (
		<div
			ref={dropdownElement}
			className={`select select--table select--date ${isOpen ? 'active' : ''}`}
		>
			<button type="button" className="select__current" onClick={handleToggleDropdown}>
				{title}
				{title === 'Date' && startDay && endDay && (
					<p>
						{`${moment(startDay).format('YYYY/MM/DD')}`}
						<span> - </span>
						<span>{moment(endDay).format('YYYY/MM/DD')}</span>
					</p>
				)}
			</button>
			{title === 'Date' && (
				<button type="button" className="select__date-icon" onClick={handleToggleDropdown}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
					>
						<path
							d="M5.99976 8.83301C5.72361 8.83301 5.49976 9.05687 5.49976 9.33301C5.49976 9.60915 5.72361 9.83301 5.99976 9.83301H9.99976C10.2759 9.83301 10.4998 9.60915 10.4998 9.33301C10.4998 9.05687 10.2759 8.83301 9.99976 8.83301H5.99976Z"
							fill="#626A85"
						/>
						<path
							d="M5.99976 11.1663C5.72361 11.1663 5.49976 11.3902 5.49976 11.6663C5.49976 11.9425 5.72361 12.1663 5.99976 12.1663H9.99976C10.2759 12.1663 10.4998 11.9425 10.4998 11.6663C10.4998 11.3902 10.2759 11.1663 9.99976 11.1663H5.99976Z"
							fill="#626A85"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6.49976 1.33301C6.49976 1.05687 6.2759 0.833008 5.99976 0.833008C5.72361 0.833008 5.49976 1.05687 5.49976 1.33301V2.49968H3C2.17157 2.49968 1.5 3.17125 1.5 3.99968V13.9997C1.5 14.8281 2.17157 15.4997 3 15.4997H13C13.8284 15.4997 14.5 14.8281 14.5 13.9997V3.99968C14.5 3.17125 13.8284 2.49968 13 2.49968H10.4998V1.33301C10.4998 1.05687 10.2759 0.833008 9.99976 0.833008C9.72362 0.833008 9.49976 1.05687 9.49976 1.33301V2.49968H6.49976V1.33301ZM5.52821 3.49968C5.59685 3.69388 5.78206 3.83301 5.99976 3.83301C6.21746 3.83301 6.40266 3.69388 6.4713 3.49968H9.52821C9.59685 3.69388 9.78206 3.83301 9.99976 3.83301C10.2175 3.83301 10.4027 3.69388 10.4713 3.49968H13C13.2761 3.49968 13.5 3.72354 13.5 3.99968V6.16634H2.5V3.99968C2.5 3.72354 2.72386 3.49968 3 3.49968H5.52821ZM2.5 7.16634V13.9997C2.5 14.2758 2.72386 14.4997 3 14.4997H13C13.2761 14.4997 13.5 14.2758 13.5 13.9997V7.16634H2.5Z"
							fill="#626A85"
						/>
					</svg>
				</button>
			)}
			<div className="select__drop select__drop--date-picker">
				<DatePickerInput inline selectsRange onChange={handleChooseDate} resetDatePicker={reset} />
			</div>
		</div>
	);
};

export default FilterDateSelect;
