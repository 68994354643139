import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { IUserDataFees, IUserFeesUpdteBody } from 'redux/reducers/users/types';
import { PencilSmallIcon, CheckIcon, CloseIcon } from 'assets/inline-svg';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';
import { numberInputFix } from 'services/utils/numberInputFix';
import { roundingNumber } from 'services/utils/roundingNumber';

export interface IFeeManagementTradeItem {
	el: IUserDataFees;
	permission: boolean;
	updateHandler: (obj: IUserFeesUpdteBody) => void;
}

const FeeManagementTradeItem: FC<IFeeManagementTradeItem> = ({ el, updateHandler, permission }) => {
	const { code, fee } = el;
	const [changeableMode, setChangeableMode] = useState<boolean>(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		trade_fee_percent: fee?.trade_fee_percent || null || '',
		trade_fee_fixed:
			roundingNumber(fee?.trade_fee_fixed, el.type === 'token' ? 'fiat' : code) || null || '',
		trade_slippage: fee?.trade_slippage || null || '',
	};

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_id: fee?.asset_id,
							data: {
								trade_fee_percent: Number(values?.trade_fee_percent),
								trade_fee_fixed: Number(values?.trade_fee_fixed),
								trade_slippage: Number(values?.trade_slippage),
							},
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<div className="td__wrap">
									{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
									<img src={`/img/currencies/${code}.svg`} alt={code} />
									<p>
										{code?.toUpperCase()} <span className="td-more-info d-inline">{el.name}</span>
									</p>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Fee %</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											type="number"
											name="trade_fee_percent"
											onChange={(event) =>
												handleFeeFixedChange(
													event,
													props.setFieldValue,
													'trade_fee_percent',
													'percent',
												)
											}
											onKeyDown={(event) => numberInputFix(event)}
											onWheel={(event) => event.currentTarget.blur()}
											onBlur={props.handleBlur}
											value={props?.values?.trade_fee_percent}
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Fee Fixed</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											name="trade_fee_fixed"
											onChange={(event) =>
												handleFeeFixedChange(
													event,
													props.setFieldValue,
													'trade_fee_fixed',
													el?.type,
												)
											}
											onKeyDown={(event) => numberInputFix(event)}
											onWheel={(event) => event.currentTarget.blur()}
											onBlur={props.handleBlur}
											value={props.values.trade_fee_fixed}
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Slippage %</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											name="trade_slippage"
											onKeyDown={(eventt) => numberInputFix(eventt)}
											onChange={(event) =>
												handleFeeFixedChange(
													event,
													props.setFieldValue,
													'trade_slippage',
													'percent',
												)
											}
											onWheel={(event) => event.currentTarget.blur()}
											onBlur={props.handleBlur}
											value={props.values.trade_slippage}
											type="number"
											max={20}
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
							<img src={`/img/currencies/${code}.svg`} alt={code} />
							<p>
								{code?.toUpperCase()} <span className="td-more-info d-inline">{el.name}</span>
							</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee %</p>
						<div className="td__wrap">
							<p>{fee?.trade_fee_percent}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee Fixed</p>
						<div className="td__wrap">
							<p>{roundingNumber(fee?.trade_fee_fixed, el.type === 'token' ? 'fiat' : code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Slippage %</p>
						<div className="td__wrap">
							<p>{fee?.trade_slippage}</p>
						</div>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								<>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOnChangeableMode}
										className="btn--icon btn--edit"
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default FeeManagementTradeItem;
