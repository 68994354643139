import { FC } from 'react';

import FilterSelect from 'ui/Formik/Select/FilterSelect';
import { INIT_FILTER_OPTIONS, STATUS_FILTER_OPTIONS } from 'redux/reducers/users/constants';
import { IUsersTableHeaderProps } from '../types';

const UsersTableHeader: FC<IUsersTableHeaderProps> = ({ setQuery }) => {
	const handleStatusChange = ({ value }: any) => {
		setQuery({ status: value });
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p> User ID </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Full Name </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Email </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Company Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Total Balance </p>
					</div>
				</div>
				<div className="td">
					<FilterSelect
						title="Status"
						options={STATUS_FILTER_OPTIONS}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleStatusChange}
					/>
				</div>

				<div className="td">
					<div className="td-name" />
				</div>
			</div>
		</div>
	);
};

export default UsersTableHeader;
