/* eslint-disable react/no-unstable-nested-components */

import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import CustomTooltip from './CustomTooltip/CustomTooltip';
import ActiveShape from './ActiveShape/ActiveShape';
import { IBalanceChartProps } from './types';

const PIE_COLORS = ['#5974FF', '#EE9E53', '#EB5757', '#5C6277', '#4a4e82', '#8D99C5', '#FF9A8D'];

const BalanceChart: FC<IBalanceChartProps> = ({ list, activeIndex }) => {
	const isNarrow = useMediaQuery({
		query: `(max-width: 790px)`,
	});

	const chartWidth = isNarrow ? 237 : 237;
	const chartHeight = isNarrow ? 237 : 237;
	return (
		<div className="balance-info-schedule">
			<PieChart width={chartWidth} height={chartHeight}>
				<Pie
					data={list}
					dataKey="valueEuro"
					nameKey="name"
					outerRadius={118}
					startAngle={30}
					endAngle={390}
					stroke="none"
					animationBegin={200}
					animationDuration={700}
					activeShape={(props) => <ActiveShape shapeProps={props} isLegend={!isNarrow} />}
				>
					{list?.map(({ name }, index) => (
						<Cell key={name} fill={PIE_COLORS[index % PIE_COLORS.length]} />
					))}
				</Pie>

				<Tooltip content={<CustomTooltip />} />
			</PieChart>
		</div>
	);
};

export default BalanceChart;
