import React, { FC, MouseEvent, useCallback, useLayoutEffect } from 'react';
import IconSvg from 'ui/Svg/IconSvg';
import { useDispatch, useSelector } from 'react-redux';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { ECoinType, ISortedButton, IWalletsTableHeader } from '../types';
import { sortedTitle } from '../utiils';
import FilterSelect from '../../../../ui/Formik/Select/FilterSelect';
import {
	INIT_FILTER_OPTIONS,
	INIT_FILTER_OPTIONS_BALANCES_CURRENCY,
	INIT_FILTER_OPTIONS_BALANCES_TOTAL,
} from '../../../../redux/reducers/transactionsFilters/constants';
import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from '../../../AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { getCurrencyData } from '../../../../redux/reducers/currency/selectors';
import { getAssetsRequest } from '../../../../redux/reducers/walletBalance/reducer';

const SortedButton: FC<ISortedButton> = ({
	type,
	title,
	sortOrder,
	sortItem,
	setSortOrder,
	setSortItem,
}) => {
	const handleSorted = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			const { name } = e.currentTarget;
			setSortItem(name);
			if (!sortOrder) setSortOrder('asc');
			if (sortOrder === 'asc') setSortOrder('desc');
			if (sortOrder === 'desc') setSortOrder('');
		},
		[setSortItem, setSortOrder, sortOrder],
	);

	useLayoutEffect(() => {
		if (sortItem) {
			setSortOrder('asc');
		}
	}, [setSortOrder, sortItem]);

	return (
		<div className={`td ${type ? 'td--fiat--right' : ''}`}>
			<button
				type="button"
				className="td-sorting-arrow-button"
				name={sortedTitle[title]}
				onClick={handleSorted}
			>
				{title}
				<span className="td-sorting-arrow">
					<span
						className={`td-sorting-arrow__item ${
							sortOrder === 'desc' && sortItem === sortedTitle[title] ? 'active' : ''
						}`}
					>
						<IconSvg name="sort-arrow-up" w="6" h="3" />
					</span>
					<span
						className={`td-sorting-arrow__item ${
							sortOrder === 'asc' && sortItem === sortedTitle[title] ? 'active' : ''
						}`}
					>
						<IconSvg name="sort-arrow-down" w="6" h="3" />
					</span>
				</span>
			</button>
		</div>
	);
};

const WalletTableHeader: FC<IWalletsTableHeader> = ({
	type,
	sortOrder,
	setSortOrder,
	sortItem,
	setSortItem,
}) => {
	const coinTypeHeader = () => {
		let headerNames = ['Currency', 'Total'];
		if (type === ECoinType.CRYPTO) {
			headerNames = ['Cryptocurrency', 'Platform Balance', 'Deposit Wallet'];
		}
		return headerNames;
	};
	const currency = useSelector(getCurrencyData);
	const currencyFilterOptions = currency
		.filter((currencyItem) => currencyItem.type === 'fiat')
		.map(({ id, code, img_path }) => ({
			id,
			name: code.toUpperCase(),
			value: id,
			path: img_path,
			code,
		}));

	const currencyFilterOptionsTotal = currency
		.filter((currencyItem) => currencyItem.type === 'fiat')
		.map(({ id, code, img_path }) => ({
			id,
			name: `Total ${code.toUpperCase()}`,
			value: id,
			path: img_path,
			code,
		}));

	const selectChangeCurrency = ({ value, name }: THistoryFiltersOption) => {
		console.log(value, name);
	};
	const selectChangeCurrencyTotal = ({ value, name }: THistoryFiltersOption) => {
		console.log(value, name);
	};

	return (
		<div className="table-header active-trade menu-filter-balances">
			<div className="tr">
				{coinTypeHeader().map((title, index) => {
					if (title === 'Currency') {
						return (
							<div className="td">
								<FilterSelect
									title="Currency"
									options={
										[
											...INIT_FILTER_OPTIONS_BALANCES_CURRENCY,
											...currencyFilterOptions,
										] as THistoryFiltersTypeOption[]
									}
									selected={INIT_FILTER_OPTIONS[0]}
									onChange={selectChangeCurrency}
									icons
								/>
							</div>
						);
					}

					if (title === 'Total') {
						return (
							<div className="td select--right">
								<FilterSelect
									title="Total EUR"
									options={
										[
											...INIT_FILTER_OPTIONS_BALANCES_TOTAL,
											...currencyFilterOptionsTotal,
										] as THistoryFiltersTypeOption[]
									}
									selected={INIT_FILTER_OPTIONS[0]}
									onChange={selectChangeCurrencyTotal}
									icons
								/>
							</div>
						);
					}
					return (
						<SortedButton
							type={type === ECoinType.FIAT && index === 1 ? type : undefined}
							key={title}
							title={title}
							sortOrder={sortOrder}
							setSortOrder={setSortOrder}
							sortItem={sortItem}
							setSortItem={setSortItem}
						/>
					);
				})}
				{type === ECoinType.CRYPTO && <div className="td td--right" />}
			</div>
		</div>
	);
};

export default WalletTableHeader;
