import React, { FC, useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCryptoTransactions,
	getCryptoTransactionsLoading,
	getCryptoTransfersList,
} from 'redux/reducers/transactions/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import { CSVFileIcon, XLSFileIcon } from 'assets/inline-svg';
import { getCryptoFilters } from 'redux/reducers/transactionsFilters/selectors';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import {
	EApiCryptoTransactionDestination,
	EApiTransactionType,
	IApiGetTransactionParams,
} from 'services/api/transactions/types';
import {
	getCryptoDepositsRequest,
	getCryptoTransactionFileRequest,
	getCryptoTransactionsRequest,
	getTradesTransfersCryptoFileRequest,
} from 'redux/reducers/transactions/reducer';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import cn from 'classnames';
import { getAdminId, getPermissions } from 'redux/reducers/auth/selectors';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import ActiveReportsFilters from 'ui/ActiveFilters/ActiveReportsFilters';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { THistoryFiltersOption } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { STATUS_CRYPTO_FILTER_OPTIONS } from 'redux/reducers/transactionsFilters/constants';
import CryptoTransactionsHeader from './CryptoTransactionsHeader';
import CryptoTransactionsRow from './CryptoTransactionsRow';
import { ITransactionsTab, ITransactionsTableSortedParams } from '../types';
import Loading from '../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
];

const CryptoTransactionsWithdrawalTable: FC<ITransactionsTab> = ({ type }) => {
	const dispatch = useDispatch();
	const adminId = useSelector(getAdminId);
	const activeFilters = useSelector(getCryptoFilters);
	const cryptoTransactions = useSelector(getCryptoTransactions);
	const cryptoTransfers = useSelector(getCryptoTransfersList);
	const cryptoTransactionsLoading = useSelector(getCryptoTransactionsLoading);
	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;

	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	const [currentPage, setCurrentPage] = useState(cryptoTransactions?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const [columnValue, setColumnValue] = useState<string | undefined>(
		EApiCryptoTransactionDestination.DESTINATION,
	);
	const [reset, setReset] = useState(false);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || cryptoTransactions?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = cryptoTransactions?.last_page ? cryptoTransactions.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cryptoTransactions?.last_page]);

	useLayoutEffect(() => {
		setSorted(null);
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	useLayoutEffect(() => {
		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			type: type === EApiTransactionType.TRANSFER ? undefined : type,
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
			...sortedParamsObj,
		};

		dispatch(getCryptoTransactionsRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorted, activeFilters, currentPage, perPage]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.TRANSACTIONS_CRYPTO }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		setReset(true);
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				}),
			);
		}
	};
	const handleGetCsvFile = () => {
		dispatch(getCryptoTransactionFileRequest({ type, fileType: 'csv', adminId }));
	};
	const handleGetXlsxFile = () => {
		dispatch(getCryptoTransactionFileRequest({ type, fileType: 'xlsx', adminId }));
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);

		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				value: { date_from: startDate, date_to: endDate },
			}),
		);
	};

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);

		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.ASSET_ID,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const cryptoCurrencyFilterOptions = cryptoCurrencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.STATUS,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};

	const handleChangeColumn = ({ name, value }: THistoryFiltersOption) => {
		setColumnValue(value);
	};
	return (
		<>
			{/* <SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
				/>
				<button type="button" className="filters__download-button" onClick={handleGetCsvFile}>
					<CSVFileIcon />
					Download
				</button>
				<button type="button" className="filters__download-button" onClick={handleGetXlsxFile}>
					<XLSFileIcon />
					Download
				</button> */}

			<ActiveReportsFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={cryptoCurrencyFilterOptions}
				statusOptions={STATUS_CRYPTO_FILTER_OPTIONS}
				handleStatusChange={handleStatusChange}
				searchArray={FiltersSearchArray}
				resetValue={resetSearchValue}
				handleSearch={handleSearch}
				reset={reset}
			/>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div
						className={cn('table table--type2', {
							'table--transaction-crypto': type === EApiTransactionType.DEPOSIT,
							'table--transaction-crypto-withdrawal': type === EApiTransactionType.WITHDRAWAL,
							'table--transaction-crypto-transfer': type === EApiTransactionType.TRANSFER,
						})}
					>
						<div className="table-header">
							<CryptoTransactionsHeader
								type={type}
								setSorted={setSorted}
								onColumnChange={handleChangeColumn}
							/>
						</div>
						<div
							className={`table-body
								${cryptoTransactions?.data.length === 4 ? 'table-body--four-elements' : ''}
								${cryptoTransactions?.data.length === 3 ? 'table-body--three-elements' : ''}
								${cryptoTransactions?.data.length === 2 ? 'table-body--two-elements' : ''}
								${cryptoTransactions?.data.length === 1 ? 'table-body--one-elements' : ''}
							`}
						>
							{!cryptoTransactionsLoading && (
								<>
									{' '}
									{cryptoTransactions?.data?.length ? (
										cryptoTransactions?.data.map((item: any) => (
											<CryptoTransactionsRow
												isAllTypes={type === EApiTransactionType.TRANSFER}
												key={item.id}
												permission={!!permission}
												{...item}
												column={columnValue}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{cryptoTransactionsLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!cryptoTransactions?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!cryptoTransactionsLoading && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
							<div className="button-wrap">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--csv" onClick={handleGetCsvFile} />
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--xlsx" onClick={handleGetXlsxFile} />
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default CryptoTransactionsWithdrawalTable;
