import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { navListAfterLogin } from 'routes/routesList';
import { toggleMinimized } from 'redux/reducers/sidebarStates/reducer';
import Footer from 'layouts-elements/Footer';
import {
	getMinimizedSidebarState,
	getMobileSidebarState,
} from 'redux/reducers/sidebarStates/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { useHistory } from 'react-router';
import Logo from '../../assets/img/sidebar_logo_gray.svg';
import MinimizedLogo from '../../assets/img/sidebar_minimized_logo.svg';
import MobileLogo from '../../assets/img/sidebar_mobile_logo.svg';

import { SidebarArrow } from '../../assets/inline-svg';
import { logoutRequest } from '../../redux/reducers/auth/reducer';

interface IMenuLink {
	path: string;
	text?: string;
}

const SideBar = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const mobileSidebar1 = useSelector(getMobileSidebarState);
	const minimizedSidebar = useSelector(getMinimizedSidebarState);
	const sidebar1Ref = useRef<HTMLDivElement | null>(null);
	const handleToggleMinimized = () => dispatch(toggleMinimized());
	const permissions = useSelector(getPermissions);
	const [showHiddenMenu, setShowHiddenMenu] = useState(false);

	const isUserManagementPathname = (pathname: string): boolean =>
		pathname === '/user-management' || pathname === '/2FA';
	const isUserManagementPath = (pathname: string): boolean =>
		String(pathname) === 'user-management' || String(pathname) === 'createUser';
	const location = useLocation();
	const [open, setOpen] = useState<boolean>(isUserManagementPathname(location.pathname));

	const [active, setActive] = useState<boolean>(
		isUserManagementPath(location.pathname.split('/')[1]),
	);
	useLayoutEffect(() => {
		if (isUserManagementPathname(location.pathname)) {
			setOpen(true);
		}

		if (isUserManagementPath(location.pathname.split('/')[1])) {
			setActive(true);
		} else {
			setActive(false);
		}
	}, [location.pathname]);

	const [checkedRequests, setCheckedRequests] = useState(false);
	const resetRequests: IMenuLink[] = [
		{ path: navListAfterLogin.resetRequestUser2fa.path, text: 'Pending Deposits' },
		{ path: navListAfterLogin.resetRequestUserPassword.path, text: 'Pending Withdrawals' },
		{ path: navListAfterLogin.resetRequestAdmin2fa.path, text: 'Pending Reset Requests' },
		{ path: navListAfterLogin.resetRequestAdminPassword.path, text: 'Pending Reset Requests2' },
	];
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkIfResetRequestActive = (match: any, location: any) => {
		return resetRequests.some((request) => location.pathname.includes(request.path));
	};
	/// //////////
	const [checkedReports, setCheckedReports] = useState(false);
	const reports: IMenuLink[] = [
		{ path: navListAfterLogin.transfersAndTradeReportsTradesCrypto.path, text: 'Trades' },
		{ path: navListAfterLogin.transfersAndTradeReportsTransfersCrypto.path, text: 'Transfers' },
		{ path: navListAfterLogin.transfersAndTradeReportsDepositCrypto.path, text: 'Deposits' },
		{ path: navListAfterLogin.transfersAndTradeReportsWithdrawalsCrypto.path, text: 'Withdrawals' },
		{ path: navListAfterLogin.transfersAndTradeReportsTradesLimits.path },
		{ path: navListAfterLogin.transfersAndTradeReportsTransfersLimits.path },
		{ path: navListAfterLogin.transfersAndTradeReportsPendingWithdrawal.path },
	];

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkIfReportActive = (match: any, location: any) => {
		return reports.some((request) => location.pathname.includes(request.path));
	};
	/// ///
	const [checkedFee, setCheckedFee] = useState(false);
	const fee: IMenuLink[] = [
		{ path: navListAfterLogin.feeManagementWithdrawFee.path, text: 'Withdraw Fee' },
		{
			path: navListAfterLogin.feeManagementDepositFee.path,
			text: 'Deposit Fee',
		},
		{ path: navListAfterLogin.feeManagementTradeFee.path, text: 'Trade Fee' },
		{
			path: navListAfterLogin.feeManagementSpreadFee.path,
			text: 'Spread Fee.',
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkIFeeActive = (match: any, location: any) => {
		return fee.some((request) => location.pathname.includes(request.path));
	};
	/// ///

	const getNavLinkClass = (path: string[]) => {
		if (path.indexOf(location.pathname) !== -1) {
			return 'sidebar1-nav-item__link active';
		}
		return 'sidebar1-nav-item__link';
	};

	const closeAllMenus = () => {
		document
			.querySelectorAll('.sidebar1-nav-item__link')
			.forEach((el) => el.classList.remove('is-opened'));
	};

	const submenuParentClickHandler = (event: any) => {
		const parentListItem = event.target.closest('.sidebar1-nav-item__list-item');
		let submenu = null;
		if (parentListItem) {
			submenu = parentListItem.querySelector('.sidebar1-nav-item__submenu');
		}
		const target = event.target.closest('.sidebar1-nav-item__link');
		const menuItemPos = target.getBoundingClientRect();

		if (submenu) {
			submenu.style.top = `${Number(menuItemPos.top) + Number(menuItemPos.height)}px`;
			submenu.style.left = `${Number(menuItemPos.left)}px`;
		}

		if (target.classList.contains('is-opened')) {
			target.classList.remove('is-opened');
		} else {
			target.classList.add('is-opened');
		}
		if (minimizedSidebar) {
			handleToggleMinimized();
		}
	};

	useEffect(() => {
		document.addEventListener('click', (event: any) => {
			const subMenuArr = Array.from(
				document.querySelectorAll('.sidebar1-nav-item__list-item--has-children'),
			);
			const insideSubMenu = subMenuArr.some((el) => el.contains(event.target as Node));
			if (!insideSubMenu) {
				closeAllMenus();
			}

			const hiddenMenuArr = Array.from(document.querySelectorAll('.sidebar1__right'));
			const insideHiddenMenuArr = hiddenMenuArr.some((el) => el.contains(event.target as Node));
			if (!insideHiddenMenuArr) {
				setShowHiddenMenu(false);
			}
		});
	}, []);

	useEffect(() => {
		let observerActive = true;
		if (showHiddenMenu) {
			const cloneContainer = document.getElementById('hidden-menu');
			const observedElements = document.querySelectorAll('.sidebar1-nav-item__list-item');
			const observer = new IntersectionObserver(
				(entries) => {
					if (observerActive) {
						if (cloneContainer) cloneContainer.innerHTML = ''; // Clear container before adding new clones

						entries.forEach((entry) => {
							if (entry.intersectionRatio < 1) {
								// Element is not fully in the viewport, clone it
								const clonedElement = entry.target.cloneNode(true);

								// Append the cloned element to the clone container
								if (cloneContainer) cloneContainer.appendChild(clonedElement);
							}
						});
					}
				},
				{
					threshold: 1.0, // 1.0 means the element must be fully visible
				},
			);
			observedElements.forEach((element) => {
				observer.observe(element);
			});

			setTimeout(() => {
				const hiddenLinksParents = document.querySelectorAll(
					'.sidebar1-nav__hidden-menu .sidebar1-nav-item__list-item--has-children',
				);
				if (hiddenLinksParents) {
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < hiddenLinksParents.length; i++) {
						hiddenLinksParents[i].addEventListener('click', (event: any) => {
							const clickTarget = event.target;
							let target;
							if (clickTarget) target = clickTarget.closest('.sidebar1-nav-item__link');

							if (target.classList.contains('is-opened')) {
								closeAllMenus();
							} else {
								closeAllMenus();
								target.classList.add('is-opened');
							}
						});
						// console.log(hiddenLinksParents[i]);
					}
				}

				const hiddenLinks = document.querySelectorAll(
					'.sidebar1-nav__hidden-menu .sidebar1-nav__link',
				);
				if (hiddenLinks) {
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < hiddenLinks.length; i++) {
						hiddenLinks[i].addEventListener('click', (event: any) => {
							event.preventDefault();
							const href = event.target.parentElement.getAttribute('href');
							if (href) {
								history.push(href);
								closeAllMenus();
								setShowHiddenMenu(false);
							}
						});
					}
				}

				const hiddenSubmenuLinks = document.querySelectorAll(
					'.sidebar1-nav__hidden-menu .sidebar1-nav-item__submenu .sidebar1-nav-item__link',
				);
				if (hiddenSubmenuLinks) {
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < hiddenSubmenuLinks.length; i++) {
						hiddenSubmenuLinks[i].addEventListener('click', (event: any) => {
							event.preventDefault();
							const href = event.target.getAttribute('href');
							const data = event.target.dataset.type;
							if (data && data === 'logout') {
								dispatch(logoutRequest({ history }));
								closeAllMenus();
								setShowHiddenMenu(false);
							}
							if (href) {
								history.push(href);
								closeAllMenus();
								setShowHiddenMenu(false);
							}
						});
					}
				}
			}, 100);
		}
		window.addEventListener('scroll', () => {
			if (window.scrollY > 5) {
				observerActive = false;
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showHiddenMenu]);

	const hideMobMenuHandler = () => {
		const mainNav = document.getElementById('main_nav');
		const links = document.querySelectorAll('.sidebar1 .sidebar1-nav-item__list ul li a');
		links.forEach((e: any) => {
			e.addEventListener('click', () => {
				if (mainNav) mainNav.style.display = 'none';
			});
		});
	};

	useEffect(() => {
		let windowWidth = window.outerWidth;
		if (windowWidth < 1180) {
			hideMobMenuHandler();
		}
		window.addEventListener('resize', () => {
			windowWidth = window.outerWidth;
			if (windowWidth < 1180) {
				hideMobMenuHandler();
			}
		});
	}, []);

	// const el = document.getElementById('main_nav');
	// if (el) el.style.display = 'flex';

	return (
		<div
			ref={sidebar1Ref}
			className={minimizedSidebar ? 'sidebar1 sidebar1--minimized' : 'sidebar1'}
		>
			<button
				type="button"
				className={minimizedSidebar ? 'sidebar-btn minimized' : 'sidebar-btn'}
				onClick={handleToggleMinimized}
			>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.42667 8.32002C5.21333 8.16002 5.21333 7.84002 5.42667 7.68002L8.36 5.48002C8.62369 5.28225 9 5.4704 9 5.80002L9 10.2C9 10.5296 8.62369 10.7178 8.36 10.52L5.42667 8.32002Z"
						fill="#626A85"
					/>
				</svg>
			</button>

			<div className="sidebar1__left">
				<a href="/" className="sidebar1-nav__logo">
					<img src={minimizedSidebar ? MinimizedLogo : Logo} alt="" />
				</a>
				<a href="/" className="sidebar1-nav__mobile-logo">
					<img src={MobileLogo} alt="" />
				</a>
				<div className="sidebar1-nav-item" id="main_nav">
					<div className="sidebar1-nav-item__header">
						<a href="/" className="sidebar1-nav__mobile-logo">
							<img src={MobileLogo} alt="" />
						</a>
						<div
							className="sidebar1-nav-item__close"
							onClick={() => {
								const el = document.getElementById('main_nav');
								if (el) el.style.display = 'none';
							}}
						>
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#8D99C5" />
								<rect
									x="12.1113"
									y="11.4"
									width="12"
									height="1"
									rx="0.5"
									transform="rotate(45 12.1113 11.4)"
									fill="#626A85"
								/>
								<rect
									x="20.5967"
									y="12.1071"
									width="12"
									height="1"
									rx="0.5"
									transform="rotate(135 20.5967 12.1071)"
									fill="#626A85"
								/>
							</svg>
						</div>
					</div>

					<nav className="sidebar1-nav-item__list">
						<ul>
							{!!permissions?.[EPermissionNames.DASHBOARD]?.only_read && (
								<li className="sidebar1-nav-item__list-item">
									<NavLink className="sidebar1-nav__link" to={navListAfterLogin.home.path}>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--dashboard" />
										<div className="sidebar1-nav__link-text">Dashboard</div>
									</NavLink>
								</li>
							)}

							{permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read ||
							permissions?.[EPermissionNames.RESET_REQUEST]?.only_read ? (
								<>
									<li className="sidebar1-nav-item__list-item sidebar1-nav-item__list-item--has-children">
										<div
											className={getNavLinkClass([
												'/reset-request/users/twofa',
												'/reset-request/users/password',
												'/reset-request/admin/twofa',
												'/reset-request/admin/password',
												'/transfers-and-trade-reports/pending-withdrawal/crypto',
												'/transfers-and-trade-reports/pending-deposits/crypto',
												'/transfers-and-trade-reports/pending-withdrawal/fiat',
												'/transfers-and-trade-reports/pending-deposits/fiat',
											])}
											onClick={submenuParentClickHandler}
										>
											<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--pending-approval" />
											<div className="sidebar1-nav__link-text">Pending Approval</div>
										</div>
										<div className="sidebar1-nav-item__submenu">
											<ul>
												{!!permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read && (
													<>
														{/* <li>
                              <span className="sidebar1-nav__link">Pending Deposits</span>
                            </li> */}
														<li>
															<NavLink
																to="/transfers-and-trade-reports/pending-deposits/crypto"
																className={getNavLinkClass([
																	'/transfers-and-trade-reports/pending-deposits/crypto',
																	'/transfers-and-trade-reports/pending-deposits/fiat',
																])}
																activeClassName=""
															>
																Pending Deposits
															</NavLink>
														</li>
														<li>
															<NavLink
																to="/transfers-and-trade-reports/pending-withdrawal/crypto"
																className={getNavLinkClass([
																	'/transfers-and-trade-reports/pending-withdrawal/crypto',
																	'/transfers-and-trade-reports/pending-withdrawal/fiat',
																])}
																activeClassName=""
															>
																Pending Withdrawals
															</NavLink>
														</li>
													</>
												)}
												{!!permissions?.[EPermissionNames.RESET_REQUEST]?.only_read && (
													<>
														<li>
															<NavLink
																to="/reset-request/users/twofa"
																className={getNavLinkClass([
																	'/reset-request/admin/twofa',
																	'/reset-request/admin/password',
																	'/reset-request/users/twofa',
																	'/reset-request/users/password',
																])}
																activeClassName=""
															>
																Pending Reset Requests
															</NavLink>
														</li>
													</>
												)}
												{/* <li>
                        <NavLink to="/reset-request/admin/password">
                          Pending Reset Requests2
                        </NavLink>
                      </li> */}
											</ul>
										</div>
									</li>
								</>
							) : null}

							{!!permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read && (
								<>
									<li className="sidebar1-nav-item__list-item sidebar1-nav-item__list-item--has-children">
										<div
											className={getNavLinkClass([
												'/transfers-and-trade-reports/trades',
												'/transfers-and-trade-reports/ledger-aggregated',
												'/transfers-and-trade-reports/transfers/crypto',
												'/transfers-and-trade-reports/deposit/crypto',
												'/transfers-and-trade-reports/withdrawals/crypto',
											])}
											onClick={submenuParentClickHandler}
										>
											<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--reports" />
											<div className="sidebar1-nav__link-text">Reports</div>
										</div>
										<div className="sidebar1-nav-item__submenu">
											<ul>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/trades"
														className={getNavLinkClass(['/transfers-and-trade-reports/trades'])}
														activeClassName=""
													>
														Trades
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/transfers/crypto"
														className={getNavLinkClass([
															'/transfers-and-trade-reports/transfers/crypto',
														])}
														activeClassName=""
													>
														Transfers
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/deposit/crypto"
														className={getNavLinkClass([
															'/transfers-and-trade-reports/deposit/crypto',
														])}
														activeClassName=""
													>
														Deposits
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/withdrawals/crypto"
														className={getNavLinkClass([
															'/transfers-and-trade-reports/withdrawals/crypto',
														])}
														activeClassName=""
													>
														Withdrawals
													</NavLink>
												</li>
												<li>
													<NavLink
														to="/transfers-and-trade-reports/ledger-aggregated"
														className={getNavLinkClass([
															'/transfers-and-trade-reports/ledger-aggregated',
														])}
														activeClassName=""
													>
														Ledger aggregated
													</NavLink>
												</li>
											</ul>
										</div>
									</li>
								</>
							)}

							{permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read ||
							permissions?.[EPermissionNames.FEE_MANAGEMENT]?.only_read ||
							permissions?.[EPermissionNames.REQUIRED_EMAILS]?.only_read ? (
								<li className="sidebar1-nav-item__list-item sidebar1-nav-item__list-item--has-children">
									<div
										className={getNavLinkClass([
											'/fee-management/withdraw-fee',
											'/fee-management/deposit-fee',
											'/fee-management/trade-fee',
											'/fee-management/spread-management-fee',
											'/fee-management/currencles-fee',
											'/required-emails',
											'/transfers-and-trade-reports/trades-limits/crypto',
											'/transfers-and-trade-reports/trades-limits/fiat',
											'/transfers-and-trade-reports/transfer-limits/crypto',
											'/transfers-and-trade-reports/transfer-limits/fiat',
										])}
										onClick={submenuParentClickHandler}
									>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--global-settings" />
										<div className="sidebar1-nav__link-text">Global Settings</div>
									</div>
									<div className="sidebar1-nav-item__submenu">
										<ul>
											{!!permissions?.[EPermissionNames.TRANSFER_TRADE_REPORT]?.only_read && (
												<>
													<li>
														<NavLink
															to="/transfers-and-trade-reports/trades-limits/crypto"
															className={getNavLinkClass([
																'/transfers-and-trade-reports/trades-limits/crypto',
																'/transfers-and-trade-reports/trades-limits/fiat',
															])}
															activeClassName=""
														>
															Trade Limits
														</NavLink>
													</li>
													<li>
														<NavLink
															to="/transfers-and-trade-reports/transfer-limits/crypto"
															className={getNavLinkClass([
																'/transfers-and-trade-reports/transfer-limits/crypto',
																'/transfers-and-trade-reports/transfer-limits/fiat',
															])}
															activeClassName=""
														>
															Transfer Limits
														</NavLink>
													</li>
												</>
											)}

											{!!permissions?.[EPermissionNames.FEE_MANAGEMENT]?.only_read && (
												<>
													<li>
														<NavLink
															to="/fee-management/withdraw-fee"
															className={getNavLinkClass([
																'/fee-management/withdraw-fee',
																'/fee-management/deposit-fee',
																'/fee-management/trade-fee',
																'/fee-management/spread-management-fee',
																'/fee-management/currencles-fee',
															])}
															activeClassName=""
														>
															Default Fees
														</NavLink>
													</li>
												</>
											)}

											{!!permissions?.[EPermissionNames.REQUIRED_EMAILS]?.only_read && (
												<li>
													<NavLink
														to="/required-emails"
														className={getNavLinkClass(['/required-emails'])}
														activeClassName=""
													>
														Default Emails
													</NavLink>
												</li>
											)}
										</ul>
									</div>
								</li>
							) : null}

							{!!permissions?.[EPermissionNames.USER_MANAGEMENT]?.only_read && (
								<li className="sidebar1-nav-item__list-item">
									<NavLink
										className="sidebar1-nav__link"
										to={navListAfterLogin.userManagement.path}
									>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--user-management" />
										<div className="sidebar1-nav__link-text">User Management</div>
									</NavLink>
								</li>
							)}

							{!!permissions?.[EPermissionNames.ADMIN_MANAGEMENT]?.only_read && (
								<li className="sidebar1-nav-item__list-item">
									<NavLink
										className="sidebar1-nav__link"
										to={navListAfterLogin.adminManagementPage.path}
									>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--admin-management" />
										<div className="sidebar1-nav__link-text">Admin Management</div>
									</NavLink>
								</li>
							)}

							{!!permissions?.[EPermissionNames.DEPOSIT_ACCOUNT]?.only_read && (
								<li className="sidebar1-nav-item__list-item">
									<NavLink
										className="sidebar1-nav__link"
										to={navListAfterLogin.depositAccounts.path}
									>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--deposit-accounts" />
										<div className="sidebar1-nav__link-text">Deposit Accounts</div>
									</NavLink>
								</li>
							)}

							{!!permissions?.[EPermissionNames.WALLET_MANAGEMENT]?.only_read && (
								<li className="sidebar1-nav-item__list-item">
									<NavLink
										className="sidebar1-nav__link"
										to={navListAfterLogin.walletBalances.path}
									>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--liquidity-management" />
										<div className="sidebar1-nav__link-text">Liquidity Management</div>
									</NavLink>
								</li>
							)}

							{!!permissions?.[EPermissionNames.REFERRAL_MANAGEMENT]?.only_read && (
								<li className="sidebar1-nav-item__list-item">
									<NavLink
										className="sidebar1-nav__link"
										to={navListAfterLogin.referralSystem.path}
									>
										<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--referral-management" />
										<div className="sidebar1-nav__link-text">Referral Management</div>
									</NavLink>
								</li>
							)}

							<li className="sidebar1-nav-item__list-item sidebar1-nav-item__list-item--has-children">
								<div className="sidebar1-nav-item__link" onClick={submenuParentClickHandler}>
									<div className="sidebar1-nav-item__icon sidebar1-nav-item__icon--account-settings" />
									<div className="sidebar1-nav__link-text">Account Settings</div>
								</div>
								<div className="sidebar1-nav-item__submenu">
									<ul>
										<li>
											<button
												className="sidebar1-nav-item__link"
												type="button"
												data-type="logout"
												onClick={() => {
													dispatch(logoutRequest({ history }));
												}}
											>
												Log Out
											</button>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</nav>
				</div>
				{!minimizedSidebar && (
					<div className="footer-container">
						<div className="footer__support">
							<a href="mailto:support@kaiserex.com">support@kaiserex.com</a>
						</div>
						<div className="footer-nav">
							<ul>
								<li>
									<a href="/terms" target="_blank">
										Service Agreement
									</a>
								</li>
								<li>
									<a href="/privacy" target="_blank">
										Privacy Policy
									</a>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
			<div className="sidebar1__right">
				<div
					className="burger"
					onClick={() => {
						const el = document.getElementById('main_nav');
						if (el) el.style.display = 'flex';
						// const el = Array.from(document.querySelectorAll('.sidebar-nav-item'));
						// el.forEach((element) => {
						//  if (!(element instanceof HTMLElement)) {
						//    throw new TypeError(`Expected an object of Type HTMLElement`);
						//  }
						//  // eslint-disable-next-line no-param-reassign
						//  element.style.display = 'flex';
						// });
					}}
				/>
			</div>
		</div>
	);
};

export default SideBar;
