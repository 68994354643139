import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { getReferralFilters, getTradesFilters } from 'redux/reducers/transactionsFilters/selectors';
import { getTrades, getTradesLoading } from 'redux/reducers/transactions/selectors';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import { getTradesFileRequest, getTradesRequest } from 'redux/reducers/transactions/reducer';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import { CSVFileIcon, XLSFileIcon } from 'assets/inline-svg';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import IconSvg from 'ui/Svg/IconSvg';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import ReferralsTradeTableRow from 'components/tables/ReferralsTradeTable/ReferralsTradeTableRow/ReferralsTradeTableRow';
import ReferralsTradeTableHeader from 'components/tables/ReferralsTradeTable/ReferralsTradeTableHeader/ReferralsTradeTableHeader';
import { NavLink } from 'react-router-dom';
import { getAdminId } from '../../../redux/reducers/auth/selectors';
import { ROUTES } from '../../../routes/constants';
import ReferralsTableRow from '../../tables/ReferralsTable/ReferralsTableRow';
import Loading from '../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
];

const TradesTab = () => {
	const dispatch = useDispatch();
	const activeFilters = useSelector(getTradesFilters);
	const trades = useSelector(getTrades);
	const tradesLoading = useSelector(getTradesLoading);
	const adminId = useSelector(getAdminId);
	const [currentPage, setCurrentPage] = useState(trades?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || trades?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = trades?.last_page ? trades.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trades?.last_page]);

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		dispatch(getTradesRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, currentPage, perPage]);

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useLayoutEffect(() => {
	// 	console.log(trades);
	// }, [trades]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.TRANSACTIONS_TRADES }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};
	// const filterData = useSelector(getReferralFilters);
	// useLayoutEffect(() => {
	// 	const filterParams = convertFilterObjectToParamsObject(activeFilters);
	// 	const params: any = {
	// 		per_page: perPage,
	// 		current_page: currentPage,
	// 		...filterParams,
	// 		date_from: filterData[0]?.value.from,
	// 		date_to: filterData[0]?.value.to,
	// 	};
	//
	// 	// console.log(filterData[0]?.value.date_from);
	// 	dispatch(getTradesRequest(params));
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [filterData]);
	const handleDownloadCsvFile = () => {
		dispatch(getTradesFileRequest({ fileType: 'csv', adminId }));
	};

	const handleDownloadXlsxFile = () => {
		dispatch(getTradesFileRequest({ fileType: 'xlsx', adminId }));
	};
	return (
		<>
			<div className="currencyButtonFile mb-20">
				<div className="currencyButton">
					<NavLink to={ROUTES.referralSystem.referrals}>Referrals</NavLink>
					<NavLink to={ROUTES.referralSystem.trades}>Trades</NavLink>
				</div>
			</div>

			<div className="table-filter flex-m mt-0 mb-0">
				<SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
					handleReset={handleResetFilters}
					isResetable
				/>
			</div>
			{/* <ActiveFilters
				activeFilters={activeFilters}
				handleDeleteFilter={handleDeleteFilter}
				handleResetFilters={handleResetFilters}
			/> */}
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--referral-trades">
						<div className="table-header">
							<ReferralsTradeTableHeader />
						</div>
						<div className="table-body">
							{!tradesLoading && (
								<>
									{' '}
									{trades?.data?.length ? (
										<>
											{trades?.data.map((item: any) => {
												return <ReferralsTradeTableRow key={item.id} {...item} />;
											})}
										</>
									) : (
										<TableBodyNoData />
									)}
								</>
							)}

							{tradesLoading ? <Loading /> : null}
						</div>
						{!!trades?.data.length && (
							<div className="table-footer">
								<div className="pagination-block">
									<PerPageSelect onChange={handleSetPerPage} />
									<ReactPaginate
										breakLabel="..."
										pageCount={pageCount}
										onPageChange={handlePageClick}
										forcePage={currentPage - 1}
										className="pagination"
										activeClassName="active"
										previousClassName="pagination__arrow pagination__arrow--prev"
										nextClassName="pagination__arrow pagination__arrow--next"
										previousLabel=""
										nextLabel=""
									/>
									<div className="fileButton">
										{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
										<button
											type="button"
											className="btn btn--csv"
											onClick={handleDownloadCsvFile}
										/>
										{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
										<button
											type="button"
											className="btn btn--xlsx"
											onClick={handleDownloadXlsxFile}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!trades?.data.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{!tradesLoading && pageCount > 1 && (
						<ReactPaginate
							breakLabel="..."
							pageCount={pageCount}
							onPageChange={handlePageClick}
							forcePage={currentPage - 1}
							className="pagination"
							activeClassName="active"
							previousClassName="arrow"
							nextClassName="arrow"
							previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
							nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
						/>
					)}
				</div>
			)} */}
		</>
	);
};

export default TradesTab;
