import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import {
	getDepositAccounts,
	getDepositAccountsLoading,
} from 'redux/reducers/walletBalance/selectors';
import {
	deleteDepositAccountRequest,
	getDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { ROUTES } from 'routes/constants';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import CreateBankAccount from 'components/tables/CreateBankAccount';
import Pagination from 'ui/Pagination/Pagination';
import { getFeeRequest } from 'redux/reducers/fee/reducer';
import DepositAccountCryptoItem from './DepositAccountCryptoItem/DepositAccountCryptoItem';
import TradeHistoryRow from '../../../AccountDetails/TransactionHistoryTab/TradeHistory/TradeHistoryRow';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const DepositAccountCrypto = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const depositAccounts = useSelector(getDepositAccounts);
	const loading = useSelector(getDepositAccountsLoading);
	const [bankAccountFormOpen, setBankAccountFormOpen] = useState<boolean>(false);

	const [bankAccountId, setBankAccountId] = useState<number | null>(null);
	const permissions = useSelector(getPermissions);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || depositAccounts?.total);
		setCurrentPage(1);
	};

	const pageCount = depositAccounts?.last_page || 1;

	const onDelete = (id: number, nickname: string) => {
		dispatch(deleteDepositAccountRequest({ admin_requisite_id: id, nickname }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Bank Account?',
				message: 'Are you sure you want to delete the account?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
		dispatch(popUpOpen('warningPopUp'));
		setCurrentPage(1);
	};

	const updateHandler = (id: number) => {
		setBankAccountId(id);
		setBankAccountFormOpen(true);
	};

	const handleCreateAccount = () => {
		setBankAccountFormOpen(true);
	};

	const closeBankAccountForm = () => {
		setBankAccountId(null);
		setBankAccountFormOpen(false);
	};

	useLayoutEffect(() => {
		dispatch(getDepositAccountRequest({ current_page: currentPage, per_page: perPage }));
	}, [dispatch, currentPage, perPage]);

	useLayoutEffect(() => {
		const data = {
			per_page: 100,
			current_page: 1,
			type: 'fiat',
		};
		dispatch(getFeeRequest(data));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!bankAccountFormOpen && (
				<>
					<div className="table-wrap">
						<div className="table-title">
							<p>Bank Accounts</p>
						</div>
						<div className="title-block-button button--flex-end">
							{!!permissions?.[EPermissionNames.DEPOSIT_ACCOUNT].editable && (
								<button onClick={handleCreateAccount} type="button" className="btn btn-primary">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="21"
										height="20"
										viewBox="0 0 21 20"
										fill="none"
									>
										<path
											d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
											fill="white"
										/>
									</svg>
									Add Bank Account
								</button>
							)}
						</div>
					</div>
					<div className="table-block">
						<div className="table-wrapper">
							<div className="table table--payment-deposit-accounts">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>Beneficiary Name / Address</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Bank Name / Address</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Currency</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>SWIFT/BIC</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Account Number</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Note</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Action</p>
											</div>
										</div>
									</div>
								</div>
								<div className="table-body">
									{!loading && (
										<>
											{' '}
											{depositAccounts?.data?.length ? (
												depositAccounts?.data?.map((el: any) => (
													<DepositAccountCryptoItem
														key={el.id}
														deleteHandler={deleteHandler}
														updateHandler={updateHandler}
														permission={!!permissions?.[EPermissionNames.DEPOSIT_ACCOUNT].editable}
														{...el}
													/>
												))
											) : (
												<TableBodyNoData />
											)}
										</>
									)}
									{loading ? <Loading /> : null}
								</div>

								<div className="table-pagination">
									<div className="select-page">
										<PerPageSelect onChange={handleSetPerPage} />
									</div>
									{pageCount > 1 && (
										<div className="pagination-block">
											<Pagination
												pageCount={pageCount}
												currentPage={currentPage}
												onPageChange={setCurrentPage}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{bankAccountFormOpen && (
				<CreateBankAccount
					closeForm={closeBankAccountForm}
					id={bankAccountId}
					setPage={setCurrentPage}
				/>
			)}
		</>
	);
};

export default DepositAccountCrypto;
