/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { roundingNumber } from 'services/utils/roundingNumber';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	EApiCryptoTransactionDestination,
	EApiCryptoTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiTransactionType,
	IApiCryptoTransaction,
} from 'services/api/transactions/types';
import {
	putCryptoNoteRequest,
	putCryptoWithdrawalApproveRequest,
	putCryptoWithdrawalRejectRequest,
} from 'redux/reducers/transactions/reducer';
import { shortNotation } from 'services/utils/strings';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import IconSvg from 'ui/Svg/IconSvg';

const INIT_VALUE_TO_EDIT = {
	id: 0,
	value: EApiCryptoTransactionStatus.ADMIN_PENDING,
	name: 'Pending',
};
const STATUS_TO_EDIT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiCryptoTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiCryptoTransactionStatus.FAILED,
	},
];

export interface ICryptoTransactionsRow extends IApiCryptoTransaction {
	isAllTypes?: boolean;
	permission: boolean;
	column?: string;
}

const CryptoTransactionsRow: FC<ICryptoTransactionsRow> = ({
	id,
	type,
	status,
	created_at,
	asset,
	chain,
	fee,
	tx_hash,
	destination,
	isAllTypes,
	permission,
	note,
	gross_fee,
	net_fee,
	user,
	column,
}) => {
	const dispatch = useDispatch();
	const [date, time] = useFormatDateTime(created_at);

	const filterStatusArrayToEdit = (currentStatus: EApiCryptoTransactionStatus | string) => {
		return STATUS_TO_EDIT.filter((el) => el.value !== currentStatus);
	};
	const findEditedStatus = (currentStatus: string) => {
		return STATUS_TO_EDIT.find((el) => el.value === currentStatus) || INIT_VALUE_TO_EDIT;
	};
	const [editedStatus, setEditedStatus] =
		useState<IHistoryFiltersOptionStringValue>(INIT_VALUE_TO_EDIT);
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editedNote, setEditedNote] = useState(note);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value);
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => {
		setEditMode(true);
		setEditedStatus(findEditedStatus(status));
		setEditedNote(note);
	};
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		if (editedStatus.value === EApiCryptoTransactionStatus.FAILED) {
			dispatch(putCryptoWithdrawalRejectRequest(id));
		}
		if (editedStatus.value === EApiCryptoTransactionStatus.COMPLETED) {
			dispatch(putCryptoWithdrawalApproveRequest(id));
		}
		if (editedNote !== note) {
			dispatch(putCryptoNoteRequest({ type, data: { transaction_id: id, note: editedNote } }));
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedStatus(findEditedStatus(status));
		disableEditMode();
	};

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{user?.id}</p>
			</div>
			{isAllTypes && (
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p className="td-status">{type}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Currency or Trading pair</p>
				<p>BTC/EUR</p>
			</div>
			{/* <div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{chain?.code}</p>
				<span className="td-more-info">{chain?.name}</span>
			</div> */}
			<div className="td">
				<p className="td-hidden-name">Gross</p>
				<p>{roundingNumber(gross_fee, asset.code)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{roundingNumber(fee, asset.code)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				<p>{roundingNumber(net_fee, asset.code)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>
			{type !== EApiTransactionType.DEPOSIT && (
				<div className="td">
					<p className="td-hidden-name">Tx ID</p>
					<p>{id}</p>
				</div>
			)}
			{type === EApiTransactionType.DEPOSIT && (
				<div className="td">
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(tx_hash, 4, 4) || '-'}
						</span>
						{!!tx_hash && (
							<CopyToClipboard text={tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M3.29199 12.8151C3.05866 12.8151 2.85449 12.7276 2.67949 12.5526C2.50449 12.3776 2.41699 12.1734 2.41699 11.9401V3.14635H3.29199V11.9401H10.2045V12.8151H3.29199ZM5.04199 11.0651C4.80866 11.0651 4.60449 10.9776 4.42949 10.8026C4.25449 10.6276 4.16699 10.4234 4.16699 10.1901V2.02344C4.16699 1.7901 4.25449 1.58594 4.42949 1.41094C4.60449 1.23594 4.80866 1.14844 5.04199 1.14844H11.4587C11.692 1.14844 11.8962 1.23594 12.0712 1.41094C12.2462 1.58594 12.3337 1.7901 12.3337 2.02344V10.1901C12.3337 10.4234 12.2462 10.6276 12.0712 10.8026C11.8962 10.9776 11.692 11.0651 11.4587 11.0651H5.04199ZM5.04199 10.1901H11.4587V2.02344H5.04199V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</p>
				</div>
			)}
			{type === EApiTransactionType.WITHDRAWAL && !isAllTypes && (
				<div className="td">
					<p className="td-hidden-name">Admin Notes</p>
					{editMode ? (
						<div className="input">
							<div className="input-wrapper">
								<input
									type="text"
									value={editedNote}
									placeholder="Notes"
									onChange={handleChangeEditedNote}
									className="input-item input-item--type-td"
								/>
							</div>
						</div>
					) : (
						<div className="tooltip-item-note">
							<p className="tooltip-item-text">{note ? `${note?.slice(0, 10)}...` : '-'}</p>
							{note && (
								<div className="tooltip-note">
									<p>{note}</p>
									<i />
								</div>
							)}
						</div>
					)}
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{editMode && status === EApiCryptoTransactionStatus.ADMIN_PENDING ? (
					<TableItemSelect
						activeElement={editedStatus}
						selectArray={statusArrayToEdit}
						onChange={handleEditStatus}
					/>
				) : (
					<span
						className={
							status === EApiCryptoTransactionStatus.ADMIN_PENDING
								? 'pending-status'
								: `${status}-status`
						}
					>
						{status === EApiCryptoTransactionStatus.ADMIN_PENDING
							? `Pending`
							: `${status.charAt(0).toUpperCase()}${status.slice(1)}`}
					</span>
				)}
			</div>
		</div>
	);
};

export default CryptoTransactionsRow;
