import React from 'react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import DetailedView from 'components/DetailedView/DetailedView';

import { EPendingDepositsType } from 'services/api/transactions/types';
import PendingDepositsTable from './PendingDepositsTable';

const PendingDepositsTab = () => {
	const { path: matchPath } = useRouteMatch();
	const { state } = useLocation();

	return (
		<div className="user-management">
			{!state && (
				<div className="tabs-buttons-wrapper">
					<div className="tabs-buttons tabs-buttons--pending">
						<NavLink to={ROUTES.transfersAndTradeReports.pendingDeposits.crypto}>Crypto</NavLink>
						<NavLink to={ROUTES.transfersAndTradeReports.pendingDeposits.fiat}>Fiat</NavLink>
					</div>
				</div>
			)}

			<Switch>
				<Route exact path={ROUTES.transfersAndTradeReports.pendingDeposits.crypto}>
					<PendingDepositsTable type={EPendingDepositsType.CRYPTO} />
				</Route>
				<Route exact path={ROUTES.transfersAndTradeReports.pendingDeposits.fiat}>
					<PendingDepositsTable type={EPendingDepositsType.FIAT} />
				</Route>
				<Route path={`${matchPath}/:id`} component={DetailedView} />
				<Redirect to={ROUTES.transfersAndTradeReports.pendingDeposits.crypto} />
			</Switch>
		</div>
	);
};

export default PendingDepositsTab;
