/* eslint-disable react/require-default-props */

import React, { FC, useLayoutEffect, useState } from 'react';
// import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks/useSelect';
import { capitalize, trimBottomLine } from 'services/utils/strings';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

interface IProps {
	textTitle?: string;
	textInitial?: string;
	changeFilter: any;
	selectedItemsArray: any;
	selectedElement?: any;
	disabled?: boolean;
}
export const ItemSelect: FC<IProps> = ({
	textTitle,
	textInitial = 'All',
	selectedItemsArray,
	selectedElement,
	changeFilter,
	disabled,
}) => {
	const [selectedItems, setSelectedItems] = useState<any[]>([
		{
			id: 0,
			text: textInitial,
		},
	]);

	useLayoutEffect(() => {
		setSelectedItems([...selectedItems, ...selectedItemsArray]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [selectedItem, setSelectedItem] = useState<any>(selectedItems[0]);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const handleSelect = (value: any) => {
		if (changeFilter) {
			changeFilter({ selectedItem: value.text === 'All' ? undefined : value.text });
		}
		setSelectedItem(value);
		setOpen(false);
	};

	useLayoutEffect(() => {
		if (selectedElement !== undefined) {
			const filteredItemsArray = selectedItemsArray.filter(
				(item: any) => item.text !== textInitial,
			);
			setSelectedItems(filteredItemsArray);

			if (filteredItemsArray.length > 0) {
				setSelectedItem(filteredItemsArray[0]);
			}
		}
	}, [selectedElement, textInitial, selectedItemsArray]);

	useLayoutEffect(() => {
		if (selectedElement !== undefined) {
			const selectedItemState = selectedItems.find((item: any) => item.text === selectedElement);
			if (selectedItemState) {
				setSelectedItem(selectedItemState);
			} else {
				setSelectedItem(null);
			}
		}
	}, [selectedItems, selectedElement]);

	return (
		<div className="item-select">
			<div
				className="select-block select-block--mb-0"
				// style={{ cursor: !disabled ? 'pointer' : 'not-allowed' }}
				style={{ cursor: !disabled ? 'pointer' : 'default' }}
			>
				{textTitle !== undefined ? (
					<div className="select-block__title">
						<p className="select-block__name">{trimBottomLine(capitalize(textTitle))}</p>
					</div>
				) : null}
				<div
					className={`select select--type-choice ${open ? 'active' : ''} ${
						disabled ? 'disabled-color' : ''
					}`}
				>
					<button
						type="button"
						className="select__current"
						ref={triggerRef}
						style={{ cursor: !disabled ? 'pointer' : 'default' }}
						onClick={() => {
							if (!disabled) {
								toggleOpen();
							}
						}}
					>
						<div className="selectInput">
							{trimBottomLine(
								capitalize(
									selectedItems.find((opt: any) => opt?.text === selectedItem?.text)?.text,
								),
							)}
						</div>
						<span className="select__current-arrow">
							<svg
								width="10"
								height="5"
								viewBox="0 0 10 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0H1H0L5 5L10 0Z" fill="#777E90" />
							</svg>
						</span>
					</button>

					<AnimatePresence>
						{open && (
							<motion.div
								className="select__drop"
								style={{ display: 'block' }}
								ref={dropRef}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { duration: 0.3 } }}
								exit={{ opacity: 0, transition: { duration: 0.3 } }}
							>
								<div className="select__drop-scroll select__drop-scroll--type2">
									<div className="select__drop-item">
										<ul>
											{selectedItems.map((e: any) => (
												<li key={e.id}>
													<button
														type="button"
														className={selectedItem.text === e.text ? 'active' : ''}
														onClick={() => handleSelect(e)}
													>
														<div className="selectInput">
															<span>{trimBottomLine(capitalize(e.text))}</span>
														</div>
														{/* <span>
															{trimBottomLine(capitalize(selectedItem.text)) ===
																trimBottomLine(capitalize(e.text))}
														</span> */}
													</button>
												</li>
											))}
										</ul>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
		</div>
	);
};
