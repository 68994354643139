import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { putUserLiquidityProviderRequest } from 'redux/reducers/users/reducer';
import { getOneUser } from 'redux/reducers/users/selectors';
// import { getUserWalletsCrypto, getUserWalletsFiat } from 'redux/reducers/users/selectors';

const TradeManagementTabView = () => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const user = useSelector(getOneUser);
	const permissions = useSelector(getPermissions);
	const sendButton = (text: string) => {
		dispatch(putUserLiquidityProviderRequest({ userId, liquidity_provider: text }));
	};
	return (
		<div className="user-management">
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--trade-management">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Liquidity Provider</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Liquidity Provider</p>
									<div className="td__wrap">
										<p>B2C2</p>
									</div>
								</div>

								<div className="td td--right">
									<p className="td-hidden-name">Action</p>
									<div className="switch">
										<label className="switch__label">
											<input
												checked
												type="checkbox"
												disabled={
													!!userId || !permissions?.[EPermissionNames.USER_MANAGEMENT].editable
												}
												className="hidden"
											/>
											<span className="switch__toggler" />
										</label>
									</div>
								</div>
							</div>
							{/* <div className="tr">
									<div className="td">
										<p className="td-hidden-name">Liquidity Provider</p>
										<p>FTX OTC</p>
									</div>
									<div className="td td--right">
										<p className="td-hidden-name">Action</p>
										<div className="switch">
											<label className="switch__label">
												<input
													onChange={() =>
														sendButton(
															user?.data?.liquidity_provider === 'FTX_OTC' ? 'B2C2' : 'FTX_OTC',
														)
													}
													disabled={
														!!userId || !permissions?.[EPermissionNames.USER_MANAGEMENT].editable
													}
													type="checkbox"
													checked={userId ? user?.data?.liquidity_provider === 'FTX_OTC' : false}
													className="hidden"
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeManagementTabView;
