import { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router';

import { getOneUserRequest } from 'redux/reducers/users/reducer';
import { ROUTES } from 'routes/constants';
import AccountHeader from './AccountHeader/AccountHeader';
import AccountTabs from './AccountTabs/AccountTabs';
import AccountTab from './AccountTab/AccountTab';
import BalancesTab from './BalancesTab/BalancesTab';
import PaymentMethodTab from './PaymentMethodTab/PaymentMethodTab';
import PaymentMethodUpdateBank from './PaymentMethodTab/PaymentMethodUpdateBank/PaymentMethodUpdateBank';
import ReferralTab from './ReferralTab/ReferralTab';
import ReferralManagement from './ReferralTab/ReferralManagement/ReferralManagement';
import TradingLimitsTab from './TradingLimitsTab/TradingLimitsTab';
import TransactionHistoryTab from './TransactionHistoryTab/TransactionHistoryTab';
import FeeManagementTab from './FeeManagementTab/FeeManagementTab';
import TradeManagementTab from './TradeManagementTab/TradeManagementTab';
import { IAccountDetailsParams } from './types';
import EmailsTab from './EmailsTab';
import TransferHistoryTab from './TransferHistoryTab/TransferHistoryTab';
import CurrenciesTab from './CurrenciesTab/CurrenciesTab';
import DepositHistoryTabView from './DepositHistoryTab/DepositHistoryTabView';
import { EPaymentMethodTab } from './PaymentMethodTab/types';
import TransferLimitsTab from './TransferLimitsTab/TransferLimitsTab';

const AccountDetails = () => {
	const dispatch = useDispatch();

	const { userId } = useParams<IAccountDetailsParams>();
	const { path } = useRouteMatch();

	const [isUpdatingBank, setIsUpdatingBank] = useState(false);
	const [bankAccountId, setBankAccountId] = useState<number | null>(null);
	const [paymentMethodActiveTab, setPaymentMethodActiveTab] = useState(EPaymentMethodTab.CRYPTO);

	useLayoutEffect(() => {
		dispatch(getOneUserRequest({ userId }));
	}, [dispatch, userId]);

	const setUpdatingBankHandler = (id: number) => {
		setIsUpdatingBank(true);
		setBankAccountId(id);
		window.scrollTo(0, 0);
	};

	const setDisableUpdatingBankHandler = () => {
		setIsUpdatingBank(false);
		setPaymentMethodActiveTab(EPaymentMethodTab.FIAT);
	};

	return (
		<>
			{isUpdatingBank ? (
				<PaymentMethodUpdateBank
					setDisableUpdatingBankHandler={setDisableUpdatingBankHandler}
					bankAccountId={bankAccountId}
				/>
			) : (
				<div className="account-details">
					<AccountHeader />

					<div className="user-management-wrap">
						<AccountTabs userId={userId} />

						<Switch>
							<Route exact path={`${path}${ROUTES.AccountTabs.ACCOUNT}`} component={AccountTab} />
							<Route exact path={`${path}${ROUTES.AccountTabs.BALANCES}`} component={BalancesTab} />
							<Route
								path={`${path}${ROUTES.AccountTabs.DEPOSIT_HISTORY}`}
								component={DepositHistoryTabView}
							/>
							<Route
								path={`${path}${ROUTES.AccountTabs.WITHDRAWAL_HISTORY}`}
								component={DepositHistoryTabView}
							/>
							<Route
								path={`${path}${ROUTES.AccountTabs.TRANSACTION_HISTORY}`}
								component={TransactionHistoryTab}
							/>
							<Route
								path={`${path}${ROUTES.AccountTabs.TRANSFER_HISTORY}`}
								component={TransferHistoryTab}
							/>
							<Route
								path={`${path}${ROUTES.AccountTabs.TRADE_HISTORY}`}
								component={TransactionHistoryTab}
							/>
							<Route exact path={`${path}${ROUTES.AccountTabs.REFERRAL}`} component={ReferralTab} />
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.TRADING_LIMITS}`}
								component={TradingLimitsTab}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.TRANSFER_LIMITS}`}
								component={TransferLimitsTab}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.PAYMENT_METHOD}`}
								render={() => (
									<PaymentMethodTab
										setUpdatingBankHandler={setUpdatingBankHandler}
										paymentMethodActiveTab={paymentMethodActiveTab}
										setPaymentMethodActiveTab={setPaymentMethodActiveTab}
									/>
								)}
							/>
							<Route
								exact
								path={`${path}/beneficiaries/update-bank-account`}
								component={PaymentMethodUpdateBank}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.TRADE_MANAGEMENT}`}
								component={TradeManagementTab}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.FEE_MANAGEMENT}`}
								component={FeeManagementTab}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.CURRENCIES_EMAILS}`}
								component={CurrenciesTab}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.REQUIRED_EMAILS}`}
								component={EmailsTab}
							/>
							<Route
								exact
								path={`${path}${ROUTES.AccountTabs.REFERRAL_FEE}`}
								component={ReferralManagement}
							/>
							<Redirect to={`${path}${ROUTES.AccountTabs.ACCOUNT}`} />
						</Switch>
					</div>
				</div>
			)}
		</>
	);
};

export default AccountDetails;
