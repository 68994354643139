import React from 'react';

interface IProps {
	// eslint-disable-next-line react/require-default-props
	height?: number;
	// eslint-disable-next-line react/require-default-props,react/no-unused-prop-types
	fontSize?: number;
}

const Loading: React.FC<IProps> = ({ height = 400, fontSize = 22 }) => {
	return (
		<div style={{ height }} className="loading">
			<div className="load-wrapp">
				<div className="load-6">
					<div style={{ fontSize }} className="letter-holder">
						<div className="l-1 letter">L</div>
						<div className="l-2 letter">o</div>
						<div className="l-3 letter">a</div>
						<div className="l-4 letter">d</div>
						<div className="l-5 letter">i</div>
						<div className="l-6 letter">n</div>
						<div className="l-7 letter">g</div>
						<div className="l-8 letter">.</div>
						<div className="l-9 letter">.</div>
						<div className="l-10 letter">.</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loading;
