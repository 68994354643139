import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
/* eslint-disable @typescript-eslint/naming-convention */
import { api } from 'services';

import { responseErrors } from 'services/http/responseErrors';
import {
	EApiTransactionType,
	IApiGetTransactionParams,
	IApiPostFiatDepositParams,
	IApiPutWithdrawalLimitPayload,
	IGeneralWithdrawalLimit,
	IGetFilePayload,
	IPutFiatTransactionsPayload,
	IPutGeneralWithdrawalLimitPayload,
	IPutTradeLimitPayload,
	ITradeLimitsResponse,
	TApiGetCryptoHistoryResponse,
	TApiGetCryptoTransactionsResponse,
	TApiGetFiatHistoryResponse,
	TApiGetFiatTransactionsResponse,
	TGetPooledDepositsResponse,
	TApiGetTradeHistoryResponse,
	TWithdrawalLimitResponse,
	TCryptoDepositsResponse,
	TTradesResponse,
	EWithdrawalLimitType,
	TProfitWalletResponse,
	IGetPendingWithdrawalPayloadRequest,
	TPendingWithdrawalResponse,
	IPendingWithdrawalFilePayloadRequest,
	IPutPendingWithdrawalPayloadRequest,
	EPendingWithdrawalType,
	IPutCryptoNotePayloadRequest,
	IGetPendingDepositPayloadRequest,
	TPendingDepositResponse,
	IApiGetLedgerTransactionParams,
	TLedgerResponse,
} from 'services/api/transactions/types';
import {
	convertFilterObjectToParamsObject,
	IConvertFilterObjectToParamsObjectReturnValue,
} from 'services/utils/convertFilterObjectToParamsObject';
import { notificationsMessagesPopUp } from 'services/utils/notificationsMessages/notificationsMessagesPopUp';
import { notificationContainer } from 'services/utils/notificationContainer';
import { downloadFile } from 'services/utils/downloadFile';
import { getDepositableFiat } from 'utils/depositableList';

import {
	addDepositCryptoRequest,
	addDepositCryptoSuccess,
	addTradeRequest,
	addTradeSuccess,
	addWithdrawalCryptoRequest,
	addWithdrawalCryptoSuccess,
	addWithdrawalFiatRequest,
	addWithdrawalFiatSuccess,
	disableCryptoTransactionsLoading,
	disableFiatTransactionsLoading,
	disableGeneralWithdrawalLimitLoading,
	disableLedgerLoading,
	disablePendingDepositLoading,
	disablePendingWithdrawalLoading,
	disablePooledDepositsLoading,
	disableProfitWalletsLoading,
	disableTradesLoading,
	disableTradingLimitsLoading,
	disableWithdrawalLimitsLoading,
	getAllBankAccountRequest,
	getAllBankAccountSuccess,
	getCryptoDepositsRequest,
	getCryptoDepositsSuccess,
	getCryptoHistoryError,
	getCryptoHistoryRequest,
	getCryptoHistorySuccess,
	getCryptoTransactionFileRequest,
	getCryptoTransactionsRequest,
	getCryptoTransactionsSuccess,
	getCryptoTransfersFileRequest,
	getCryptoTransfersRequest,
	getCryptoTransfersSuccess,
	getCryptoWithdrawalLimitsRequest,
	getFiatDepositsRequest,
	getFiatDepositsSuccess,
	getFiatHistoryError,
	getFiatHistoryRequest,
	getFiatHistorySuccess,
	getFiatTransactionFileRequest,
	getFiatTransactionsRequest,
	getFiatTransactionsSuccess,
	getFiatTransfersFileRequest,
	getFiatTransfersRequest,
	getFiatTransfersSuccess,
	getFiatWithdrawalLimitsRequest,
	getGeneralWithdrawalLimitRequest,
	getGeneralWithdrawalLimitSuccess,
	getLedgerTransactionsRequest,
	getLedgerTransactionsSuccess,
	getPendingDepositFileRequest,
	getPendingDepositRequest,
	getPendingDepositSuccess,
	getPendingWithdrawalFileRequest,
	getPendingWithdrawalRequest,
	getPendingWithdrawalSuccess,
	getPooledDepositsFileRequest,
	getPooledDepositsRequest,
	getPooledDepositsSuccess,
	getProfitWalletsFileRequest,
	getProfitWalletsRequest,
	getProfitWalletsSuccess,
	getReferralTradesFileRequest,
	getTradeHistoryError,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTradesFileRequest,
	getTradesRequest,
	getTradesSuccess,
	getTradesTransfersCryptoFileRequest,
	getTradingLimitsCryptoRequest,
	getTradingLimitsFiatRequest,
	getTradingLimitsSuccess,
	getWithdrawalLimitsSuccess,
	pendingWithdrawalCryptoFilterById,
	postFiatDepositRequest,
	putCryptoNoteRequest,
	putCryptoWithdrawalApproveRequest,
	putCryptoWithdrawalRejectRequest,
	putFiatTransactionsRequest,
	putGeneralWithdrawalLimitRequest,
	putPendingDepositApproveRequest,
	putPendingDepositNoteRequest,
	putPendingDepositRejectRequest,
	putPendingWithdrawalApproveRequest,
	putPendingWithdrawalCancelRequest,
	putPendingWithdrawalCryptoApproveRequest,
	putPendingWithdrawalCryptoRejectRequest,
	putPendingWithdrawalNoteRequest,
	putPendingWithdrawalRejectRequest,
	putPendingWithdrawalStatusRequest,
	putTradingLimitRequest,
	putWithdrawalLimitsRequest,
} from './reducer';
import {
	IGetTransactionHistoryRequestPayload,
	TCryptoTransactions,
	TFiatTransactions,
	IGetTradeLimitPayload,
	IPostFiatDepositWorker,
	IAddWithdrawalFiatWorker,
	IAddDepositCryptoWorker,
	IAddWithdrawalCryptoWorker,
	IAddTradeWorker,
} from './types';
import {
	getCryptoTransactions,
	getFiatDepositsList,
	getFiatTransactions,
	getPendingDeposit,
	getPendingWithdrawal,
} from './selectors';
import {
	getCryptoFilters,
	getFiatFilters,
	getPendingCryptoWithdrawalFilters,
	getPendingFiatWithdrawalFilters,
} from '../transactionsFilters/selectors';
import { ITransactionsFilter } from '../transactionsFilters/types';
import { getAdminId } from '../auth/selectors';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { getFiatCurrency } from '../currency/selectors';
import { ICurrencyFiat } from '../currency/types';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { clearError, setErrorMsg } from '../errors/reducer';

// new

function* getAllBankAccountWorker() {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const data: never = yield call(api.transactions.getAllBankAccount);

		yield put(getAllBankAccountSuccess(data));
	} catch (error) {
		yield put(getCryptoHistoryError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getCryptoHistoryWorker({ payload }: PayloadAction<IGetTransactionHistoryRequestPayload>) {
	const { apiParams, onFinally } = payload;

	try {
		const data: TApiGetCryptoHistoryResponse = yield call(
			api.transactions.getCryptoHistory,
			apiParams,
		);

		yield put(getCryptoHistorySuccess(data));
	} catch (error) {
		yield put(getCryptoHistoryError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.();
	}
}

function* getFiatHistoryWorker({ payload }: PayloadAction<IGetTransactionHistoryRequestPayload>) {
	const { apiParams, onFinally } = payload;

	try {
		const data: TApiGetFiatHistoryResponse = yield call(api.transactions.getFiatHistory, apiParams);

		yield put(getFiatHistorySuccess(data));
	} catch (error) {
		yield put(getFiatHistoryError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.();
	}
}

function* getTradeHistoryWorker({ payload }: PayloadAction<IGetTransactionHistoryRequestPayload>) {
	const { apiParams, onFinally } = payload;

	try {
		const data: TApiGetTradeHistoryResponse = yield call(
			api.transactions.getTradeHistory,
			apiParams,
		);

		yield put(getTradeHistorySuccess(data));
	} catch (error) {
		yield put(getTradeHistoryError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.();
	}
}

function* getCryptoTransfersWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.transactions.getCryptoTransfers, payload);
		yield put(getCryptoTransfersSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error')
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}

function* getFiatTransfersWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.transactions.getFiatTransfers, payload);
		yield put(getFiatTransfersSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error')
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
		yield put(disableFiatTransactionsLoading());
	}
}

function* getCryptoDepositsWorker({ payload }: PayloadAction<any>) {
	try {
		const response: TCryptoDepositsResponse = yield call(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			api.transactions.getCryptoDeposits,
			payload,
		);
		yield put(getCryptoDepositsSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error')
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}

function* getFiatDepositsWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			api.transactions.getFiatDeposits,
			payload,
		);
		yield put(getFiatDepositsSuccess(response));
	} catch (error) {
		// notificationContainer('Error', 'error')
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}

function* getCryptoTransactionsWorker({ payload }: PayloadAction<IApiGetTransactionParams>) {
	try {
		const data: TApiGetCryptoTransactionsResponse = yield call(
			api.transactions.getCryptoTransactions,
			payload,
		);

		yield put(getCryptoTransactionsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}
function* getFiatTransactionsWorker({ payload }: PayloadAction<IApiGetTransactionParams>) {
	try {
		const data: TApiGetFiatTransactionsResponse = yield call(
			api.transactions.getFiatTransactions,
			payload,
		);

		yield put(getFiatTransactionsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableFiatTransactionsLoading());
	}
}
function* putFiatTransactionsWorker({ payload }: PayloadAction<IPutFiatTransactionsPayload>) {
	try {
		const fiatDeposits: TFiatTransactions = yield select(getFiatDepositsList);
		const fiatTransactions: TFiatTransactions = yield select(getFiatTransactions);
		const activeFilters: ITransactionsFilter[] = yield select(getFiatFilters);
		const filterParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putFiatTransactions, payload.data, payload.type, payload.id);

		yield put(
			getFiatTransactionsRequest({
				type: payload.type,
				per_page: fiatTransactions?.per_page,
				current_page: fiatTransactions?.current_page,
				...filterParams,
			}),
		);
		yield put(
			getFiatDepositsRequest({
				type: payload.type,
				per_page: fiatDeposits?.per_page,
				current_page: fiatDeposits?.current_page,
				...filterParams,
			}),
		);
		yield put(
			getPendingDepositRequest({
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				type: payload.type,
				per_page: fiatDeposits?.per_page,
				current_page: fiatDeposits?.current_page,
				...filterParams,
			}),
		);

		notificationContainer(notificationsMessagesInfo.changeSuccess, 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableFiatTransactionsLoading());
	}
}
function* putCryptoWithdrawalApproveWorker({ payload }: PayloadAction<number>) {
	try {
		const cryptoTransactions: TCryptoTransactions = yield select(getCryptoTransactions);
		const activeFilters: ITransactionsFilter[] = yield select(getCryptoFilters);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putCryptoWithdrawalApprove, payload);
		notificationContainer('Data updated successfully.', 'success');

		yield put(
			getCryptoTransactionsRequest({
				type: EApiTransactionType.WITHDRAWAL,
				per_page: cryptoTransactions?.per_page,
				current_page: cryptoTransactions?.current_page,
				...cryptoParams,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}
function* putCryptoWithdrawalRejectWorker({ payload }: PayloadAction<number>) {
	try {
		const cryptoTransactions: TCryptoTransactions = yield select(getCryptoTransactions);
		const activeFilters: ITransactionsFilter[] = yield select(getCryptoFilters);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putCryptoWithdrawalReject, payload);
		notificationContainer('Data updated successfully.', 'success');

		yield put(
			getCryptoTransactionsRequest({
				type: EApiTransactionType.WITHDRAWAL,
				per_page: cryptoTransactions?.per_page,
				current_page: cryptoTransactions?.current_page,
				...cryptoParams,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}
function* putCryptoNoteWorker({ payload }: PayloadAction<IPutCryptoNotePayloadRequest>) {
	try {
		const cryptoTransactions: TCryptoTransactions = yield select(getCryptoTransactions);
		const activeFilters: ITransactionsFilter[] = yield select(getCryptoFilters);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putCryptoNote, payload.data);

		yield put(
			getCryptoTransactionsRequest({
				type: payload.type as EApiTransactionType,
				per_page: cryptoTransactions?.per_page,
				current_page: cryptoTransactions?.current_page,
				...cryptoParams,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableCryptoTransactionsLoading());
	}
}
// function* postFiatDepositWorker({ payload }: PayloadAction<IApiPostFiatDepositParams>) {
function* postFiatDepositWorker({ payload }: PayloadAction<IPostFiatDepositWorker>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;

	try {
		yield call(api.transactions.postFiatDeposits, apiParams);

		yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionCreated }));
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg('Invalid totp code.'));
				hasError = true;
				// notificationContainer('Invalid totp code.', 'user_blocked', 'Errors');
			} else {
				const fiatCurrencies: ICurrencyFiat = yield select(getFiatCurrency);
				responseErrors(error, getDepositableFiat(fiatCurrencies));
				yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionNotCreated }));
				yield put(popUpOpen('errorPopUp'));
			}
		}
	} finally {
		onFinally?.(hasError);
		// yield put(clearError());
		// yield put(disableCryptoTransactionsLoading());
		const fiatTransactions: TFiatTransactions = yield select(getFiatTransactions);
		const fiatDeposits: TFiatTransactions = yield select(getFiatDepositsList);
		const activeFilters: ITransactionsFilter[] = yield select(getFiatFilters);

		const filterParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);
		yield put(
			getFiatTransactionsRequest({
				type: EApiTransactionType.DEPOSIT,
				per_page: fiatTransactions?.per_page,
				current_page: fiatTransactions?.current_page,
				...filterParams,
			}),
		);
		yield put(
			getFiatDepositsRequest({
				type: EApiTransactionType.DEPOSIT,
				per_page: fiatDeposits?.per_page,
				current_page: fiatDeposits?.current_page,
				...filterParams,
			}),
		);
	}
}
function* getPooledDepositsWorker({ payload }: PayloadAction<IApiGetTransactionParams>) {
	try {
		const data: TGetPooledDepositsResponse = yield call(
			api.transactions.getPooledDeposits,
			payload,
		);

		yield put(getPooledDepositsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePooledDepositsLoading());
	}
}
function* getPooledDepositsFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		// const response: string = yield call(api.transactions.getPooledDepositsFile, {
		// 	token: token.secret,
		// 	type: payload?.type,
		// 	fileType: payload.fileType,
		// 	adminId: payload.adminId,
		// });
		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(response, `Pooled Deposits.${payload.fileType}`);
		// 	// }
		// });
		const { origin } = window.location;

		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto_pooled/report/${
				payload.fileType
			}/${token.secret}${payload?.adminId ? `/${payload?.adminId}` : ''}`,
		);

		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto_pooled/report/${
				payload.fileType
			}/${token.secret}${payload?.adminId ? `/${payload?.adminId}` : ''}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getCryptoWithdrawalLimitsWorker() {
	try {
		const data: TWithdrawalLimitResponse = yield call(api.transactions.getCryptoWithdrawalLimits);

		yield put(getWithdrawalLimitsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableWithdrawalLimitsLoading());
	}
}
function* getFiatWithdrawalLimitsWorker() {
	try {
		const data: TWithdrawalLimitResponse = yield call(api.transactions.getFiatWithdrawalLimits);

		yield put(getWithdrawalLimitsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableWithdrawalLimitsLoading());
	}
}
function* putWithdrawalLimitsWorker({ payload }: PayloadAction<IApiPutWithdrawalLimitPayload>) {
	try {
		yield call(api.transactions.putWithdrawalLimits, payload);
		// yield payload.type === EWithdrawalLimitType.CRYPTO && put(getCryptoWithdrawalLimitsRequest({}));
		// yield payload.type === EWithdrawalLimitType.FIAT && put(getFiatWithdrawalLimitsRequest({}));
		notificationContainer('Transfer limit was updated successfully', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
			notificationContainer('Incorrect data', 'user_blocked', 'Error');
		}
	} finally {
		// yield put(disableWithdrawalLimitsLoading());
	}
}

function* getGeneralWithdrawalLimitWorker() {
	try {
		const data: IGeneralWithdrawalLimit = yield call(api.transactions.getGeneralWithdrawalLimit);

		yield put(getGeneralWithdrawalLimitSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableGeneralWithdrawalLimitLoading());
	}
}

function* putGeneralWithdrawalLimitWorker({
	payload,
}: PayloadAction<IPutGeneralWithdrawalLimitPayload>) {
	try {
		yield call(api.transactions.putGeneralWithdrawalLimit, payload);
		notificationContainer(notificationsMessagesInfo.putGeneralWithdrawalLimit, 'success');
		yield put(getGeneralWithdrawalLimitRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableGeneralWithdrawalLimitLoading());
	}
}

function* getTradesWorker({ payload }: PayloadAction<IApiGetTransactionParams>) {
	try {
		const data: TTradesResponse = yield call(api.transactions.getTrades, {
			status: 'completed',
			...payload,
		});

		yield put(getTradesSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableTradesLoading());
	}
}
function* getTradesFileWorker({ payload }: PayloadAction<IGetFilePayload & { adminId: number }>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);

		// const response: string = yield call(api.transactions.getTradesFile, {
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// 	adminId: payload.adminId,
		// });
		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(response, `Trades.${payload.fileType}`);
		// 	// }
		// });

		const { origin } = window.location;
		/* Old endpoint
		`${process.env.REACT_APP_WEB_API_URL}/admin/trade_referrals/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}` */
		/* New endpoint
		/trade/report/trades/{extension}/{secret}/{admin_id} */
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/trade/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}`,
		);

		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/trade/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getReferralTradesFileWorker({
	payload,
}: PayloadAction<IGetFilePayload & { adminId: number }>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/trade_referrals/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/trade_referrals/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getTradesTransfersCryptoFileWorker({
	payload,
}: PayloadAction<IGetFilePayload & { adminId: number }>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		// const response: string = yield call(api.transactions.getTradesFile, {
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// 	adminId: payload.adminId,
		// });
		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(response, `Trades.${payload.fileType}`);
		// 	// }
		// });
		const { origin } = window.location;
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${origin}/api/admin/trade/report/trades/${payload.fileType}/${token.secret}/${payload.adminId}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getTradingLimitsCryptoWorker({ payload }: PayloadAction<IGetTradeLimitPayload>) {
	try {
		const data: ITradeLimitsResponse = yield call(api.transactions.getTradingLimitsCrypto, payload);

		yield put(getTradingLimitsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableTradingLimitsLoading());
	}
}
function* getTradingLimitsFiatWorker({ payload }: PayloadAction<IGetTradeLimitPayload>) {
	try {
		const data: ITradeLimitsResponse = yield call(api.transactions.getTradingLimitsFiat, payload);

		yield put(getTradingLimitsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableTradingLimitsLoading());
	}
}
function* putTradingLimitWorker({ payload }: PayloadAction<IPutTradeLimitPayload>) {
	const { limit_max, limit_min, onSuccess, trading_limit_id } = payload;
	try {
		yield call(api.transactions.putTradingLimit, { trading_limit_id, limit_min, limit_max });
		yield put(onSuccess?.());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// console.log(error?.response?.data?.errors);
			if (error?.response?.data?.errors[0].includes('the_limit_max_must_be_greater_than')) {
				notificationContainer(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Trade limit must be greater than ${error?.response?.data?.errors[0].replace(
						'the_limit_max_must_be_greater_than_',
						'',
					)}`,
					'user_blocked',
					'Limit is too low',
				);
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(disableTradingLimitsLoading());
	}
}

function* getCryptoTransactionFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const type =
			payload?.type === EApiTransactionType.DEPOSIT ||
			payload?.type === EApiTransactionType.WITHDRAWAL
				? payload?.type
				: undefined;
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		// const response: string = yield call(api.transactions.getCryptoTransactionFile, {
		// 	type,
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// 	adminId: payload.adminId,
		// });
		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(
		// 	// 		response,
		// 	// 		`${
		// 	// 			type === EApiTransactionType.DEPOSIT ? 'Deposit ' : 'Withdrawal ' || ''
		// 	// 		}Crypto Transaction File.${payload.fileType}`,
		// 	// 	);
		// 	// }
		// });
		const { origin } = window.location;
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto/report/${payload.fileType}/${
				token.secret
			}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto/report/${payload.fileType}/${
				token.secret
			}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getFiatTransactionFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const type =
			payload?.type === EApiTransactionType.DEPOSIT ||
			payload?.type === EApiTransactionType.WITHDRAWAL
				? payload?.type
				: undefined;

		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		const { origin } = window.location;
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/fiats/report/${payload.fileType}/${
				token.secret
			}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/fiats/report/${payload.fileType}/${
				token.secret
			}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
		// const response: string = yield call(api.transactions.getFiatTransactionFile, {
		// 	type,
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// 	adminId: payload.adminId,
		// });
		// yield call(() => {
		// 	// downloadFile(
		// 	// 	response,
		// 	// 	`${
		// 	// 		type === EApiTransactionType.DEPOSIT ? 'Deposit ' : 'Withdrawal ' || ''
		// 	// 	}Fiat Transaction File.${payload.fileType}`,
		// 	// );
		// });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getCryptoTransfersFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const type =
			payload?.type === EApiTransactionType.DEPOSIT ||
			payload?.type === EApiTransactionType.WITHDRAWAL
				? payload?.type
				: undefined;
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto/transfers/report/${
				payload.fileType
			}/${token.secret}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto/transfers/report/${
				payload.fileType
			}/${token.secret}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getFiatTransfersFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const type =
			payload?.type === EApiTransactionType.DEPOSIT ||
			payload?.type === EApiTransactionType.WITHDRAWAL
				? payload?.type
				: undefined;
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/fiat/transfers/report/${
				payload.fileType
			}/${token.secret}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/fiat/transfers/report/${
				payload.fileType
			}/${token.secret}${payload.adminId ? `/${payload.adminId}` : ''}${type ? `/${type}` : ''}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getProfitWalletsWorker({ payload }: PayloadAction<IApiGetTransactionParams>) {
	try {
		const data: TProfitWalletResponse = yield call(api.transactions.getProfitWallets, payload);

		yield put(getProfitWalletsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableProfitWalletsLoading());
	}
}
function* getProfitWalletsFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const adminId: number = yield select(getAdminId);
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		// const response: string = yield call(api.transactions.getProfitWalletsFile, {
		// 	adminId,
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// });
		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(response, `Profit Wallets.${payload.fileType}`);
		// 	// }
		//
		// });
		const { origin } = window.location;

		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto_profit/report/${payload.fileType}/${token.secret}/${adminId}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/crypto_profit/report/${payload.fileType}/${token.secret}/${adminId}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getPendingDepositWorker({ payload }: PayloadAction<IGetPendingDepositPayloadRequest>) {
	try {
		const data: TPendingDepositResponse = yield call(api.transactions.getPendingDeposit, payload);

		yield put(getPendingDepositSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingDepositLoading());
	}
}
function* putPendingDepositRejectWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingDeposit: TCryptoTransactions = yield select(getPendingDeposit);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingDepositReject, payload.id);

		const params = {
			per_page: pendingDeposit?.per_page,
			current_page: pendingDeposit?.current_page,
			...cryptoParams,
		};
		yield put(getPendingDepositRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingDepositLoading());
	}
}
function* putPendingDepositApproveWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingDeposit: TCryptoTransactions = yield select(getPendingDeposit);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingDepositApprove, payload.id);

		const params = {
			per_page: pendingDeposit?.per_page,
			current_page: pendingDeposit?.current_page,
			...cryptoParams,
		};
		yield put(getPendingDepositRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const errorMessage = error?.response?.data?.errors[0];
			if (error?.response?.data?.errors.indexOf('fireblocks_balance_error') > -1) {
				notificationContainer('Please check fireblocks balance', 'user_blocked', 'Error');
			} else if (error?.response?.data?.errors.indexOf('server_error') > -1) {
				notificationContainer(
					'Something went wrong. Please contact support if problem persists',
					'user_blocked',
					'Error',
				);
			} else if (
				error?.response?.data?.errors.indexOf('error_create_fireblocks_transaction') > -1
			) {
				notificationContainer(
					'Something went wrong. Please contact support if problem persists',
					'user_blocked',
					'Error',
				);
			} else if (errorMessage.includes('not_enough_pool_balance')) {
				notificationContainer(
					'Insufficient pool wallet balance.',
					'amount_error',
					'Transfer error',
				);
			} else if (
				errorMessage.includes('not_enough_amount') ||
				errorMessage.includes('balance_is_zero')
			) {
				notificationContainer('Amount is too low.', 'amount_error', 'Transfer error');
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(disablePendingDepositLoading());
	}
}
function* putPendingDepositNoteWorker({ payload }: PayloadAction<IPutCryptoNotePayloadRequest>) {
	try {
		const pendingDeposit: TCryptoTransactions = yield select(getPendingDeposit);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingDepositNote, payload.data);

		const params = {
			per_page: pendingDeposit?.per_page,
			current_page: pendingDeposit?.current_page,
			...cryptoParams,
		};
		yield put(getPendingDepositRequest({ type: payload.type as EPendingWithdrawalType, params }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingDepositLoading());
	}
}

function* getPendingWithdrawalWorker({
	payload,
}: PayloadAction<IGetPendingWithdrawalPayloadRequest>) {
	try {
		const data: TPendingWithdrawalResponse = yield call(
			api.transactions.getPendingWithdrawal,
			payload,
		);

		yield put(getPendingWithdrawalSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}

function* getPendingWithdrawalFileWorker({
	payload,
}: PayloadAction<IPendingWithdrawalFilePayloadRequest>) {
	try {
		const adminId: number = yield select(getAdminId);
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		// const response: string = yield call(api.transactions.getPendingWithdrawalFile, {
		// 	adminId,
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// 	assetType: payload.assetType,
		// });

		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(response, `Profit Wallets.${payload.fileType}`);
		// 	// }
		// });
		const { origin } = window.location;
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/pending_withdrawal/report/${payload.fileType}/${token.secret}/${adminId}/${payload.assetType}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/pending_withdrawal/report/${payload.fileType}/${token.secret}/${adminId}/${payload.assetType}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getPendingDepositFileWorker({
	payload,
}: PayloadAction<IPendingWithdrawalFilePayloadRequest>) {
	try {
		const adminId: number = yield select(getAdminId);
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);
		// const response: string = yield call(api.transactions.getPendingWithdrawalFile, {
		// 	adminId,
		// 	token: token.secret,
		// 	fileType: payload.fileType,
		// 	assetType: payload.assetType,
		// });

		// yield call(() => {
		// 	// if (payload?.fileType) {
		// 	// 	downloadFile(response, `Profit Wallets.${payload.fileType}`);
		// 	// }
		// });
		const { origin } = window.location;
		console.log(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/pending_deposit/report/${payload.fileType}/${token.secret}/${adminId}/${payload.assetType}`,
		);
		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/transactions/pending_deposit/report/${payload.fileType}/${token.secret}/${adminId}/${payload.assetType}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* putPendingWithdrawalStatusRequestWorker({ payload }: PayloadAction<any>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalStatus, payload);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(getPendingWithdrawalRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}
function* putPendingWithdrawalApproveWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalApprove, payload.id);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(getPendingWithdrawalRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const errorMessage = error?.response?.data?.errors[0];
			if (error?.response?.data?.errors.indexOf('fireblocks_balance_error') > -1) {
				notificationContainer('Please check fireblocks balance', 'user_blocked', 'Error');
			} else if (error?.response?.data?.errors.indexOf('server_error') > -1) {
				notificationContainer(
					'Something went wrong. Please contact support if problem persists',
					'user_blocked',
					'Error',
				);
			} else if (
				error?.response?.data?.errors.indexOf('error_create_fireblocks_transaction') > -1
			) {
				notificationContainer(
					'Something went wrong. Please contact support if problem persists',
					'user_blocked',
					'Error',
				);
			} else if (errorMessage.includes('not_enough_pool_balance')) {
				notificationContainer(
					'Insufficient pool wallet balance.',
					'amount_error',
					'Transfer error',
				);
			} else if (
				errorMessage.includes('not_enough_amount') ||
				errorMessage.includes('balance_is_zero')
			) {
				notificationContainer('Amount is too low.', 'amount_error', 'Transfer error');
			} else {
				responseErrors(error);
			}
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}
function* putPendingWithdrawalRejectWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalReject, payload.id);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(getPendingWithdrawalRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}
function* putPendingWithdrawalCancelWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalCancel, payload.id);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(getPendingWithdrawalRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}
function* putPendingWithdrawalNoteWorker({ payload }: PayloadAction<IPutCryptoNotePayloadRequest>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalNote, payload.data);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(
			getPendingWithdrawalRequest({ type: payload.type as EPendingWithdrawalType, params }),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}

function* putPendingWithdrawalCryptoApproveWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalCryptoApprove, payload.id);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(getPendingWithdrawalRequest({ type: payload.type, params }));
		// yield put(pendingWithdrawalCryptoFilterById(payload.id));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}
function* putPendingWithdrawalCryptoRejectWorker({
	payload,
}: PayloadAction<IPutPendingWithdrawalPayloadRequest>) {
	try {
		const pendingWithdrawal: TCryptoTransactions = yield select(getPendingWithdrawal);
		const activeFilters: ITransactionsFilter[] = yield select(
			payload.type === EPendingWithdrawalType.CRYPTO
				? getPendingCryptoWithdrawalFilters
				: getPendingFiatWithdrawalFilters,
		);
		const cryptoParams: IConvertFilterObjectToParamsObjectReturnValue =
			yield convertFilterObjectToParamsObject(activeFilters);

		yield call(api.transactions.putPendingWithdrawalCryptoReject, payload.id);

		const params = {
			per_page: pendingWithdrawal?.per_page,
			current_page: pendingWithdrawal?.current_page,
			...cryptoParams,
		};
		yield put(getPendingWithdrawalRequest({ type: payload.type, params }));
		notificationContainer('Data updated successfully.', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disablePendingWithdrawalLoading());
	}
}

function* addDepositCryptoWorker({ payload }: PayloadAction<IAddDepositCryptoWorker>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;

	try {
		yield call(api.transactions.postAddDepositCrypto, apiParams);
		yield put(addDepositCryptoSuccess());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionCreated }));
		yield put(popUpOpen('successPopUp'));
		yield put(
			getCryptoDepositsRequest({
				type: EApiTransactionType.DEPOSIT,
				per_page: 10,
				current_page: 1,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg('Invalid totp code.'));
				hasError = true;
				// notificationContainer('Invalid totp code.', 'user_blocked', 'Errors');
			} else {
				yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionNotCreated }));
				yield put(popUpOpen('errorPopUp'));
			}
		}
	} finally {
		onFinally?.(hasError);
	}
}

function* addWithdrawalFiatWorker({ payload }: PayloadAction<IAddWithdrawalFiatWorker>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;

	try {
		yield call(api.transactions.postAddWithdrawalFiat, apiParams);
		yield put(addWithdrawalFiatSuccess());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionCreated }));
		yield put(popUpOpen('successPopUp'));
		yield put(
			getFiatTransactionsRequest({
				type: EApiTransactionType.WITHDRAWAL,
				per_page: 10,
				current_page: 1,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg('Invalid totp code.'));
				hasError = true;
				// notificationContainer('Invalid totp code.', 'user_blocked', 'Errors');
			} else {
				yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionNotCreated }));
				yield put(popUpOpen('errorPopUp'));
			}
		}
	} finally {
		onFinally?.(hasError);
	}
}

function* addWithdrawalCryptoWorker({ payload }: PayloadAction<IAddWithdrawalCryptoWorker>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;

	try {
		yield call(api.transactions.postAddWithdrawalCrypto, apiParams);
		yield put(addWithdrawalCryptoSuccess());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionCreated }));
		yield put(popUpOpen('successPopUp'));
		yield put(
			getCryptoTransactionsRequest({
				type: EApiTransactionType.WITHDRAWAL,
				per_page: 10,
				current_page: 1,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg('Invalid totp code.'));
				hasError = true;
				// notificationContainer('Invalid totp code.', 'user_blocked', 'Errors');
			} else {
				yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionNotCreated }));
				yield put(popUpOpen('errorPopUp'));
			}
		}
	} finally {
		onFinally?.(hasError);
	}
}

function* addTradeWorker({ payload }: PayloadAction<IAddTradeWorker>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;

	try {
		yield call(api.transactions.postAddTrade, apiParams);
		yield put(addTradeSuccess());
		yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionCreated }));
		yield put(popUpOpen('successPopUp'));
		yield put(
			getTradesRequest({
				per_page: 10,
				current_page: 1,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'invalid_totp_code') {
				yield put(setErrorMsg('Invalid totp code.'));
				hasError = true;
				// notificationContainer('Invalid totp code.', 'user_blocked', 'Errors');
			} else {
				yield put(setPopUpData({ message: notificationsMessagesPopUp.transactionNotCreated }));
				yield put(popUpOpen('errorPopUp'));
			}
		}
	} finally {
		onFinally?.(hasError);
	}
}

function* getLedgerTransactionsWorker({ payload }: PayloadAction<IApiGetLedgerTransactionParams>) {
	try {
		const data: TLedgerResponse = yield call(api.transactions.getLedgerTransactions, {
			...payload,
		});

		yield put(getLedgerTransactionsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(disableLedgerLoading());
	}
}

export function* transactionsSaga() {
	yield takeLatest(getAllBankAccountRequest, getAllBankAccountWorker);
	yield takeLatest(getCryptoHistoryRequest, getCryptoHistoryWorker);
	yield takeLatest(getFiatHistoryRequest, getFiatHistoryWorker);
	yield takeLatest(getTradeHistoryRequest, getTradeHistoryWorker);
	yield takeEvery(getCryptoTransfersRequest, getCryptoTransfersWorker);
	yield takeEvery(getFiatTransfersRequest, getFiatTransfersWorker);
	yield takeEvery(getCryptoDepositsRequest, getCryptoDepositsWorker);
	yield takeLatest(getCryptoTransactionsRequest, getCryptoTransactionsWorker);
	yield takeLatest(getFiatTransactionsRequest, getFiatTransactionsWorker);
	yield takeLatest(putFiatTransactionsRequest, putFiatTransactionsWorker);
	yield takeLatest(putCryptoWithdrawalApproveRequest, putCryptoWithdrawalApproveWorker);
	yield takeLatest(putCryptoWithdrawalRejectRequest, putCryptoWithdrawalRejectWorker);
	yield takeLatest(putCryptoNoteRequest, putCryptoNoteWorker);
	yield takeLatest(postFiatDepositRequest, postFiatDepositWorker);
	yield takeLatest(getPooledDepositsRequest, getPooledDepositsWorker);
	yield takeLatest(getPooledDepositsFileRequest, getPooledDepositsFileWorker);
	yield takeLatest(getCryptoWithdrawalLimitsRequest, getCryptoWithdrawalLimitsWorker);
	yield takeLatest(getFiatWithdrawalLimitsRequest, getFiatWithdrawalLimitsWorker);
	yield takeLatest(putWithdrawalLimitsRequest, putWithdrawalLimitsWorker);
	yield takeLatest(getGeneralWithdrawalLimitRequest, getGeneralWithdrawalLimitWorker);
	yield takeLatest(putGeneralWithdrawalLimitRequest, putGeneralWithdrawalLimitWorker);
	yield takeLatest(getTradesRequest, getTradesWorker);
	yield takeLatest(getTradesFileRequest, getTradesFileWorker);
	yield takeLatest(getReferralTradesFileRequest, getReferralTradesFileWorker);
	yield takeLatest(getTradesTransfersCryptoFileRequest, getTradesTransfersCryptoFileWorker);
	yield takeLatest(getTradingLimitsCryptoRequest, getTradingLimitsCryptoWorker);
	yield takeLatest(getTradingLimitsFiatRequest, getTradingLimitsFiatWorker);
	yield takeLatest(putTradingLimitRequest, putTradingLimitWorker);
	yield takeLatest(getCryptoTransactionFileRequest, getCryptoTransactionFileWorker);
	yield takeLatest(getFiatTransactionFileRequest, getFiatTransactionFileWorker);
	yield takeLatest(getCryptoTransfersFileRequest, getCryptoTransfersFileWorker);
	yield takeLatest(getFiatTransfersFileRequest, getFiatTransfersFileWorker);
	yield takeLatest(getProfitWalletsRequest, getProfitWalletsWorker);
	yield takeLatest(getProfitWalletsFileRequest, getProfitWalletsFileWorker);
	yield takeLatest(getPendingDepositRequest, getPendingDepositWorker);
	yield takeLatest(putPendingDepositRejectRequest, putPendingDepositRejectWorker);
	yield takeLatest(putPendingDepositApproveRequest, putPendingDepositApproveWorker);
	yield takeLatest(putPendingDepositNoteRequest, putPendingDepositNoteWorker);
	yield takeLatest(getPendingWithdrawalRequest, getPendingWithdrawalWorker);
	yield takeLatest(getPendingWithdrawalFileRequest, getPendingWithdrawalFileWorker);
	yield takeLatest(getPendingDepositFileRequest, getPendingDepositFileWorker);
	yield takeLatest(putPendingWithdrawalApproveRequest, putPendingWithdrawalApproveWorker);
	yield takeLatest(putPendingWithdrawalRejectRequest, putPendingWithdrawalRejectWorker);
	yield takeLatest(putPendingWithdrawalCancelRequest, putPendingWithdrawalCancelWorker);
	yield takeLatest(putPendingWithdrawalNoteRequest, putPendingWithdrawalNoteWorker);
	yield takeLatest(getFiatDepositsRequest, getFiatDepositsWorker);
	yield takeLatest(putPendingWithdrawalStatusRequest, putPendingWithdrawalStatusRequestWorker);
	yield takeLatest(putPendingWithdrawalCryptoRejectRequest, putPendingWithdrawalCryptoRejectWorker);
	yield takeLatest(
		putPendingWithdrawalCryptoApproveRequest,
		putPendingWithdrawalCryptoApproveWorker,
	);
	yield takeEvery(addDepositCryptoRequest, addDepositCryptoWorker);
	yield takeEvery(addWithdrawalFiatRequest, addWithdrawalFiatWorker);
	yield takeEvery(addWithdrawalCryptoRequest, addWithdrawalCryptoWorker);
	yield takeEvery(addTradeRequest, addTradeWorker);
	yield takeLatest(getLedgerTransactionsRequest, getLedgerTransactionsWorker);
}
