import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { EPaymentMethodTab } from './types';
import Crypto from './Crypto/Crypto';
import Fiat from './Fiat/Fiat';
import {
	getPermissions,
	getSecretTokenForFileDownload,
} from '../../../redux/reducers/auth/selectors';

const DepositWallets = () => {
	const [activeTab, setActiveTab] = useState<EPaymentMethodTab>(EPaymentMethodTab.CRYPTO);
	const key = useSelector(getSecretTokenForFileDownload);
	const dispatch = useDispatch();
	const permission = useSelector(getPermissions)?.[EPermissionNames.WALLET_MANAGEMENT].editable;
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <Crypto permission={!!permission} />;
			case EPaymentMethodTab.FIAT:
				return <Fiat permission={!!permission} />;
			default:
				return <Crypto permission={!!permission} />;
		}
	};
	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	// const setToFiatTab = () => {
	// 	setActiveTab(EPaymentMethodTab.FIAT);
	// };

	return (
		<div className="user-management-body user-management-body--type2">
			<div className="currencyButtonFile">
				<div className="tabs-buttons tabs-buttons--liquidity tabs-buttons--wallets">
					<button
						type="button"
						className={activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''}
						onClick={setToCryptoTab}
					>
						Crypto
					</button>
					{/* <button */}
					{/*	type="button" */}
					{/*	className={`button button--size4 button--type3 ${ */}
					{/*		activeTab === EPaymentMethodTab.FIAT ? 'active' : '' */}
					{/*	}`} */}
					{/*	onClick={setToFiatTab} */}
					{/* > */}
					{/*	Fiat */}
					{/* </button> */}
				</div>
				<div className="fileButton" />
			</div>
			{chooseTab()}
		</div>
	);
};
export default DepositWallets;
