// eslint-disable import/namespace
import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';
import {
	getFiatDepositsList,
	getFiatTransactions,
	getFiatTransactionsLoading,
} from 'redux/reducers/transactions/selectors';
import IconSvg from 'ui/Svg/IconSvg';
import { getFiatFilters } from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { EApiTransactionType, IApiGetTransactionParams } from 'services/api/transactions/types';
import {
	getFiatDepositsRequest,
	getFiatTransactionFileRequest,
	getFiatTransactionsRequest,
} from 'redux/reducers/transactions/reducer';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { CSVFileIcon, XLSFileIcon } from 'assets/inline-svg';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import AddFiatDepositTransaction from 'layouts-elements/PopUp/AddFiatDepositTransaction/AddFiatDepositTransaction';
import { getAdminId, getPermissions } from 'redux/reducers/auth/selectors';
import { getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import { THistoryFiltersOption } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import CreateDepositAccountPopUp from 'layouts-elements/PopUp/CreateDepositAccount/CreateDepositAccount';
import { EPermissionNames } from 'redux/reducers/auth/types';
import ActiveReportsFilters from 'ui/ActiveFilters/ActiveReportsFilters';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { STATUS_FIAT_FILTER_OPTIONS_V2 } from 'redux/reducers/transactionsFilters/constants';
import FiatTransactionsHeader from './FiatTransactionsHeader';
import FiatTransactionsRow from './FiatTransactionsRow';
import { ITransactionsTab, ITransactionsTableSortedParams } from '../types';
import Loading from '../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
	{ name: 'Bank Name', value: ETransactionsFilters.REQUISITE_NAME },
];

const FiatTransactionsWithdrawalTable: FC<ITransactionsTab> = ({ type }) => {
	const dispatch = useDispatch();
	const activeFilters = useSelector(getFiatFilters);
	const adminId = useSelector(getAdminId) || 1;
	const fiatTransactions = useSelector(getFiatTransactions);
	// const fiatTransactions = useSelector(getFiatDepositsList);
	const fiatTransactionsLoading = useSelector(getFiatTransactionsLoading);
	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;
	const [reset, setReset] = useState(false);

	const [addNewTransactionPopup, setAddNewTransactionPopup] = useState(false);
	const handleOpenAddNewTransactionPopup = () => {
		setAddNewTransactionPopup(true);
	};
	const handleCloseAddNewTransactionPopup = () => {
		setAddNewTransactionPopup(false);
	};

	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const [currentPage, setCurrentPage] = useState(fiatTransactions?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setPerPage(value || fiatTransactions?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const pageCount = fiatTransactions?.last_page ? fiatTransactions.last_page : 1;

	const [createDepositOpen, setCreateDepositOpen] = useState(false);

	const handleCloseCreateDeposit = () => {
		setCreateDepositOpen(false);
	};
	const handleOpenCreateDeposit = () => {
		setCreateDepositOpen(true);
	};

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fiatTransactions?.last_page]);

	useLayoutEffect(() => {
		setSorted(null);
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_CRYPTO));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	useLayoutEffect(() => {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);
		const params: IApiGetTransactionParams = {
			type: type === EApiTransactionType.TRANSFER ? undefined : type,
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
			...sortedParamsObj,
		};

		dispatch(getFiatTransactionsRequest(params));
		// dispatch(getFiatDepositsRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, perPage, currentPage, sorted]);

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.TRANSACTIONS_FIAT }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		setReset(true);
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT));
	};

	const handleGetCsvFile = () => {
		dispatch(getFiatTransactionFileRequest({ type, fileType: 'csv', adminId }));
	};
	const handleGetXlsxFile = () => {
		dispatch(getFiatTransactionFileRequest({ type, fileType: 'xlsx', adminId }));
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_FIAT,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_FIAT,
				}),
			);
		}
	};

	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const fiatCurrencyFilterOptions = fiatCurrencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);

		dispatch(
			addFilter({
				type: ETransactionsFilters.ASSET_ID,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);

		dispatch(
			addFilter({
				type: ETransactionsFilters.STATUS,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	return (
		<>
			{/* <SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
				/> */}
			{/* {!!permission && type === EApiTransactionType.DEPOSIT && (
					<button
						type="button"
						onClick={handleOpenAddNewTransactionPopup}
						className="button button--wide button--size2"
					>
						Add New Transaction
					</button>
				)} */}
			{/* <button type="button" className="filters__download-button" onClick={handleGetCsvFile}>
					<CSVFileIcon />
					Download
				</button>
				<button type="button" className="filters__download-button" onClick={handleGetXlsxFile}>
					<XLSFileIcon />
					Download
				</button> */}

			<ActiveReportsFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={fiatCurrencyFilterOptions}
				handleStatusChange={handleStatusChange}
				statusOptions={STATUS_FIAT_FILTER_OPTIONS_V2}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				reset={reset}
			/>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div
						className={cn('table table--type2', {
							'table--transaction-fiat': type === EApiTransactionType.DEPOSIT,
							'table--transaction-fiat-transfer': type === EApiTransactionType.TRANSFER,
							'table--transaction-fiat-withdrawal': type === EApiTransactionType.WITHDRAWAL,
						})}
					>
						<div className="table-header">
							<FiatTransactionsHeader type={type} setSorted={setSorted} />
						</div>
						<div
							className={`table-body 
								${fiatTransactions?.data.length === 4 ? 'table-body--four-elements' : ''}
								${fiatTransactions?.data.length === 3 ? 'table-body--three-elements' : ''}
								${fiatTransactions?.data.length === 2 ? 'table-body--two-elements' : ''}
								${fiatTransactions?.data.length === 1 ? 'table-body--one-elements' : ''}
							`}
						>
							{!fiatTransactionsLoading && (
								<>
									{fiatTransactions?.data.length ? (
										fiatTransactions?.data.map((item: any) => (
											<FiatTransactionsRow
												isAllTypes={type === EApiTransactionType.TRANSFER}
												key={item.id}
												permission={!!permission}
												{...item}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}

							{fiatTransactionsLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!fiatTransactions?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!fiatTransactionsLoading && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
							<div className="button-wrap">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--csv" onClick={handleGetCsvFile} />
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--xlsx" onClick={handleGetXlsxFile} />
							</div>
						</div>
					</div>
				)}
			</div>

			<AddFiatDepositTransaction
				closeModal={handleCloseAddNewTransactionPopup}
				open={addNewTransactionPopup}
				buttonClick={handleOpenCreateDeposit}
			/>
		</>
	);
};

export default FiatTransactionsWithdrawalTable;
