/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useMemo } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { numberInputFix } from 'services/utils/numberInputFix';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IUserCreateTransactionLimit } from 'redux/reducers/users/types';

export interface IFeeAssetItem {
	asset_id: number;
	limit_min: number;
	limit_max: number;
}

export interface IFeeManagementDepositItem {
	el: IUserCreateTransactionLimit;
	updateHandler: (obj: IUserCreateTransactionLimit) => void;
}

const TransferLimitsTableRow: FC<IFeeManagementDepositItem> = ({ el, updateHandler }) => {
	const { asset_id, daily, hourly, maximum, minimum } = el;
	const [changeableMode, setChangeableMode] = useState(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		minimum: minimum || null || '',
		maximum: maximum || null || '',
		hourly: hourly || null || '',
		daily: daily || null || '',
	};

	const currencyData = useSelector(getCurrencyData);

	const filterCurrency = useMemo(() => {
		return currencyData?.find((item) => item.id === asset_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyData]);

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_id,
							minimum: Number(values?.minimum),
							maximum: Number(values?.maximum),
							hourly: Number(values?.hourly),
							daily: Number(values?.daily),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<div className="td__wrap">
									<img
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										src={`/img/currencies/${filterCurrency?.code}.svg`}
										alt={filterCurrency?.code}
									/>
									<p>{filterCurrency?.code?.toUpperCase() || '-'}</p>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Minimum</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											onKeyDown={(evt) => numberInputFix(evt)}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props?.values?.minimum}
											onWheel={(event) => event.currentTarget.blur()}
											name="minimum"
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Maximum</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											onKeyDown={(evt) => numberInputFix(evt)}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props?.values?.maximum}
											onWheel={(event) => event.currentTarget.blur()}
											name="maximum"
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Hourly Limit</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											onKeyDown={(evt) => numberInputFix(evt)}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props?.values?.hourly}
											onWheel={(event) => event.currentTarget.blur()}
											name="hourly"
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Daily Limit</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											onKeyDown={(evt) => numberInputFix(evt)}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											value={props?.values?.daily}
											onWheel={(event) => event.currentTarget.blur()}
											name="daily"
											type="number"
											placeholder="0"
										/>
									</div>
								</div>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<div className="td__wrap">
							{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
							<img src={`/img/currencies/${filterCurrency?.code}.svg`} alt={filterCurrency?.code} />
							<p>{filterCurrency?.code?.toUpperCase() || '-'}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Minimum</p>
						<div className="td__wrap">
							<p>{roundingNumber(minimum, filterCurrency?.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maximum</p>
						<div className="td__wrap">
							<p>{roundingNumber(maximum, filterCurrency?.code)}</p>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Hourly Limit</p>
						<div className="td__wrap">
							{filterCurrency && <p>{roundingNumber(hourly, filterCurrency.code)}</p>}
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Daily Limit</p>
						<div className="td__wrap">
							{filterCurrency && <p>{roundingNumber(daily, filterCurrency.code)}</p>}
						</div>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								onClick={switchOnChangeableMode}
								className="btn--icon btn--edit"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TransferLimitsTableRow;
