import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getTradeHistoryRequest } from 'redux/reducers/transactions/reducer';
import { getTradeHistory, getTradeHistoryPageCount } from 'redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from 'redux/reducers/transactions/types';
import IconSvg from 'ui/Svg/IconSvg';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import { ITransactionsTableSortedParams } from 'components/TransfersAndTradeReports/types';
import { ETradeSortedFields } from 'services/api/transactions/types';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import ActiveTradeFilters from 'ui/ActiveFilters/ActiveTradeFilters';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { getTradesFilters } from 'redux/reducers/transactionsFilters/selectors';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import PerPageDropdown from 'services/utils/PaginationPerPageDropdown';
import TradeHistoryRow from './TradeHistoryRow';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import { THistoryFiltersOption } from '../CryptoHistory/types';
import FiatHistoryRow from '../../TransferHistoryTab/FiatHistory/FiatHistoryRow';
import Loading from '../../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [{ name: 'Tx ID', value: ETransactionsFilters.TX_ID }];

const TradeHistory = () => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();

	const tradeHistory = useSelector(getTradeHistory);
	const pageCount = useSelector(getTradeHistoryPageCount);
	const currency = useSelector(getCurrencyData);
	const activeFilters = useSelector(getTradesFilters);

	const [currentPage, setCurrentPage] = useState(1);
	const [firstLoading, setFirstLoading] = useState(true);

	const [quantitySorted, setQuantitySorted] = useState<TSortedDirection>('');
	const [amountSorted, setAmountSorted] = useState<TSortedDirection>('');
	const [rateSorted, setRateSorted] = useState<TSortedDirection>('');
	const [feeSorted, setFeeSorted] = useState<TSortedDirection>('');
	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [reset, setReset] = useState(false);
	const [itemsPerPage, setItemsPerPage] = useState<number | string>(10);

	const onSortedQuantity = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.QUANTITY, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setQuantitySorted(direction);
		setAmountSorted('');
		setRateSorted('');
		setFeeSorted('');
	};
	const onSortedAmount = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.AMOUNT, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setAmountSorted(direction);
		setQuantitySorted('');
		setRateSorted('');
		setFeeSorted('');
	};

	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setFeeSorted(direction);
		setQuantitySorted('');
		setAmountSorted('');
		setRateSorted('');
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	useLayoutEffect(() => {
		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);
		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				userId,
				current_page: currentPage,
				per_page: Number(itemsPerPage),
				...sortedParamsObj,
				...filterParams,
				status: 'completed',
			},
			onFinally: () => setFirstLoading(false),
		};
		dispatch(getTradeHistoryRequest(params));
	}, [currentPage, dispatch, userId, sorted, activeFilters, itemsPerPage]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
	}, [dispatch]);

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		setReset(true);
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_TRADES));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};
	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_TRADES,
			}),
		);
	};
	const currencyFilterOptions = currency.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));
	const selectChangeCurrencySell = ({ value, name }: THistoryFiltersOption) => {
		setReset(false);
		if (!value) {
			dispatch(
				deleteFilter({
					type: ETransactionsFilters.SELL,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					name,
					value: name,
					type: ETransactionsFilters.SELL,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};
	const selectChangeCurrencyBuy = ({ value, name }: THistoryFiltersOption) => {
		setReset(false);
		if (!value) {
			dispatch(
				deleteFilter({
					type: ETransactionsFilters.BUY,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		} else {
			dispatch(
				addFilter({
					name,
					value: name,
					type: ETransactionsFilters.BUY,
					filterType: EFiltersType.TRANSACTIONS_TRADES,
				}),
			);
		}
	};

	const handleChangePerPage = (value: string | number) => {
		setCurrentPage(Math.ceil(tradeHistory.from / Number(value)));
		setItemsPerPage(Number(value));
	};

	return (
		<div className="trade-history-wrapper">
			<ActiveTradeFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				currencyOptions={currencyFilterOptions}
				handleChangeCurrencySell={selectChangeCurrencySell}
				handleChangeCurrencyBuy={selectChangeCurrencyBuy}
				reset={reset}
			/>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--transaction-history-trade">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Timestamp</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Sell</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Buy</p>
									</div>
								</div>
								<div className="td td--right">
									<SortedButton
										direction={quantitySorted}
										title="Amount Sold"
										handleSort={onSortedQuantity}
									/>
								</div>
								<div className="td td--right">
									<SortedButton
										direction={amountSorted}
										title="Amount Received"
										handleSort={onSortedAmount}
									/>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Exchange Rate</p>
									</div>
								</div>
								<div className="td td--right">
									<SortedButton direction={feeSorted} title="Fee" handleSort={onSortedFee} />
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Tx ID</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!firstLoading && (
								<>
									{' '}
									{tradeHistory?.data?.length ? (
										tradeHistory?.data?.map((item: any) => (
											<TradeHistoryRow key={item.id} item={item} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{firstLoading ? <Loading /> : null}
						</div>
						<div className="table-footer table-footer--type2">
							<div className="pagination-block pagination-block--full">
								<PerPageDropdown
									options={[10, 20, 50, 100]}
									setPerPage={handleChangePerPage}
									perPage={Number(itemsPerPage)}
								/>
								{!firstLoading && pageCount > 1 && (
									<ReactPaginate
										breakLabel="..."
										pageCount={pageCount}
										onPageChange={handlePageClick}
										forcePage={currentPage - 1}
										className="pagination"
										activeClassName="active"
										previousClassName="pagination__arrow pagination__arrow--prev"
										nextClassName="pagination__arrow pagination__arrow--next"
										previousLabel=""
										nextLabel=""
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradeHistory;
