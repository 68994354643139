/* eslint-disable @typescript-eslint/naming-convention */
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletBalancesApi } from './types';
// ==========================================:
export const walletBalances: IWalletBalancesApi = {
	getAssets: () =>
		http.get(endpoint.global.GET_GLOBAL_ASSETS()).then((response) => {
			return response.data;
		}),
	getWalletBalancesLiquidity: (params) =>
		http
			.get(endpoint.walletBalances.GET_LIQUIDITY(params.currency), { params })
			.then((response) => {
				return response.data;
			}),
	// new api
	// getWalletBalancesLiquidityDashboard: (params) =>
	// 	http
	// 		.get(endpoint.walletBalances.GET_LIQUIDITY_DASHBOARD(params.currency), { params })
	// 		.then((response) => {
	// 			return response.data;
	// 		}),
	//
	getWalletBalancesLiquidityReport: ({ fileName, key, currency }: any) =>
		http
			.get(endpoint.walletBalances.GET_LIQUIDITY_REPORT(fileName, key, currency))
			.then((response) => {
				return response.data;
			}),
	getWalletBalancesPendingFeesReport: ({ fileName, key, currency }: any) =>
		http
			.get(endpoint.walletBalances.GET_PENDING_FEES_REPORT(fileName, key, currency))
			.then((response) => {
				return response.data;
			}),
	getWalletBalancesPlatformBalances: (params) =>
		http
			.get(endpoint.walletBalances.GET_PLATFORM_BALANCES(params.currency), { params })
			.then((response) => {
				return response.data;
			}),
	getWalletBalancesDepositWallets: (params) =>
		http
			.get(endpoint.walletBalances.GET_DEPOSIT_WALLETS(params.currency), { params })
			.then((response) => {
				return response.data;
			}),
	getWalletBalancesPendingFees: (params) =>
		http
			.get(endpoint.walletBalances.GET_PENDING_FEES(params.currency), { params })
			.then((response) => {
				return response.data;
			}),
	// User
	getWalletBalancesPlatformBalancesUser: (params) =>
		http
			.get(endpoint.walletBalances.GET_PLATFORM_BALANCES_USER(params.currency), { params })
			.then((response) => {
				return response.data;
			}),
	getWalletBalancesDepositWalletsUser: (params) =>
		http.get(endpoint.walletBalances.GET_DEPOSIT_WALLETS_USER(), { params }).then((response) => {
			return response.data;
		}),
	// Fiat
	getWalletBalancesDepositWalletsUserFiat: (params) =>
		http
			.get(endpoint.walletBalances.GET_DEPOSIT_WALLETS_USER_FIAT(), { params })
			.then((response) => {
				return response.data;
			}),
	//
	getDepositAccounts: (payload) =>
		http.get(endpoint.walletBalances.GET_DEPOSIT_ACCOUNTS, { params: payload }).then((response) => {
			return response.data;
		}),
	getUserDepositAccounts: (payload) =>
		http
			.get(endpoint.walletBalances.GET_USER_DEPOSIT_ACCOUNTS(payload.userId), {
				params: payload.params,
			})
			.then((response) => {
				return response.data;
			}),
	updateDepositAccount: (payload) =>
		http.put(endpoint.walletBalances.UPDATE_DEPOSIT_ACCOUNT, payload).then((response) => {
			return response.data;
		}),
	createDepositAccount: (payload) =>
		http.post(endpoint.walletBalances.CREATE_DEPOSIT_ACCOUNT, payload).then((response) => {
			return response.data;
		}),
	deleteDepositAccount: (payload) =>
		http
			.delete(endpoint.walletBalances.DELETE_DEPOSIT_ACCOUNT, { data: payload })
			.then((response) => {
				return response.data;
			}),
	// buttons
	// Deposit wallets PAGE
	postButtonsDepositsWalletsCrypto: (payload: any) =>
		http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_DEPOSIT_WALLETS_CRYPTO, { ...payload })
			.then((response) => {
				return response.data;
			}),
	// pending-fees PAGE
	postButtonsPendingFeesCrypto: (payload: any) =>
		http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_PENDING_FEES_CRYPTO, { ...payload })
			.then((response) => {
				return response.data;
			}),
	postButtonsPendingFeesFiat: (payload: any) =>
		http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_PENDING_FEES_FIAT, { ...payload })
			.then((response) => {
				return response.data;
			}),
	// user-balances PAGE
	postButtonsUserBalancesAll: (payload: any) =>
		http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_USER_BALANCES_ALL, { ...payload })
			.then((response) => {
				return response.data;
			}),
	postButtonsUserBalancesTotals: (payload: any) =>
		http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_USER_BALANCES_TOTALS, { ...payload })
			.then((response) => {
				return response.data;
			}),
	postButtonsUserBalancesWallets: (payload: any) => {
		return http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_USER_BALANCES_WALLETS(payload?.user_id), {
				...payload,
			})
			.then((response) => {
				return response.data;
			});
	},
	postButtonsUserBalances: (payload: any) => {
		const { chain_id, asset_id, totp } = payload;

		return http
			.post(endpoint.walletBalances.buttons.POST_BUTTONS_USER_BALANCES(payload?.userId), {
				chain_id,
				asset_id,
				totp,
			})
			.then((response) => {
				return response.data;
			});
	},
};
