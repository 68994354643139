import { FC, useLayoutEffect, useState } from 'react';
import { EPaymentMethodTab } from './types';
import PaymentMethodCrypto from './PaymentMethodCrypto/PaymentMethodCrypto';
import PaymentMethodFiat from './PaymentMethodFiat/PaymentMethodFiat';

export interface IPaymentMethodTab {
	setUpdatingBankHandler?: (id: number) => void;
	paymentMethodActiveTab?: string;
	setPaymentMethodActiveTab?: React.Dispatch<React.SetStateAction<EPaymentMethodTab>>;
}

const PaymentMethodTab: FC<IPaymentMethodTab> = ({
	setUpdatingBankHandler,
	paymentMethodActiveTab,
	setPaymentMethodActiveTab,
}) => {
	const [activeTab, setActiveTab] = useState<any>(EPaymentMethodTab.CRYPTO);
	const chooseTab = () => {
		switch (activeTab) {
			case EPaymentMethodTab.CRYPTO:
				return <PaymentMethodCrypto />;
			case EPaymentMethodTab.FIAT:
				return <PaymentMethodFiat setUpdatingBankHandler={setUpdatingBankHandler} />;
			default:
				return <PaymentMethodCrypto />;
		}
	};

	const setToCryptoTab = () => {
		setActiveTab(EPaymentMethodTab.CRYPTO);
	};
	const setToFiatTab = () => {
		setActiveTab(EPaymentMethodTab.FIAT);
	};

	useLayoutEffect(() => {
		setActiveTab(paymentMethodActiveTab);
		return () => {
			setPaymentMethodActiveTab && setPaymentMethodActiveTab(EPaymentMethodTab.CRYPTO);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentMethodActiveTab]);

	return (
		<div className="beneficiaries">
			<div className="tabs-buttons tabs-buttons--mb-0 tabs-buttons---flex">
				<button
					type="button"
					className={`button button--size4 button--type3 ${
						activeTab === EPaymentMethodTab.CRYPTO ? 'active' : ''
					}`}
					onClick={setToCryptoTab}
				>
					Crypto
				</button>
				<button
					type="button"
					className={`button button--size4 button--type3 ${
						activeTab === EPaymentMethodTab.FIAT ? 'active' : ''
					}`}
					onClick={setToFiatTab}
				>
					Fiat
				</button>
			</div>
			{chooseTab()}
		</div>
	);
};
export default PaymentMethodTab;
