import { Route, Switch, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import LimitsTab from './LimitsTable';
import UserTableTab from './UserTable';
import TradeManagementTab from './TradeManagementTab/TradeManagementTab';
import FeeManagementTab from './FeeManagementTab/FeeManagementTab';
import EmailsTab from './EmailsTab';
import CurrenciesFee from './Currencles/CurrenclesFee';
import ReferralManagement from './ReferralManagement/ReferralManagement';
import TransferLimitsTab from './TransferLimits';

const CreateUserPage = () => {
	const { url } = useRouteMatch();

	return (
		<>
			<div className="user-management-wrap user-management-wrap--create-account">
				<div className="user-management__heading">Create Account</div>
				<div className="tabs-account mb-30">
					<NavLink to={`${url}/userInfo`}>User Information</NavLink>
					<NavLink to={`${url}/limits`}>Trade Limits</NavLink>
					<NavLink to={`${url}/transfer-limits`}>Transfer Limits</NavLink>
					<NavLink to={`${url}/trade-management`}>Liquidity Provider</NavLink>
					<NavLink to={`${url}/fee-management`}>Fee Management</NavLink>
					<NavLink to={`${url}/currencies-fee`}>Currencies</NavLink>
					<NavLink to={`${url}/emails`}>Emails</NavLink>
					<NavLink to={`${url}/referral-management`}>Referral Management</NavLink>
				</div>
				<Switch>
					<Route exact path={`${url}/userInfo`} component={UserTableTab} />
					<Route path={`${url}/limits`} component={LimitsTab} />
					<Route path={`${url}/transfer-limits`} component={TransferLimitsTab} />
					<Route path={`${url}/trade-management`} component={TradeManagementTab} />
					<Route path={`${url}/fee-management`} component={FeeManagementTab} />
					<Route path={`${url}/currencies-fee`} component={CurrenciesFee} />
					<Route path={`${url}/emails`} component={EmailsTab} />
					<Route path={`${url}/referral-management`} component={ReferralManagement} />
				</Switch>
			</div>
		</>
	);
};

export default CreateUserPage;
