import { IAccountDetailsParams } from 'components/AccountDetails/types';
import ReferralTable from 'components/tables/ReferralTable';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUserReferralRequest } from 'redux/reducers/users/reducer';
import {
	getOneUser,
	getUserReferral,
	getUserReferralLoader,
	getUserReferralsPageCount,
} from 'redux/reducers/users/selectors';

import ReferralManagement from '../ReferralManagement/ReferralManagement';

const ReferralTabView = () => {
	const { userId } = useParams<IAccountDetailsParams>();

	const dispatch = useDispatch();
	const referralsList = useSelector(getUserReferral);
	const loader = useSelector(getUserReferralLoader);

	const [currentPage, setCurrentPage] = useState(1);

	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [showReferralList, setShowReferralList] = useState(true);

	useLayoutEffect(() => {
		dispatch(getUserReferralRequest({ userId, current_page: currentPage, per_page: itemsPerPage }));
	}, [dispatch, userId, currentPage, itemsPerPage]);

	const handleItemsPerPage = (value: string | number, deleting = false) => {
		deleting
			? setCurrentPage(1)
			: setCurrentPage(Math.ceil(Number(referralsList?.from) / Number(value)));
		setItemsPerPage(Number(value));
	};

	return (
		<div className="user-management">
			{/* <div className="currencyButtonFile mt-20 mb-20">
				<div className="currencyButton">
					<button
						type="button"
						className={showReferralList ? 'active' : ''}
						onClick={handleSwitchLists}
					>
						Referrals
					</button>
					<button
						type="button"
						className={showReferralList ? '' : 'active'}
						onClick={handleSwitchLists}
					>
						Referral Fee
					</button>
				</div>
			</div> */}
			{showReferralList ? (
				<div>
					<ReferralTable
						referralsList={referralsList}
						loading={loader}
						perPage={itemsPerPage}
						setCurrentPage={setCurrentPage}
						setItemsPerPage={handleItemsPerPage}
						currentPage={currentPage}
					/>
				</div>
			) : (
				<ReferralManagement />
			)}
		</div>
	);
};

export default ReferralTabView;
