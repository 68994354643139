/* eslint-disable @typescript-eslint/naming-convention */

import React, { ChangeEvent, FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { setFireblockIdRequest } from 'redux/reducers/users/reducer';
import { getErrorMsg } from 'redux/reducers/errors/selectors';
import { getFireblockIdLoading } from 'redux/reducers/users/selectors';
import { setErrorMsg } from 'redux/reducers/errors/reducer';
import { IPopUp } from '../types';

const SecurityVerificationPopUp: FC<IPopUp & IPopUpData> = ({ open, closeModal, payload }) => {
	const [totp, setTotp] = useState('');
	const dispatch = useDispatch();

	const { userId, fireblocks_vault_id } = payload;

	const errorMessage = useSelector(getErrorMsg);
	const loading = useSelector(getFireblockIdLoading);

	const [error, setError] = useState<string>('');

	useLayoutEffect(() => {
		if (errorMessage && errorMessage === 'invalid_totp_code') {
			setError('Your 2FA code is incorrect.');
		}
		return () => {
			dispatch(setErrorMsg(''));
		};
	}, [errorMessage, dispatch]);
	const handleConfirm = () => {
		if (totp.length === 0) {
			setError('Please enter 2FA code.');
			return;
		}
		if (error) {
			return;
		}
		dispatch(setFireblockIdRequest({ userId, data: { fireblocks_vault_id, totp }, closeModal }));
	};
	const handleTotpChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError('');
		dispatch(setErrorMsg(''));
		const { value } = e.target;
		if (value.length === 0) {
			setError('Please enter 2FA code.');
		}
		if (value.length > 6) {
			return;
		}
		if (/^[0-9]*$/.test(value)) {
			setTotp(value);
		}
	};

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header">
					<p className="popup-header__title">Security Verification</p>
				</div>
				<div className="popup-body">
					<div className="input">
						<p className="input__name">Authentication Code</p>
						<div className="input-wrapper">
							<input
								onChange={handleTotpChange}
								value={totp}
								className={`input-item ${error ? 'error-border' : ''}`}
								type="text"
								placeholder="Enter 2FA Code"
								disabled={loading}
							/>
							{error && <span className="error-red">{error}</span>}
						</div>
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button
							type="button"
							onClick={handleConfirm}
							disabled={loading}
							className="btn btn-primary btn--full"
						>
							Confirm
						</button>
						<button
							onClick={closeModal}
							type="button"
							className="btn-link btn--full justify-content-center"
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SecurityVerificationPopUp;
