import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import {
	getProfitWalletsFileRequest,
	getProfitWalletsRequest,
} from 'redux/reducers/transactions/reducer';
import { getProfitWalletFilters } from 'redux/reducers/transactionsFilters/selectors';
import { getProfitWallets, getProfitWalletsLoading } from 'redux/reducers/transactions/selectors';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import { CSVFileIcon, XLSFileIcon } from 'assets/inline-svg';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import IconSvg from 'ui/Svg/IconSvg';
import ReportsFilters from 'ui/ActiveFilters/ReportsFilters';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { THistoryFiltersOption } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { STATUS_PROFIT_WALLET_FILTER_OPTIONS } from 'redux/reducers/transactionsFilters/constants';
import { ITransactionsTableSortedParams } from '../types';
import ProfitWalletTableRow from './ProfitWalletTableRow/ProfitWalletTableRow';
import ProfitWalletTableHeader from './ProfitWalletTableHeader/ProfitWalletTableHeader';
import Loading from '../../../layouts-elements/Loading/Loading';

const FiltersSearchArray = [{ name: 'User ID', value: ETransactionsFilters.ADMIN_ID }];

const ProfitWalletTable = () => {
	const dispatch = useDispatch();
	const profitWallets = useSelector(getProfitWallets);
	const profitWalletsLoading = useSelector(getProfitWalletsLoading);
	const activeFilters = useSelector(getProfitWalletFilters);

	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);
	const [currentPage, setCurrentPage] = useState(profitWallets?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const [reset, setReset] = useState(false);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || profitWallets?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = profitWallets?.last_page ? profitWallets.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profitWallets?.last_page]);

	useLayoutEffect(() => {
		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
			...sortedParamsObj,
		};

		dispatch(getProfitWalletsRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sorted, activeFilters, currentPage, perPage]);

	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_PROFIT_WALLET));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(
			deleteFilter({ type: filterType, filterType: EFiltersType.TRANSACTIONS_PROFIT_WALLET }),
		);
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		setReset(true);
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_PROFIT_WALLET));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_PROFIT_WALLET,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_PROFIT_WALLET,
				}),
			);
		}
	};

	const handleDownloadCsvFile = () => {
		dispatch(getProfitWalletsFileRequest({ fileType: 'csv' }));
	};

	const handleDownloadXlsxFile = () => {
		dispatch(getProfitWalletsFileRequest({ fileType: 'xlsx' }));
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_PROFIT_WALLET,
			}),
		);
	};

	const currencyData = useSelector(getCurrencyData);
	const currencyFilterOptions = currencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.ASSET_ID,
				filterType: EFiltersType.TRANSACTIONS_PROFIT_WALLET,
			}),
		);
	};

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.STATUS,
				filterType: EFiltersType.TRANSACTIONS_PROFIT_WALLET,
			}),
		);
	};
	return (
		<>
			{/* <SearchWithSelect
					resetValue={resetSearchValue}
					selectArr={FiltersSearchArray}
					handleSearch={handleSearch}
				/>
				<button type="button" className="filters__download-button" onClick={handleDownloadCsvFile}>
					<CSVFileIcon />
					Download
				</button>
				<button type="button" className="filters__download-button" onClick={handleDownloadXlsxFile}>
					<XLSFileIcon />
					Download
				</button> */}

			<ReportsFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={currencyFilterOptions}
				handleStatusChange={handleStatusChange}
				statusOptions={STATUS_PROFIT_WALLET_FILTER_OPTIONS}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				reset={reset}
			/>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--type2 table--transactions-profit-wallet">
						<div className="table-header">
							<ProfitWalletTableHeader setSorted={setSorted} />
						</div>
						<div className="table-body">
							{!profitWalletsLoading && (
								<>
									{' '}
									{profitWallets?.data.length ? (
										profitWallets?.data.map((item) => (
											<ProfitWalletTableRow key={item.id} {...item} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{profitWalletsLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!profitWallets?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!profitWalletsLoading && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
						</div>
						<div className="button-wrap">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button type="button" className="btn btn--csv" onClick={handleDownloadCsvFile} />
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button type="button" className="btn btn--xlsx" onClick={handleDownloadXlsxFile} />
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default ProfitWalletTable;
