import ResetUserPasswordItem from 'components/ResetRequest/ResetPasswordItem';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import {
	getAdminResetPasswordsRequestsLoading,
	getRequestsLoading,
	getUsersPasswordResetRequests,
} from 'redux/reducers/users/selectors';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import PerPageDropdown from '../../../../../services/utils/PaginationPerPageDropdown';
import Item from '../../../../../components/WalletBalance/PlatformBalance/Fiat/Item/Item';
import Loading from '../../../../../layouts-elements/Loading/Loading';

interface IUsersTableProps {
	handlePageChange: any;
	setItemsPerPageHandler: any;
	itemsPerPage: number;
	page: number;
}

const UserPasswordTab: FC<IUsersTableProps> = ({
	handlePageChange,
	setItemsPerPageHandler,
	itemsPerPage,
	page,
}) => {
	const requests = useSelector(getUsersPasswordResetRequests);
	const permissions = useSelector(getPermissions);
	const loading = useSelector(getRequestsLoading);

	return (
		<div className="table table--user-management-reset-request">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>User ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Email</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Type</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Date</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>IP Address</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Reason</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{!loading &&
					(requests?.data?.length ? (
						<>
							{requests?.data.map((item: any) => {
								return (
									<ResetUserPasswordItem
										key={String(item.created_at) + String(item.id)}
										data={item}
										permission={!!permissions?.[EPermissionNames.RESET_REQUEST].editable}
									/>
								);
							})}
						</>
					) : (
						<TableBodyNoData />
					))}
				{loading && <Loading />}
			</div>

			{!!requests?.data?.length && (
				<div className="table-footer">
					<div className="pagination-block pagination-block--full">
						<PerPageDropdown
							options={[10, 20, 50, 100]}
							setPerPage={setItemsPerPageHandler}
							perPage={itemsPerPage}
						/>
						<ReactPaginate
							breakLabel="..."
							pageCount={requests?.last_page || 1}
							onPageChange={handlePageChange}
							forcePage={page - 1}
							className="pagination"
							activeClassName="active"
							previousClassName="pagination__arrow pagination__arrow--prev"
							nextClassName="pagination__arrow pagination__arrow--next"
							previousLabel=""
							nextLabel=""
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default UserPasswordTab;
