import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Popup from 'reactjs-popup';
import { Field, Form, Formik } from 'formik';
import cn from 'classnames';
import { getCurrencyData } from '../../../redux/reducers/currency/selectors';
import { ICurrencySelectItem } from '../../../ui/Formik/Select/CurrencySelect/types';
import { clearError } from '../../../redux/reducers/errors/reducer';
import SelectDate from '../../../ui/SelectDate';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';
import Input from '../../../ui/Formik/Input';
import InputTotp from '../../../ui/Formik/InputTotp';
import { IPopUp } from '../types';
import UserSearch from './UserSearch/UserSearch';
import { ICurrencyDataItem } from '../../../redux/reducers/currency/types';
import { addTradeRequest } from '../../../redux/reducers/transactions/reducer';
import { IAddTradeWorker } from '../../../redux/reducers/transactions/types';

export interface IAddTradeForm {
	date: string;
	from_asset_code: string;
	to_asset_code: string;
	amount_sold: string;
	amount_recieved: string;
	fee: string;
	exchange_rate: string;
	note: string;
	twoFa: string;
}

const AddTradeTransaction: FC<IPopUp> = ({ open, closeModal, buttonClick }) => {
	const dispatch = useDispatch();
	const currencyData = useSelector(getCurrencyData);

	const [reset, setReset] = useState(false);
	const [userId, setUserId] = useState('');

	// Currencies
	const [fromCurrencies, setFromCurrencies] = useState<ICurrencyDataItem[]>(currencyData);
	const [selectedFromCurrency, setSelectedFromCurrency] = useState<ICurrencySelectItem | null>(
		null,
	);
	const [toCurrencies, setToCurrencies] = useState<ICurrencyDataItem[]>(currencyData);
	const [selectedToCurrency, setSelectedToCurrency] = useState<ICurrencySelectItem | null>(null);

	const handleGetAssetIdByCode = (currentCode: string) => {
		return currencyData.filter(({ code }) => code === currentCode.toLowerCase())[0].id;
	};

	const handleChangeUserId = (currentUserId: string) => {
		setUserId(currentUserId);
	};

	const handleFromCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedFromCurrency(value);
		if (value.id === selectedToCurrency?.id) {
			setSelectedToCurrency(null);
		}
		setToCurrencies(currencyData?.filter(({ id }) => id !== value.id));
	};
	const handleToCurrencyChange = (value: ICurrencySelectItem) => {
		setSelectedToCurrency(value);
		if (value.id === selectedFromCurrency?.id) {
			setSelectedFromCurrency(null);
		}
	};

	const handleCloseModal = () => {
		setSelectedToCurrency(null);
		setSelectedFromCurrency(null);
		closeModal();
	};

	const handleCloseModalClick = () => {
		setSelectedToCurrency(null);
		setSelectedFromCurrency(null);
		closeModal();
	};

	const initialValues: IAddTradeForm = {
		date: '',
		from_asset_code: '',
		to_asset_code: '',
		amount_sold: '',
		amount_recieved: '',
		fee: '',
		exchange_rate: '',
		note: '',
		twoFa: '',
	};

	const validationSchema = yup.object().shape({
		date: yup.string().required('Please, enter date!'),
		from_asset_code: yup.string().required('Please, choose currency!'),
		to_asset_code: yup.string().required('Please, choose currency!'),
		amount_sold: yup.string().required('Please, enter amount!'),
		amount_recieved: yup.string().required('Please, enter amount!'),
		exchange_rate: yup.string().required('Please, enter exchange rate!'),
		fee: yup.string().required('Please, enter trade fee!'),
		note: yup.string().required('Please, enter note!'),
		twoFa: yup
			.string()
			.required('Please, enter 2FA code!')
			.max(6, 'Please enter 6 character 2FA code.'),
	});

	useEffect(() => {
		setFromCurrencies(currencyData);
		setToCurrencies(currencyData);
	}, [currencyData, open]);

	return (
		<>
			<Popup open={open} nested onClose={handleCloseModalClick}>
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={handleCloseModalClick} />
					<div className="popup-header popup-header--mb-20">
						<p className="popup-header__title">Add New Transaction</p>
					</div>
					<Formik
						validateOnBlur
						enableReinitialize
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values: IAddTradeForm, { resetForm, setSubmitting }) => {
							if (userId) {
								const params: IAddTradeWorker = {
									apiParams: {
										user_id: Number(userId),
										from_asset_id: handleGetAssetIdByCode(values.from_asset_code),
										to_asset_id: handleGetAssetIdByCode(values.to_asset_code),
										amount_sold: Number(values.amount_sold),
										amount_recieved: Number(values.amount_recieved),
										fee: Number(values.fee),
										exchange_rate: Number(values.exchange_rate),
										admin_notes: values.note,
										date: values.date,
										totp: values.twoFa,
									},
									onFinally: (hasError: boolean) => {
										setSubmitting(false);
										dispatch(clearError());
										if (!hasError) {
											resetForm();
											handleCloseModal();
										}
									},
								};
								dispatch(addTradeRequest(params));
							}
						}}
					>
						{({ isSubmitting, isValid, dirty }) => (
							<Form>
								<div className="popup-body popup-body--transaction">
									<div className="phone-number-input-wrap">
										<UserSearch
											handleChangeUserId={handleChangeUserId}
											resetForm={reset}
											handleResetForm={() => setReset(false)}
										/>
										<div className="mt-16">
											<Field name="date" component={SelectDate} />
										</div>
										<div className="input-line">
											<Field
												type="text"
												searchField
												name="from_asset_code"
												title="Sell Currency"
												component={CurrencySelect}
												arr={fromCurrencies}
												onChange={handleFromCurrencyChange}
												className="select--add-fiat-transaction thin--font"
												disabled={!fromCurrencies}
												activeValue={selectedFromCurrency}
											/>
											<Field
												type="text"
												searchField
												name="to_asset_code"
												title="Buy Currency"
												component={CurrencySelect}
												arr={toCurrencies}
												onChange={handleToCurrencyChange}
												className="select--add-fiat-transaction thin--font"
												disabled={!toCurrencies}
												activeValue={selectedToCurrency}
											/>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													name="amount_sold"
													title="Amount Sold"
												/>
											</div>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													name="amount_recieved"
													title="Amount Received"
												/>
											</div>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													name="exchange_rate"
													title="Exchange Rate"
												/>
											</div>
											<div className="number__input">
												<Field
													required
													type="number"
													placeholder="0.00"
													component={Input}
													name="fee"
													title="Trade Fee"
												/>
											</div>
										</div>
										<Field
											required
											type="textarea"
											placeholder="Text"
											component={Input}
											name="note"
											title="Note"
										/>
										<Field
											required
											type="text"
											placeholder="Enter 2fa code"
											component={InputTotp}
											name="twoFa"
											title="2FA Code"
										/>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--column">
										<button
											type="submit"
											className="btn btn-primary btn--full"
											disabled={!(isValid && dirty) || isSubmitting || !userId}
										>
											Confirm
										</button>
										<button
											type="button"
											onClick={handleCloseModal}
											className="btn btn btn-outline btn-secondary"
										>
											Cancel
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Popup>
		</>
	);
};

export default AddTradeTransaction;
