import React, { ChangeEvent, FC, useLayoutEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconSvg from 'ui/Svg/IconSvg';
import { IDepositAccount } from 'redux/reducers/walletBalance/types';
import { ISelect } from './types';

interface ICountry {
	value: string;
	label: string;
}
const Select: FC<ISelect> = ({
	touched,
	type,
	field,
	placeholder,
	inputMode,
	onKeyUp,
	title,
	arr,
	dropdownTitle,
	customList,
	customInput,
	buttonName,
	buttonClick,
	disabled,
	searchField,
	setSearchArr,
	setTouched,
	setSearchCurrency,
	validateField,
	setSearchAccount,
	className,
	name,
}) => {
	const [opened, setOpened] = useState<boolean>(false);
	const [customListValue, setCustomListValue] = useState<string>('');
	const [searchValue, setSearchValue] = useState('');
	const selectRef = useRef<HTMLDivElement | null>(null);

	const handleClearSerch = () => {
		setSearchValue('');
	};

	useLayoutEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectRef.current?.contains(event.target as Node)) {
				setOpened(false);
				setTouched && setTouched();
			}
		};

		if (opened) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [opened, setTouched]);

	useLayoutEffect(() => {
		!!setSearchAccount && setSearchAccount(arr);
		setSearchValue('');
	}, [arr, setSearchAccount]);

	useLayoutEffect(() => {
		if (setSearchArr) {
			if (searchValue) {
				const newArr = arr?.filter(
					(item: ICountry) =>
						item.label?.toLowerCase().includes(searchValue.toLowerCase()) ||
						item.value?.toLowerCase().includes(searchValue.toLowerCase()),
				);
				setSearchArr(newArr);
			}
			if (!searchValue) {
				setSearchArr(arr);
			}
		}

		if (setSearchCurrency) {
			if (!searchValue) {
				setSearchCurrency(arr);
				return;
			}
			const newArr = arr?.filter((el: any) =>
				el.asset
					? el.asset.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					  el.asset.code.toLowerCase().includes(searchValue.toLowerCase())
					: el.name.toLowerCase().includes(searchValue.toLowerCase()) ||
					  el.code.toLowerCase().includes(searchValue.toLowerCase()),
			);
			setSearchCurrency(newArr);
		}

		if (setSearchAccount) {
			if (searchValue) {
				const newArr = arr?.filter((item: IDepositAccount) =>
					item.bank_name?.toLowerCase().includes(searchValue.toLowerCase()),
				);
				setSearchAccount(newArr);
			}
			if (!searchValue) {
				setSearchAccount(arr);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, setSearchArr, setSearchCurrency, setSearchAccount]);

	const toggleSelect = () => {
		setOpened(!opened);
	};
	const changeInputValue = (value: string) => {
		field.onChange({ target: { value, name: field.name } });
		setOpened(false);
	};
	const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchValue(value);
	};

	useLayoutEffect(() => {
		if (
			(customList?.activeElement || customList?.activeElement === '') &&
			customList?.activeElement !== customListValue
		) {
			!!field && field.onChange({ target: { value: customList?.activeElement, name: field.name } });
			!!validateField &&
				setTimeout(() => {
					validateField(field.name);
				});
			setCustomListValue(customList?.activeElement);
			setOpened(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customList?.activeElement, customListValue, field]);

	return (
		<div className="select-block">
			{!!title && <p className="select-block__name">{title}</p>}
			<div
				ref={selectRef}
				className={`select ${opened ? 'active' : ''} ${disabled ? 'disabled' : ''} ${
					touched ? 'error-touched' : ''
				} ${className || ''}`}
			>
				<button onClick={toggleSelect} type="button" className="select__current">
					{!!customInput && (
						<span className="select__custom select__current--placeholder">{customInput}</span>
					)}
					{!!placeholder && !field?.value && !customInput && (
						<span className="select__current--placeholder">{placeholder}</span>
					)}
					{!!field?.value && !customInput && <span>{field.value}</span>}
					<input
						className="select-input"
						{...field}
						type={type}
						placeholder={placeholder}
						inputMode={inputMode || null}
						onKeyUp={onKeyUp}
						name={name}
						readOnly
					/>

					<span className="select__current-arrow">
						<svg
							width="10"
							height="5"
							viewBox="0 0 10 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0H1H0L5 5L10 0Z" fill="#777E90" />
						</svg>
					</span>
				</button>
				<AnimatePresence>
					{opened && (
						<motion.div
							className="select__drop select__drop---top country-select"
							style={{ display: 'block' }}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.3 } }}
							exit={{ opacity: 0, transition: { duration: 0.3 } }}
						>
							{searchField && !setSearchAccount && (
								<div className="input input--search">
									<div className="input-wrapper">
										<input
											className="input-item"
											type="text"
											// placeholder="Search"
											value={searchValue}
											onChange={handleChangeSearchValue}
										/>
										<div className="input-icon input-icon--search" />
										<div className="input-icon input-icon--clear" onClick={handleClearSerch} />
									</div>
								</div>
							)}
							<div className="select__drop-scroll">
								{!searchField && !!dropdownTitle && (
									<div className="select__drop-title">
										<p>{dropdownTitle}</p>
										<button type="button" onClick={toggleSelect}>
											<IconSvg name="close" w="16" h="17" />
										</button>
									</div>
								)}
								<div className="select__drop-item">
									<ul>
										{customList
											? customList.list
											: !!arr?.length &&
											  arr.map((el: any, index: number) => (
													<li key={String(el) + index.toString()}>
														<button type="button" onClick={() => changeInputValue(el)}>
															{el}
														</button>
													</li>
											  ))}
									</ul>
								</div>
							</div>
							{searchField && setSearchAccount && (
								<div className="input input--search">
									<div className="input-wrapper">
										<input
											className="input-item"
											type="text"
											// placeholder="Search"
											value={searchValue}
											onChange={handleChangeSearchValue}
										/>
										<div className="input-icon input-icon--search" />
										<div className="input-icon input-icon--clear" onClick={handleClearSerch} />
									</div>
								</div>
							)}
							{buttonName && buttonClick && (
								<button type="button" onClick={buttonClick} className="btn btn--w-full btn-primary">
									{buttonName}
								</button>
							)}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default Select;
