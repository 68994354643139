import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { FC, useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getCommissionRequest } from 'redux/reducers/referrals/reducer';
import { getCommission } from 'redux/reducers/referrals/selectors';
import { IReferralItem } from 'redux/reducers/referrals/types';
import PerPageDropdown from 'services/utils/PaginationPerPageDropdown';
import IconSvg from 'ui/Svg/IconSvg';
import { referralSystemRequest } from 'redux/reducers/users/reducer';
import ReferralTableRow from './ReferralTableRow';
import { IReferralTableProps } from './types';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import {
	getReferralSystem,
	getUserReferralsPageCount,
} from '../../../redux/reducers/users/selectors';
import TradeHistoryRow from '../../AccountDetails/TransactionHistoryTab/TradeHistory/TradeHistoryRow';
import Loading from '../../../layouts-elements/Loading/Loading';

const ReferralTable: FC<IReferralTableProps> = ({
	referralsList,
	perPage,
	setCurrentPage,
	setItemsPerPage,
	currentPage,
	loading,
}) => {
	const dispatch = useDispatch();
	const { userId } = useParams<IAccountDetailsParams>();
	const [currentItems, setCurrentItems] = useState<IReferralItem[] | null>(null);
	const pageCount = useSelector(getUserReferralsPageCount);

	const commission = useSelector(getCommission);
	const permissions = useSelector(getPermissions);
	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	useLayoutEffect(() => {
		dispatch(getCommissionRequest());
		dispatch(referralSystemRequest(userId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const referralSystem = useSelector(getReferralSystem);
	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--referral one--user">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>User ID</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Referred User</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Email</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Total Commission</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Trade Value</p>
									</div>
								</div>
								{/* <div className="td"> */}
								{/*	<div className="td-name"> */}
								{/*		<p>Commission</p> */}
								{/*	</div> */}
								{/* </div> */}
								<div className="td td--right">
									<div className="td-name">
										<p>Percentage %</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">{/* <p>Action</p> */}</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{referralsList?.data?.length ? (
										referralsList?.data?.map((referral: any) => (
											<ReferralTableRow
												referralSystem={referralSystem}
												permission={!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
												key={referral.id}
												{...referral}
												commission={commission}
												userId={userId}
												setCurrentPage={setCurrentPage}
												setItemsPerPage={setItemsPerPage}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{!!referralsList?.data.length && (
							<div className="table-footer table-footer--type2">
								<div className="pagination-block pagination-block--full">
									<PerPageDropdown
										options={[10, 20, 50, 100]}
										setPerPage={setItemsPerPage}
										perPage={perPage}
									/>
									{pageCount > 1 && (
										<ReactPaginate
											breakLabel="..."
											pageCount={pageCount}
											forcePage={currentPage - 1}
											onPageChange={handlePageClick}
											className="pagination"
											activeClassName="active"
											previousClassName="arrow arrow--prev"
											nextClassName="arrow arrow--next"
											previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
											nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {!!referralsList?.data.length && (
				<div className="pagination-block pagination-block--full">
					<PerPageDropdown
						options={[10, 20, 50, 100]}
						setPerPage={setItemsPerPage}
						perPage={perPage}
					/>
					{pageCount > 1 && (
						<ReactPaginate
							breakLabel="..."
							pageCount={pageCount}
							onPageChange={handlePageClick}
							className="pagination"
							activeClassName="active"
							previousClassName="arrow"
							nextClassName="arrow"
							previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
							nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
						/>
					)}
				</div>
			)} */}
		</>
	);
};

export default ReferralTable;
